import * as actionTypes from "../actionTypes";

const initialState = {
    loading: false,
    accountData: null,
    updateAccData: null,
    error: null,
    success: null,
    getAccSuccess: null,
    getAccError: null,
    updatePasswordSuccess: null,
    updatePasswordMessage: null
};
export const accountReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.GET_USER_ACCOUNT_START:
            return { ...state, loading: true, error: null, getAccSuccess: null, accountData: null };
        case actionTypes.GET_USER_ACCOUNT_SUCCESS:
            return { ...state, loading: false, error: null, getAccSuccess: null, accountData: action.payload, };
        case actionTypes.GET_USER_ACCOUNT_FAILURE:
            return { ...state, loading: false, getAccError: action.payload, success: null, };
        
        case actionTypes.ADD_USER_ACCOUNT_START:
            return { ...state, loading: true, error: null, success: null };
        case actionTypes.ADD_USER_ACCOUNT_SUCCESS:
            return { ...state, loading: false, error: null, success: action.payload };
        case actionTypes.ADD_USER_ACCOUNT_FAILURE:
            return { ...state, loading: false, error: action.payload, success: null, };
        
        case actionTypes.UPDATE_USER_ACCOUNT_START:
            return { ...state, loading: true, error: null, success: null };
        case actionTypes.UPDATE_USER_ACCOUNT_SUCCESS:
            return { ...state, loading: false, error: null, success: action.payload, updateAccData: action.payload };
        case actionTypes.UPDATE_USER_ACCOUNT_FAILURE:
            return { ...state, loading: false, error: action.payload, success: null, };
        
        case actionTypes.DELETE_USER_ACCOUNT_START:
            return { ...state, loading: true, error: null, success: null };
        case actionTypes.DELETE_USER_ACCOUNT_SUCCESS:
            return { ...state, loading: false, error: null, success: action.payload };
        case actionTypes.DELETE_USER_ACCOUNT_FAILURE:
            return { ...state, loading: false, error: action.payload, success: null, };

        case actionTypes.UPDATE_PASSWORD_START:
            return { ...state, loading: true, error: null, updatePasswordSuccess: null };
        case actionTypes.UPDATE_PASSWORD_SUCCESS:
            return { ...state, loading: false, error: null, updatePasswordSuccess: true, updatePasswordMessage: action.payload.message };
        case actionTypes.UPDATE_PASSWORD_FAILURE:
            return { ...state, loading: false, updatePasswordSuccess: false, updatePasswordMessage: action.payload.message };

        case actionTypes.ACCOUNT_MESSAGE_HANDLER:
            return {
                ...state, loading: false, error: action.payload, success: action.payload, updatePasswordSuccess: action.payload, updatePasswordMessage: action.payload
            };

        default:
            return {
                loading: false,
                accountData: null,
                updateAccData: null,
                error: null,
                success: null,
                getAccSuccess: null,
                getAccError: null,
                updatePasswordSuccess: null,
                updatePasswordMessage: null
            };
    }
};
