// LoginModal.js
import React from 'react';
import { Modal, Backdrop, Fade } from '@material-ui/core';
const LoginModal = ({ open, onClose, children, className,hideBackdrop }) => {
  return (
    
    <Modal
      open={open}
      onClose={onClose}
      hideBackdrop={hideBackdrop}
      className={className}
    >
      {children}
    </Modal>
  );
};

export default LoginModal;
