import React, { useState } from 'react'
import { Grid, Button, TextField, InputAdornment, Link, Typography } from '@material-ui/core';
import { pakFlag, cross } from '../../assets/images/img';
import Loader from '../../components/loading';

import { useStyles } from './styles';

function CreateAccount(props) {
    const classes = useStyles();
    const { modalCloseLogin, icon, values,handleLogin ,handleChange,loading,selectPaymentMethod,Payment} = props;
    return (
        <div>
            <div className='login-modal-bajao'>
                <div className='bajao-cross-btn-login'>
                    <img src={cross} onClick={() => modalCloseLogin('create-account')} />
                </div>
                <h2>Enter Your Mobile<br /> Number</h2>
                <div className="create-account-logo" >
                    {icon.map((item, index) => (
                        <div>
                            <img src={item} onClick={() => selectPaymentMethod(index)} className={`${Payment == index ? 'active-card-design' : ''}`} />
                        </div>
                    ))}
                </div>
                <TextField
                    variant="outlined"
                    value={values.phone}
                    name='phone'
                    onChange={handleChange('phone')}
                    InputProps={{
                        classes: {
                            root: classes.phoneTxtInput,
                            focused: classes.inputFocused,
                        },
                        startAdornment: (
                            <InputAdornment position="start" className='input-adorment'>
                                <img
                                    src={pakFlag}
                                    alt="Pakistan Flag"
                                /> <span>{'+92'}</span>
                            </InputAdornment>
                        ),
                        inputProps: {
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                        },
                    }}

                />
                <div className='verify-text-btn'>
                    <p >A Verification Pin will be sent to your <br />provided number.</p>
                    {/* <button onClick={() => props.history.push('/verification')}>continue</button> */}
                    {loading ? (
                        <Loader />
                    )
                        : (

                            <button onClick={handleLogin}>continue</button>
                        )}
                </div>
            </div>
        </div>
    )
}

export default CreateAccount