import React, { useState, useEffect } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
// import internal(own) modules
import MainLayout from "./MainLayout";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
// import AuthModal from "../container/sidebar/AuthModal";
import {
  sidebarTabsList
} from "../environment";

// const guestRoutes = ['/', 'home', 'search', 'my-music', 'browse-category', 'genre','tracks','browseAll','video'];
const guestRoutes = ['/', 'home', 'my-music','categories','play-list', 'tracks', 'search-result', 'browse-category', 'category-details', 'browse-all', 'video','play-list'];
const userRoutes = ['/', 'home', 'search', 'my-music', 'genre', 'profile','browse-all','play-list', 'tracks', 'search-result', 'browse-category', 'category-details', 'categories', 'video','play-list'];

const MainLayoutRoute = ({ render, ...rest }) => {
  const userDetail = JSON.parse(localStorage.getItem('user'));
  const sidebar = sidebarTabsList;
  const routes = userDetail ? userRoutes : guestRoutes;

  return (
    <Route
      {...rest}
      className={'app-logo-text'}
      render={(matchProps) => {
        // const requestedRoute = rest.path.split('/')[1];
        // if (routes.includes(requestedRoute) || rest.path === "/") {
          
        // Check if the requested route is allowed
        if (routes.includes(rest.path.split('/')[1]) || rest.path === "/") {
          return (
            <MainLayout sidebar={sidebar} currentSong={'test'}>{render(matchProps)}</MainLayout>
          );
        }
        else {
          // Redirect to login page if user is not authenticated and route is protected
          if (!userDetail && userRoutes.includes(rest.path.split('/')[1]) || rest.path === "/") {
            localStorage.setItem('isNotAuthUser', true);
            return <Redirect to="/" />;
          }
          else {
            return <Redirect to="/" />;
          }
        }
      }}
    />
  );
};

export default MainLayoutRoute;
