import * as actions from "../actions";
import * as actionsTypes from "../actionTypes";
import { put, takeEvery, all, fork } from "redux-saga/effects";
// import axios from 'axios';
import AccountService from "../../services/account.service";
import { getUserDataFromLocalStorage } from "../../services/utils";

function* getUserAccountStart(action) {
  try {
    const response = yield AccountService.getUserAccounts();

    if (response?.data) {
      yield put(actions.getUserAccountSuccess(response?.data));
    } else {
      yield put(actions.getUserAccountFailure("network error"));
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.getUserAccountFailure(error));
  }
}
function* getUserAccountSaga() {
  yield takeEvery(actionsTypes.GET_USER_ACCOUNT_START, getUserAccountStart);
}

function* addUserAccountStart(action) {
  try {
    if (action.payload) {
      const userData = getUserDataFromLocalStorage();
      const userId = Date.now()
      const name = action?.payload?.name;
      const gender = action?.payload?.gender;
      const schoolId = action?.payload?.schoolId;
      const schoolName = action?.payload?.schoolName;
      const schoolCode = action?.payload?.schoolCode;
      const city = action?.payload?.city;
      const grade = action?.payload?.grade;
      const msisdn = userData?.msisdn;
      const cnic = action?.payload?.cnic;
      const userType = action?.payload?.userType;
      const schoolType = action?.payload?.schoolType;
      const provinceName = action?.payload?.provinceName;
      const districtName = action?.payload?.districtName;
      const cityName = action?.payload?.cityName;
      let params = { userId, name, gender, schoolName, city, grade, msisdn, cnic, userType, schoolId, schoolCode, schoolType, provinceName, districtName, cityName };

      const response = yield AccountService.addAccount(params);

      if (response?.data) {
        yield put(actions.addUserAccountSuccess(response?.data));
      } else {
        yield put(actions.addUserAccountFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.addUserAccountFailure(error));
  }
}
function* addUserAccountSaga() {
  yield takeEvery(actionsTypes.ADD_USER_ACCOUNT_START, addUserAccountStart);
}

function* updateUserAccountStart(action) {
  try {
    if (action.payload) {
      const config = {
        headers: {
          userid: action?.payload?.userId,
        }
      };
      const name = action?.payload?.name;
      const gender = action?.payload?.gender;
      const schoolName = action?.payload?.schoolName;
      const schoolId = action?.payload?.schoolId;
      const schoolCode = action?.payload?.schoolCode;
      const city = action?.payload?.city;
      const grade = action?.payload?.grade;
      const cnic = action?.payload?.cnic;
      const userType = action?.payload?.userType;
      const schoolType = action?.payload?.schoolType;
      const provinceName = action?.payload?.provinceName;
      const districtName = action?.payload?.districtName;
      const cityName = action?.payload?.cityName;
      let params = { name, gender, schoolName, city, grade, cnic, userType, schoolType, provinceName, districtName, cityName,schoolId,schoolCode };

      const response = yield AccountService.updateAccount(params, config);

      if (response?.data) {
        yield put(actions.updateUserAccountSuccess(response?.data));
      } else {
        yield put(actions.updateUserAccountFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.updateUserAccountFailure(error));
  }
}
function* updateUserAccountSaga() {
  yield takeEvery(actionsTypes.UPDATE_USER_ACCOUNT_START, updateUserAccountStart);
}

function* deleteUserAccountStart(action) {
  try {
    if (action.payload) {
      const config = {
        headers: {
          userid: action?.payload?.userId,
        }
      };
      const response = yield AccountService.deleteAccount(config);

      if (response?.data) {
        yield put(actions.deleteUserAccountSuccess(response?.data));
      } else {
        yield put(actions.deleteUserAccountFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.deleteUserAccountFailure(error));
  }
}
function* deleteUserAccountSaga() {
  yield takeEvery(actionsTypes.DELETE_USER_ACCOUNT_START, deleteUserAccountStart);
}

function* updatePasswordStart(action) {
  try {
    if (action.payload) {
      const config = {
        headers: {
          userid: action?.payload?.userId,
          password: action?.payload?.old_pass,
          newpassword: action?.payload?.new_pass,
        }
      };
      const response = yield AccountService.updatePassword(config);

      if (response?.data.isSuccess) {
        yield put(actions.updatePasswordSuccess(response?.data));
      } else {
        yield put(actions.updatePasswordFailure(response?.data));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.updatePasswordFailure(error));
  }
}
function* updatePasswordSaga() {
  yield takeEvery(actionsTypes.UPDATE_PASSWORD_START, updatePasswordStart);
}

export function* accountSaga() {
  yield all([
    fork(getUserAccountSaga),
    fork(addUserAccountSaga),
    fork(updateUserAccountSaga),
    fork(deleteUserAccountSaga),
    fork(updatePasswordSaga),
  ]);
}
