import React from 'react'
import { gmail, FaceBook, apple, Mobile, cross } from '../../assets/images/img'
import { useHistory } from 'react-router-dom';

function SignComponent(props) {
    const { modalCloseLogin } = props;
    const history = useHistory();

    const handleContinueBtn = () => {
        history.push('/create-account')
    }
    return (
        <div>
            <div className='login-modal-bajao'>
                <div className='bajao-cross-btn-login'>
                    <img src={cross} onClick={modalCloseLogin} />
                </div>
                <div className='bajao-text-head'>
                    <h2>LogIn With Bajao</h2>
                    <span>Music, Videos & Reels For Everyone</span>
                </div>

                <div className='btn-bajao-login'>
                    <button className='gm-btn'><span className='socials-btn-align' ><img src={gmail} className='socials-btn-style' /></span>Continue With Gmail</button>
                    <button className='fb-btn'><span className='socials-btn-align'><img src={FaceBook} className='socials-btn-style' /></span>Continue With Facebook</button>
                    <button className='number-btn' onClick={handleContinueBtn}><span className='socials-btn-align' ><img src={Mobile} className='socials-btn-style' /></span>Continue With Number</button>
                    <button className='skin-btn'><span className='socials-btn-align'><img src={apple} className='socials-btn-style' /></span>Continue With Apple</button>
                </div>
            </div>

        </div>
    )
}

export default SignComponent