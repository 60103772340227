import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import  AudioPlayerContext  from './routes/AudioPlayerContext';
import  {UserProvider}  from './routes/UserContext';

ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
    <AudioPlayerContext>
      <App />
    </AudioPlayerContext>
    </UserProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
