import HttpService from "./http.service";

class HomeService extends HttpService {
  getContentSubsciptionData = (params) => this.get("content-subscription/list", params);  
  getProfileFavouritesData = (data) => this.get(`reaction/favourite/${data.id}`, data);  
  searchContentData = (params) => this.get("content/search", params);  
  setAsFavourite = (data) => this.post("reaction/store", data);  
  setViewLogin = (data) => this.post("content-views-login/store", data);  
  setView = (data) => this.post("content-views-logout/store", data);  
  updateFavourite = (id, data) => this.patch(`reaction/${id}`, data);
  setAsJazzTune = (config) => this.post("content-subscription/store", config);  
  getStream = (config) => this.geth("content/stream", config);
  getBanners = () => this.get("banners/list");
  getSongsByArtist = (params) => this.get("content/list_by_artist", params);
  getSectionContent = (id, params) => this.get(`section-content-id/${id}`, params);
  getLiveChannels = () => this.get("channel");
  getGrades = () => this.get("grade");
  getContent = (data) => this.get("home/getHomeData", data);
  getContentByGradeId = (config) => this.geth("content/grade", config);
  searchContent = (config) => this.gethSearch("content/search", config);
  getChannel = () => this.get("channel");
  getChannelById = (config) => this.geth("channel/stream", config);
  getSubject = (params) => this.get("content/subject", params);
  getCity = () => this.get("city");
  getProvince = () => this.get("provinces");
  getSchoolsByCity = (config) => this.geth("provinces/school", config);
  continueWatching = (config) => this.geth("content/keepWatching", config);
  updateDurWatched = (config) => this.post("content/updateDurationWatched", undefined, config);
  getContentByChapterId = (config) => this.geth("content/chapter", config);
  watchHistory = (config) => this.geth("content/watchedHistory", config);
  introStreamLink = (config) => this.geth("content/introStreamlink", config);
}

export default new HomeService();