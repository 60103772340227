
import React, { createContext, useContext, useState, useEffect } from 'react';

const ThemeContext = createContext();

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

export const ThemeProvider = ({ children }) => {
  const [isDarkTheme, setIsDarkTheme] = useState(() => {
    // Retrieve the theme preference from local storage, or default to 'light' theme
    return localStorage.getItem('theme') === 'dark';
  });

  const toggleTheme = () => {
    // Toggle the theme and store the preference in local storage
    const newTheme = !isDarkTheme;
    setIsDarkTheme(newTheme);
    localStorage.setItem('theme', newTheme ? 'dark' : 'light');
  };

  useEffect(() => {
    // Listen for changes in the theme preference in local storage
    window.addEventListener('storage', (event) => {
      if (event.key === 'theme') {
        setIsDarkTheme(event.newValue === 'dark');
      }
    });

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener('storage');
    };
  }, []);

  return (
    <ThemeContext.Provider value={{ isDarkTheme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
