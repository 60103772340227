import * as actionTypes from "../actionTypes";

// List
export const getUserAccountStart = (data) => {
    return {
        type: actionTypes.GET_USER_ACCOUNT_START,
        payload: data,
    };
};
export const getUserAccountSuccess = (data) => {
    return {
        type: actionTypes.GET_USER_ACCOUNT_SUCCESS,
        payload: data,
    };
};
export const getUserAccountFailure = (data) => {
    return {
        type: actionTypes.GET_USER_ACCOUNT_FAILURE,
        ACCOUNTyload: data,
    };
};

// Add Account
export const addUserAccountStart = (data) => {
    return {
        type: actionTypes.ADD_USER_ACCOUNT_START,
        payload: data,
    };
};
export const addUserAccountSuccess = (data) => {
    return {
        type: actionTypes.ADD_USER_ACCOUNT_SUCCESS,
        payload: data,
    };
};
export const addUserAccountFailure = (data) => {
    return {
        type: actionTypes.ADD_USER_ACCOUNT_FAILURE,
        payload: data,
    };
};

// Update Account
export const updateUserAccountStart = (data) => {
    return {
        type: actionTypes.UPDATE_USER_ACCOUNT_START,
        payload: data,
    };
};
export const updateUserAccountSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_USER_ACCOUNT_SUCCESS,
        payload: data,
    };
};
export const updateUserAccountFailure = (data) => {
    return {
        type: actionTypes.UPDATE_USER_ACCOUNT_FAILURE,
        payload: data,
    };
};

// Delete Account
export const deleteUserAccountStart = (data) => {
    return {
        type: actionTypes.DELETE_USER_ACCOUNT_START,
        payload: data,
    };
};
export const deleteUserAccountSuccess = (data) => {
    return {
        type: actionTypes.DELETE_USER_ACCOUNT_SUCCESS,
        payload: data,
    };
};
export const deleteUserAccountFailure = (data) => {
    return {
        type: actionTypes.DELETE_USER_ACCOUNT_FAILURE,
        payload: data,
    };
};

// Update Password
export const updatePasswordStart = (data) => {
    return {
        type: actionTypes.UPDATE_PASSWORD_START,
        payload: data,
    };
};
export const updatePasswordSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_PASSWORD_SUCCESS,
        payload: data,
    };
};
export const updatePasswordFailure = (data) => {
    return {
        type: actionTypes.UPDATE_PASSWORD_FAILURE,
        payload: data,
    };
};

export const accountMessageHandler = () => { 
    return {
        type: actionTypes.ACCOUNT_MESSAGE_HANDLER,
        payload: null,
    };
};
