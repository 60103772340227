import React, { useEffect, useState,useContext,useCallback} from 'react';

import './styles.js'

import { useStyles } from './styles.js';
import { modalLogo, modalLogo1, thanksIcon } from "../../assets/images/img";
import { Alert } from '../../components'
import LoginModal from './loginModal';
import { InputBase } from '@material-ui/core';
import * as actions from "../../store/actions";
import { connect } from "react-redux";
import OtpInput from 'react-otp-input';
import Loader from '../loading/index.js';
import MySidebar from '../../container/sidebar/MySidebar';
import UserContext from '../../routes/UserContext.js';

const codeArr = [
  { code: 1 },
  { code: 2 },
  { code: 3 },
  { code: 4 },
]

const LoginParentModal = React.memo((props) => {
  const {openModal, setOpen, handleLoginSuccess, loading, otpSuccess, sendOtpData, sendOtpError, verifyOtpSuccess, verifyOtpError, verifyOtpData,type} = props
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [codeValue, setCodeValue] = useState('');
  const [timerValue, setTimerValue] = useState('0:00');
  const [subModal, setSubModal] = useState(false);
  const [signInModal, setSignInModal] = useState(true);
  const [verifyModal, setVerifyModal] = useState(false);
  const [signInSuccessModal, setSignInSuccessModal] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const [renderSideBar, setRender] = useState(false);
  const { login } = useContext(UserContext);

  
  const [values, setValues] = useState({
    phone: ''
  })
  const [errors, setErrors] = useState({
    phone: ''
  })

  const handleLogin = useCallback(() => {
    setTimeout(() => {
      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem("user", JSON.stringify(verifyOtpData.data.user));
      localStorage.setItem("token", verifyOtpData.data.token);
      login(verifyOtpData.data.user, type);
      setOpen(false);
    }, 1000);
  }, [verifyOtpData, login, setOpen, type]);

  useEffect(() => {
    setOpen(props.openModal)
  }, [props.openModal])

  useEffect(() => {
    function handleClickOutside(event) {
      const clickedInside = event.target.closest('.profile-div');
      const clickedInsideDiv = event.target.closest('.modal-div');
      if (!clickedInside && !clickedInsideDiv) {
        setOpen(false)

      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCloseLogin = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  
  const sendOtpFuntion = useCallback(() => {
    props.sendOtp({ phone_number: values.phone });
  }, [props, values.phone]);

  useEffect(() => {
    if (otpSuccess) {
      setTimeout(() => {
        setVerifyModal(true)
        // timer()
        setSignInModal(false)
        setSignInSuccessModal(false)
        props.otpSuccessHandler()
      }, 2000)
    }
    if (sendOtpError) {
      setTimeout(() => {
        props.otpSuccessHandler()
      }, 2000)
    }
  }, [otpSuccess, sendOtpError])

  const verifyOtpFuntion = useCallback(() => {
    props.verifyOtp({ phone_number: values.phone, pin_code: codeValue });
  }, [props, values.phone, codeValue]);

  useEffect(() => {
    if (verifyOtpSuccess) {
      setSignInModal(false)
      setVerifyModal(false)
      setSignInSuccessModal(true)
      handleLogin()
      setRender(true)
      props.verifyOtpSuccessHandler()
    }
    if (verifyOtpError) {
      setTimeout(() => {
        props.verifyOtpSuccessHandler()
      }, 2000)
    }
  }, [verifyOtpSuccess, verifyOtpError])

  const handleNextModal = (no) => {
    if (no === 1) {
      setVerifyModal(true)
      setSignInModal(false)
      setSignInSuccessModal(false)
    } else if (no === 2) {
      setSignInModal(false)
      setVerifyModal(false)
      setSignInSuccessModal(true)
      handleLogin()
    } else {
      setSignInModal(true)
      setVerifyModal(false)
      setSignInSuccessModal(false)
    }
  }

const handlePhoneNumberChange = useCallback((newValue) => {
  setValues((prevState) => ({ ...prevState, phone: newValue }));
}, []);

const handleCodeChange = useCallback((event) => {
  const inputValue = event.target.value;
  if (!isNaN(inputValue) && inputValue >= 0 && inputValue <= 9) {
    setCodeValue(inputValue);
  }
}, []);

  useEffect(() => {
    // timer()
  }, []);
  // const timer = () => {
  //   // Set the countdown time to 1 minute and 40 seconds
  // let countdownTime = 100; // 1 minute and 40 seconds in seconds

  // const countdownInterval = setInterval(() => {
  //   // Calculate minutes and seconds
  //   const minutes = Math.floor(countdownTime / 60);
  //   let seconds = countdownTime % 60;

  //   // Add leading zero if seconds are less than 10
  //   if (seconds < 10) {
  //     seconds = '0' + seconds;
  //   }

  //   // Display the countdown in the console or wherever you need it
  //   console.log(`${minutes}:${seconds}`);
  //   setTimerValue(minutes + ":" + seconds)
  //   // Decrease countdown time
  //   countdownTime--;

  //   // Check if countdown has reached zero
  //   if (countdownTime < 0) {
  //     clearInterval(countdownInterval);
  //     console.log("Countdown finished!");
  //     // You can add any further actions after countdown finishes
  //   }
  // }, 1000);
  // }

  const handleSelected = (index) => {
    // setIsSelected(index)
  }

  const itemDetails = (item) => {
    const token = localStorage.getItem('token');

    if (!token) {
      setSignInModal(true)
      setVerifyModal(false)
      setSignInSuccessModal(false)
      setOpen(true)
    } else {
      props.history.push('/tracks', { rowData: item })
    }
    console.log('token', token);
  }
  return (
    <div>
      <div>
        <div>
          <LoginModal
            open={openModal}
            onClose={handleCloseLogin}
            className={classes.modal}
          >
            <div>
              <div className='close-modal'>
                <div onClick={handleCloseLogin} style={{ margin: '4px 11px' }}>
                  <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.690162 0.327551C0.542651 0.327589 0.409871 0.416999 0.354364 0.553667C0.298857 0.690336 0.331691 0.847009 0.437401 0.949891L3.98736 4.49985L0.437401 8.04982C0.342911 8.14078 0.304924 8.2757 0.338069 8.4026C0.371214 8.52951 0.470321 8.62861 0.597226 8.66176C0.724132 8.6949 0.859044 8.65692 0.950012 8.56243L4.49998 5.01247L8.04994 8.56243C8.14091 8.65692 8.27582 8.6949 8.40272 8.66176C8.52963 8.62861 8.62874 8.52951 8.66188 8.4026C8.69503 8.2757 8.65704 8.14078 8.56255 8.04982L5.01259 4.49985L8.56255 0.949891C8.66976 0.845676 8.702 0.68633 8.64373 0.548635C8.58545 0.41094 8.44862 0.323144 8.29917 0.327551C8.20499 0.330357 8.11561 0.369716 8.04995 0.437291L4.49999 3.98725L0.950027 0.437291C0.881778 0.367135 0.788063 0.327556 0.690187 0.327551H0.690162Z" fill="#0C0D34" />
                  </svg>
                </div>
              </div>
              {subModal && (
                <div className={`${classes.paper} modal-logo-main`}>
                  <div className='modal-logo'>
                    <img src={modalImage} className='modalImage' />
                  </div>
                  <div className='cnfrm-txt'>Do You Want To Set This As Your Jazz Tune?</div>
                  <div className='sub-btn-main'>
                    <div><button className='sub-btn-yes'>Yes</button></div>
                    <div><button className='sub-btn-no'>No</button></div>
                  </div>
                  <div><p className='charge-txt'>Subscription Charges: Rs. 3.01/day (incl. tax) Download Price: Rs. 11.53 (incl. tax)</p></div>
                  <div><p className='or-txt'>OR</p></div>

                  <div className='dial-txt'>
                    Dial *2301*RBT Code# From Your Jazz Number
                  </div>
                </div>
              )}
              {signInModal && (
                <div className={`${classes.paper} modal-div  modal-mobile main-login-modal `}>
                  <div className='login-header-txt'>LogIn Or SignUp</div>
                  <div className='modal-logo'>
                    <img src={modalLogo} className='modal-logo-img' />
                    <img src={modalLogo1} className='modal-logo-img-1' />
                  </div>
                  <p>Let Your Callers Groove To Jazz Tunes</p>
                  <div>
                    <InputBase
                      type='text'
                      value={values.phone}
                      placeholder='Enter Jazz Number To Proceed'
                      className='login-input-number'
                      onChange={(e) => handlePhoneNumberChange(e.target.value)}
                    />
                  </div>
                  <div>
                    <p>There Will Be No Deduction Of Balance Upon LogIn & SignUp
                      <br />
                      Its Absolutely Free</p>
                  </div>
                  <div className='continue-btn-outer'>
                    <button disabled={loading} className='continue-btn-inner' onClick={() => sendOtpFuntion()}>Continue</button>
                    {
                      loading &&
                      <Loader height={70} />
                    }
                    {
                      sendOtpData &&
                      <div className='send-otp-message'>
                        <p className={`${sendOtpData.data?.respCode === "00" ? 'send-otp-success' : 'send-otp-error'}`}>{sendOtpData?.message}</p>
                      </div>
                    }
                    {
                      sendOtpError &&
                      <div className='send-otp-message'>
                        <p className='send-otp-error'>{sendOtpError}</p>
                      </div>
                    }
                  </div>
                </div>

              )}
              {verifyModal && (
                <div className={`${classes.paper} modal-div modal-mobile verif-main`}>
                  <div className='login-header-txt'>Verify Number</div>
                  <div className='modal-logo'>
                    <img src={modalLogo} className='modal-logo-img' />
                    <img src={modalLogo1} className='modal-logo-img-1' />
                  </div>
                  <p className='pin-text'>
                    Enter 4-Digit PIN Sent On
                    <br />
                    {values.phone}
                  </p>
                  <div className='inpt-otp-center'>
                  <OtpInput
                    value={codeValue}
                    onChange={(value) => setCodeValue(value)}
                    numInputs={4}
                    isInputNum={true}
                    className='login-input-code'
                  />
                  
                    {/* {codeArr.map((item, index) => {
                      return (
                        <div>
                          <InputBase
                            type='text'
                            className='login-input-code'
                            value={codeValue}
                            onChange={(e) => handleCodeChange(e, item.code)}
                            inputProps={{ min: 0, max: 9 }}
                          />
                        </div>
                      );
                    })} */}
                  </div>
                  <div>
                    <p> <span style={{ textDecoration: 'underline' }}></span></p>
                  </div>
                  <div className='continue-btn-outer'>
                    <button disabled={loading} className='continue-btn-inner' onClick={() => verifyOtpFuntion()}>Continue</button>
                    {
                      loading &&
                      <Loader height={70} />
                    }
                    {
                      verifyOtpError &&
                      <div className='send-otp-message'>
                        <p>{verifyOtpError}</p>
                      </div>
                    }
                  </div>
                </div>
              )}
              {signInSuccessModal && (
                <div className={`${classes.paper2} modal-div modal-mobile`}>
                  <div className='modal-logo'>
                    <img src={thanksIcon} className='modal-logo-img-2' />
                  </div>
                  <p className='text-sign-success-1'>Thank You For Registering On Jazz Tunes.</p>
                  <i className='text-sign-success-2'>You Will Receive A Confirmation SMS Shortly. </i>
                </div>
              )}
            </div>
          </LoginModal>
        </div>
        </div>
        {renderSideBar &&
        <MySidebar loginUser={true}/>
        }
    </div>

  );
})

const mapStateToProps = ({ authReducer }) => {
  const { loading, otpSuccess, sendOtpData, sendOtpError, verifyOtpSuccess, verifyOtpError, verifyOtpData } = authReducer;
  return { loading, otpSuccess, sendOtpData, sendOtpError, verifyOtpSuccess, verifyOtpError, verifyOtpData };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendOtp: (data) => dispatch(actions.sendOtp(data)),
    otpSuccessHandler: () => dispatch(actions.otpSuccessHandler()),
    verifyOtp: (data) => dispatch(actions.verifyOtp(data)),
    verifyOtpSuccessHandler: () => dispatch(actions.verifyOtpSuccessHandler()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginParentModal);
