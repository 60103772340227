import * as actions from "../actions";
import * as actionsTypes from "../actionTypes";
import { all, fork, put, takeEvery } from "redux-saga/effects";
import AuthService from "../../services/auth.service";
import HttpService from "../../services/http.service";
// import axios from "axios";
import { setUserDataInLocalStorage } from "../../services/utils";

function* loginStart(action) {
  // console.log('In loginStart authSagas.js ',action.payload);
  try {
    const response = yield AuthService.login({
      username: action.payload.mobile_no,
      password: action.payload.password
    });

    //console.log('response ',response.data);
    // if (response.data.accessToken) {
    //   HttpService.setToken(response.data.accessToken);//set token in header
    //   let user = response?.data?.user || {}; 
    //   HttpService.setUser(user._id);// set userId and role in header
    //   //console.log('storing in localstorage ',user);
    //   localStorage.setItem('user', JSON.stringify(user)); 
    //   yield put(actions.loginSuccess(response.data));
    if (response?.data?.isSuccess) {
      let user = response?.data || {};
      setUserDataInLocalStorage(user?.data?.user); // This is the main user it will not change in localstorage if user switch between sub acc
      HttpService.setUserId(user?.data?.user?.userId);// set userId and role in header
      HttpService.setToken(user?.data?.token?.token);
      localStorage.setItem('user', JSON.stringify(user?.data?.user));
      localStorage.setItem('token', user?.data?.token?.token);
      // const currentTime = Date.now();
      // const tenMinutesLater = new Date(currentTime + 10 * 60 * 1000);
      // localStorage.setItem('auth_expiry', tenMinutesLater.getTime());
      localStorage.setItem('auth_expiry', user?.data?.token?.expiry);
      localStorage.setItem('userid', user?.data?.user?.userId);
      yield put(actions.loginSuccess(response.data));
    } else {
      yield put(actions.loginFailure("Invalid Username/Password"));
    }
  } catch (err) {
    console.log('err loginStart authSagas.js ', err)
    yield put(actions.loginFailure('Invalid Username/Password'));
  }
}

function* loginStartSaga() {
  yield takeEvery(actionsTypes.LOGIN_START, loginStart);
}

// function* otpGenStart(action) {
//   console.log('In otpGenStart Saga ',action.payload);
//   try {
//     const response = yield AuthService.login({
//       email: action.payload.email
//     });

//     if (response.data && response.data.statusCode === 200) { 
//       yield put(actions.genOtpSuccess(response.data));
//     } else {
//       yield put(actions.genOtpFailure("Invalid Username/Password"));
//     }
//   } catch (err) {
//     console.log('err ', err)
//     let error = err?.response?.data?.message;

//     if (!error) {
//       error = err?.response?.message || "Network error";
//     }
//     yield put(actions.genOtpFailure(error));
//   }
// }

// function* otpGenStartSaga() {
//   yield takeEvery(actionsTypes.OTP_GEN, otpGenStart);
// }

function* signUpStart(action) {
  try {
    let response = ''
    let payload = action.payload;
    // let create = payload.create;
    // delete payload.create
    response = yield AuthService.signUp(payload);
    // console.log("response?.data?.isSuccess",response?.data)
    if (response?.data?.isSuccess) {
      let user = response?.data || {};
      setUserDataInLocalStorage(user?.data?.user); // This is the main user it will not change in localstorage if user switch between sub acc
      HttpService.setUserId(user?.data?.user?.userId);// set userId and role in header
      HttpService.setToken(user?.data?.token?.token);
      localStorage.setItem('user', JSON.stringify(user?.data?.user));
      localStorage.setItem('token', user?.data?.token?.token);
      localStorage.setItem('auth_expiry', user?.data?.token?.expiry);
      localStorage.setItem('userid', user?.data?.user?.userId);
      yield put(actions.signupSuccess(response));
    } else {
      yield put(actions.signupFailure(response?.data));
    }
  } catch (err) {
    // console.log('er ', err)
    let error = err?.response?.data?.message;
    if (!error) {
      error = "network error"
    }
    yield put(actions.signupFailure(error));
  }
}

function* signupStartSaga() {
  yield takeEvery(actionsTypes.SIGNUP_START, signUpStart);
}

function* sendOtp(action) {
  try {
    if (action.payload) {
      // console.log("action.payload",action.payload) 
      const response = yield AuthService.sendOtp(action.payload);
      // console.log('sendOtp resp',response)
      if (response?.data.statusCode === 200) {
        yield put(actions.sendOtpSuccess(response.data));
      } else {
        yield put(actions.sendOtpFailure(response?.data.message));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.sendOtpFailure(error));
  }
}

function* sendOtpSaga() {
  yield takeEvery(actionsTypes.SEND_OTP_START, sendOtp);
}


function* verifyOtp(action) {
  try {
    if (action.payload) {
      // console.log("action.payload",action.payload) 
      const response = yield AuthService.verifyOtp(action.payload);
      // console.log('sendOtp resp',response)
      if (response?.data.statusCode === 200) {
        yield put(actions.verifyOtpSuccess(response.data));
      } else {
        yield put(actions.verifyOtpFailure(response?.data));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.verifyOtpFailure(error));
  }
}

function* verifyOtpSaga() {
  yield takeEvery(actionsTypes.VERIFY_OTP_START, verifyOtp);
}

function* confirmDetailStart(action) {
  try {
    let response = ''
    let payload = action.payload;
    // let create = payload.create;
    // delete payload.create
    response = yield AuthService.confirmDetail(payload);
    if (response?.status === 200) {
      yield put(actions.signupSuccess(response));
    } else {
      yield put(actions.signupFailure(response?.data));
    }
  } catch (err) {
    // console.log('er ', err)
    let error = err?.response?.data?.message;
    if (!error) {
      error = "network error"
    }
    yield put(actions.signupFailure(error));
  }
}

function* confirmDetailSaga() {
  yield takeEvery(actionsTypes.CONFIRM_DETAILS_START, confirmDetailStart);
}

function* preAuthStart(action) {
  try {
    const response = yield AuthService.preAuth();
    if (response?.data?.isSuccess) {
      let preAuthToken = response?.data?.data?.token
      let expiry = response?.data?.data?.expiry
      localStorage.setItem('pre_auth', preAuthToken);
      localStorage.setItem('pre_auth_expiry', expiry);
      if (!localStorage.getItem('token')) {
        HttpService.setToken(preAuthToken);
      }
      yield put(actions.preAuthSuccess(response?.data));
    } else {
      yield put(actions.preAuthFailure(response?.data));
    }
  } catch (err) {
    console.log('err ', err)
    yield put(actions.preAuthFailure(err?.message));
  }
}
function* preAuthStartSaga() {
  yield takeEvery(actionsTypes.PRE_AUTH_START, preAuthStart);
}

function* refreshTokenStart(action) {
  try {
    const config = {
      headers: {
        token: localStorage.getItem("pre_auth"),
        cts: Date.now()
      }
    };
    const response = yield AuthService.refreshToken(config);
    if (response?.data?.isSuccess) {
      let preAuthToken = response?.data?.data?.token
      let expiry = response?.data?.data?.expiry
      localStorage.setItem('pre_auth', preAuthToken);
      localStorage.setItem('pre_auth_expiry', expiry);
      if (!localStorage.getItem('token')) {
        HttpService.setToken(preAuthToken);
      }
      yield put(actions.refreshTokenSuccess(response?.data));
    } else {
      yield put(actions.refreshTokenFailure(response?.data));
    }
  } catch (err) {
    console.log('err ', err)
    yield put(actions.refreshTokenFailure(err));
  }
}
function* refreshTokenStartSaga() {
  yield takeEvery(actionsTypes.REFRESH_TOKEN_START, refreshTokenStart);
}

function* forgetPassword(action) {
  try {
    if (action.payload) {
      const config = {
        headers: {
          email: action.payload.email,
          password: 1234
        }
      };
      // console.log("action.payload",action.payload) 
      const response = yield AuthService.forgetPassword(config);
      console.log('forget password response', response)
      if (response?.data.isSuccess) {
        yield put(actions.forgetPasswordSuccess(response));
      } else {
        yield put(actions.forgetPasswordFailure(response?.data));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.forgetPasswordFailure(error));
  }
}

function* forgetPasswordSaga() {
  yield takeEvery(actionsTypes.FORGET_PASSWORD_START, forgetPassword);
}

function* checkUserStart(action) {
  try {
    if (action.payload) {
      console.log('action ', action.payload);
      const config = {
        headers: {
          msisdn: action?.payload?.msisdn,
          email: action?.payload?.email,
        }
      };
      const response = yield AuthService.checkUser(config);
      console.log(response)
      if (response?.data) {
        yield put(actions.checkUserSuccess(response?.data));
      }
      else {
        yield put(actions.checkUserFailure(response?.message));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'
    yield put(actions.checkUserFailure(error));
  }
}
function* checkUserSaga() {
  yield takeEvery(actionsTypes.CHECK_USER_START, checkUserStart);
}


export function* authSaga() {
  yield all([fork(loginStartSaga), fork(signupStartSaga), fork(sendOtpSaga), fork(verifyOtpSaga), fork(confirmDetailSaga), fork(preAuthStartSaga), fork(refreshTokenStartSaga), fork(forgetPasswordSaga), fork(checkUserSaga)]);
}
