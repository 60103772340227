import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    root: {
        '& .Mui-selected': {
            backgroundColor: '#fff; !important',
            color: '#02131D !important',
            borderRadius: '50px',
            fontWeight: '600 !important'
        },
        '& .MuiTab-root': {
            minWidth: '119px',
        },
        flexGrow: 1,
        // backgroundColor: theme.palette.background.paper,
        borderRadius: '40px',
        background: 'rgba(255, 255, 255, 0.05)',
        width: 'auto',
        marginLeft: '40px',
        display: 'inline-flex'
    },
    colorBar: {
        '&.MuiAppBar-colorPrimary': {
            borderRadius: '100px',
            background: 'rgba(0, 0, 0, 0.05) !important',
            color: 'black',
            marginTop: '0px !important',
        }
    },
    cardRoot: {
        // maxWidth: 164,
        borderRadius: '30px',

    },
    media: {
        height: 164,
    },
    cardAction: {
        '&.MuiCardActionArea-root': {
            borderRadius: '30px !important'
        }
    },
    paper: {
        backgroundColor: '#131516',
        outline: 'none',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(6, 4, 3),
        width: '460px',
        height: '463px',
        borderRadius: '20px',
        boxSizing: 'border-box',
        margin:'auto',
        top: '50%',
        transform: 'translate(0%, 24%)',
      },
      paper2: {
        backgroundColor: '#131516',
        outline: 'none',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(6, 4, 3),
        width: '460px',
        // height: '343px',
        borderRadius: '20px',
        boxSizing: 'border-box',
        margin:'auto',
        top: '50%',
        transform: 'translate(0%, 37%)',
      },
}))