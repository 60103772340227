import React, { useEffect, useState,useContext} from 'react';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Loader } from "./index";
import './styles.js'
import '../assets/scss/profilemenu.scss';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "../assets/scss/general.scss";
import "../assets/scss/home.scss";
import { connect } from "react-redux";
import * as actions from "../store/actions";
import { withRouter } from "react-router";
import { useStyles } from './styles.js';
import { APP_DOMAIN_PREFIX } from '../environment';
import { usePlayer } from '../routes/AudioPlayerContext.js';
import UserContext from '../routes/UserContext.js';

function Dashboard(props) {
  const classes = useStyles();
  const [secContent, setSecContent] = useState([]);
  const [open, setOpen] = useState(false);
  const [bannerData, setBannerData] = useState([]);
  const [sectionContent, setContentData] = useState([]);
  const { addSongPlayer,setPlaylist} = usePlayer();
  const { user,type,setType} = useContext(UserContext);


  useEffect(() => {
    function handleClickOutside(event) {
      const clickedInside = event.target.closest('.profile-div');
      const clickedInsideDiv = event.target.closest('.modal-div');
      if (!clickedInside && !clickedInsideDiv) {
        setOpen(false)

      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    props.getBannersStart()
    let userData = JSON.parse(localStorage.getItem('user'));
    if(userData)
      props.getContentData({user_id: userData.id})
    else
      props.getContentData()
  }, []);

  useEffect(() => {
      setType(null)
  }, []);
  useEffect(() => {
    if (props.bannersData) {      
      setBannerData(props.bannersData.data.resp)
    }
  }, [props?.bannersData]);





  useEffect(() => {
    if (props?.contentData) {
      setContentData(props.contentData.data)
      props.getContentHandler();
    }
  }, [props?.contentData])

  useEffect(() => {
    if (props.sectionContentData) {
      setSecContent(props.sectionContentData.data.resp)
    }
  }, [props?.sectionContentData]);

  const sectionItems = (item,tabData) => {
    let user = JSON.parse(localStorage.getItem('user'));
    if(user)
      props.getSectionContentStart({id:tabData.section_ids,user_id:user.id,pageSize:15,page:1});
    else
      props.getSectionContentStart({id:tabData.section_ids,pageSize:15,page:1});
    props.history.push('/tracks', { rowData: item, id:tabData.section_ids });
   }
  const openModal = (item, tabData) => {
    sectionItems(item,tabData)
    
    addSongPlayer(item)
  }

  const artistSongs = (item) => {
    props.getSongsByArtistStart({artist_id:item.artist_id})
    props.history.push('/tracks', { rowData: item, artist_id:item.artist_id });
  }

  useEffect(() => {
    setPlaylist('')
  }, []);

  const handleBannerClick = (row) => {
    addSongPlayer(row)

  };
  return (
    <div>
      <div>
        {/* <div className={classes.root}> */}
        {props.bannersLoading ? <Loader /> :
          <OwlCarousel className='owl-theme mainslider-owl' dots={false}
            responsive={{
              0: {
                stagePadding: 20,
                items: 2,
                margin: 16,
              },
              600: {
                items: 3,
                margin: 10,
                stagePadding: 40,
              },
              768: {
                items: 5,
                margin: 5,
                stagePadding: 20,
              },
              992: {
                items: 5,
                margin: 10,
                stagePadding: 80,
              },
              1000: {
                items: 5,
                margin: 20,
                stagePadding: 60,
              },
              1200: {
                items: 6,
                stagePadding: 80,
                margin: 10
              },
              1366: {
                items: 3,
                stagePadding: 110,
                margin: 10
              },
              1600: {
                items: 3,
                stagePadding: 150,
                margin: 27
              },
              1920: {
                items: 3,
                stagePadding: 60,
                margin: 27
              }
            }}
          >
            {bannerData.map((item, index) => {
              return (
                <Card className={`${classes.cardRoot} card-root-style`} onClick={() => handleBannerClick(item)}>
                  <div>
                    <img src={item.image} />
                  </div>
                </Card>
              );
            })}
          </OwlCarousel>
        }
      </div>

      {props.contentLoading ? (
        <Loader />
      ) : (
        sectionContent.map((tabData, tabIndex) => (
          <div key={tabIndex}>
            <Typography variant="h4" className='tab-heading'>{tabData.title}</Typography>
            <OwlCarousel className='owl-theme mainslider-owl' dots={false}
              responsive={{
                0: {
                  stagePadding: 20,
                  items: 2,
                  margin: 16,
                },
                600: {
                  items: 3,
                  margin: 10,
                  stagePadding: 40,
                },
                768: {
                  items: 5,
                  margin: 5,
                  stagePadding: 20,
                },
                992: {
                  items: 5,
                  margin: 10,
                  stagePadding: 80,
                },
                1000: {
                  items: 5,
                  margin: 20,
                  stagePadding: 60,
                },
                1200: {
                  items: 6,
                  stagePadding: 80,
                  margin: 10
                },
                1366: {
                  items: 6,
                  stagePadding: 110,
                  margin: 10
                },
                1600: {
                  items: 7,
                  stagePadding: 150,
                  margin: 27
                },
                1920: {
                  items: 7,
                  stagePadding: 250,
                  margin: 27
                }
              }}
            >
              {tabData?.content?.map((item, key) => {
                return (
                  tabData.title!=="Singers" 
                  ?
                  <div>
                    <Card className={` card-root-style`} onClick={() => openModal(item, tabData)}>
                      <div><img src={item.thumbnail} className='recommend-thumb' /></div>
                      <div className='card-content' style={{ paddingTop: '15px' }}>
                        <Typography gutterBottom variant="h5" component="h2" className='song-title'>
                          {item.title.length > 18 ? item.title.slice(0, 18) + '...' : item.title}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" className='singer-title'>
                          {item.artist.length > 25 ? item.artist.slice(0, 25) + '...' : item.artist}
                        </Typography>
                      </div>
                    </Card>
                  </div>
                  :
                  <div>
                     <div className={`${classes.cardRoot} card-root-style-artist`} onClick={() => artistSongs(item)}>
                        <div><img src={item.image} className='artist-img' /></div>
                        <div className='card-content-artist'>
                          <Typography variant="body2" color="textSecondary" component="p" className='card-content-artist-txt'>
                            {item.title}
                          </Typography>
                        </div>
                    </div>
                  </div>
                );
              })}
            </OwlCarousel>
          </div>
        ))
      )}

      {/* {isLoading ? (
          <Loader />
        ) : (
          artist.map((tabData, tabIndex) => (
            <div key={tabIndex}>
              <Typography variant="h4" className='tab-heading'>{tabData.type}</Typography>
              <OwlCarousel className='owl-theme mainslider-owl'  dots={false}
                responsive={{
                  0: {
                    stagePadding: 20,
                    items: 2,
                    margin: 5,
                  },
                  600: {
                    items: 3,
                    margin: 10,
                    stagePadding: 40,
                  },
                  768: {
                    items: 5,
                    margin: 5,
                    stagePadding: 20,
                  },
                  992: {
                    items: 5,
                    margin: 10,
                    stagePadding: 80,
                  },
                  1000: {
                    items: 5,
                    margin: 20,
                    stagePadding: 60,
                  },
                  1200: {
                    items: 6,
                    stagePadding: 80,
                    margin: 10
                  },
                  1366: {
                    items: 6,
                    stagePadding: 110,
                    margin: 10
                  },
                  1600: {
                    items: 7,
                    stagePadding: 150,
                    margin: 27
                  },
                  1920: {
                    items: 7,
                    stagePadding: 250,
                    margin: 27
                  }
                }}
              >
                {tabData?.data?.map((item, index) => {
                  return (
                    <div  className={`${classes.cardRoot} card-root-style-artist`} onClick={() => itemDetails(item)}>
                        <div><img src={item.image} className='artist-img' /></div>
                        <div className='card-content-artist'>
                          <Typography variant="body2" color="textSecondary" component="p" className='card-content-artist-txt'>
                            {item.singerName}
                          </Typography>
                        </div>
                    </div>
                  );
                })}
              </OwlCarousel>
            </div>
          ))
        )} */}


      {/* {isLoading ? (
          <Loader />
        ) : (
          veloSoundData.map((tabData, tabIndex) => (
            <div key={tabIndex}>
              <Typography variant="h4" className='tab-heading'>{tabData.type}</Typography>
              <OwlCarousel className='owl-theme mainslider-owl'  dots={false}
                responsive={{
                  0: {
                    stagePadding: 20,
                    items: 2,
                    margin: 5,
                  },
                  600: {
                    items: 3,
                    margin: 10,
                    stagePadding: 40,
                  },
                  768: {
                    items: 5,
                    margin: 5,
                    stagePadding: 20,
                  },
                  992: {
                    items: 5,
                    margin: 10,
                    stagePadding: 80,
                  },
                  1000: {
                    items: 5,
                    margin: 20,
                    stagePadding: 60,
                  },
                  1200: {
                    items: 6,
                    stagePadding: 80,
                    margin: 10
                  },
                  1366: {
                    items: 6,
                    stagePadding: 110,
                    margin: 10
                  },
                  1600: {
                    items: 7,
                    stagePadding: 150,
                    margin: 27
                  },
                  1920: {
                    items: 7,
                    stagePadding: 250,
                    margin: 27
                  }
                }}
              >
                {tabData?.data?.map((item, index) => {
                  return (
                    <div className={`${classes.cardRoot} card-root-style`} onClick={() => itemDetails(item)}>
                        <div><img src={item.image} className='' /></div>
                        <div className='card-content' style={{paddingTop:'15px'}}>
                          <Typography gutterBottom variant="h5" component="h2" className='song-title'>
                            {item.songName}
                          </Typography>
                          <Typography variant="body2" color="textSecondary" component="p" className='singer-title'>
                            {item.singerName}
                          </Typography>
                        </div>
                    </div>
                  );
                })}
              </OwlCarousel>
            </div>
          ))
        )} */}

    </div>

  );
}



//what is needed at start
const mapStateToProps = ({ authReducer, homeReducer }) => {
  const { loggedInSuccess, loggedInError } = authReducer;
  const { contWatchData, contWatchLoading, bannersLoading, liveChannelsLoading, gradesLoading, contentLoading, contentByGradeIdLoading, error, success, bannersData, gradesData, contentData, contentByGradeIdData, channelData, sectionContentData, songsByArtistData } = homeReducer;
  return { contWatchData, contWatchLoading, bannersLoading, liveChannelsLoading, gradesLoading, contentLoading, contentByGradeIdLoading, error, success, bannersData, gradesData, contentData, contentByGradeIdData, channelData, loggedInSuccess, loggedInError, sectionContentData, songsByArtistData };
};
//which actions our function can dispatch
const mapDispatchToProps = (dispatch) => {
  return {
    getBannersStart: (data) => dispatch(actions.getBannersStart(data)),
    getSectionContentStart: (data) => dispatch(actions.getSectionContentStart(data)),
    getContentData: (data) => dispatch(actions.getContentStart(data)),
    getContentHandler: (data) => dispatch(actions.getContentHandler()),
    getSongsByArtistStart: (data) => dispatch(actions.getSongsByArtistStart(data)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard));
