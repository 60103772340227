import * as actionTypes from "../actionTypes";

export const getAllContentSubscriptionDataStart = (data) => {
    return {
        type: actionTypes.ALL_CONTENT_SUBSCRIPTION_DATA_START,
        payload: data,
    };
};
export const getAllContentSubscriptionDataSuccess = (data) => {
    return {
        type: actionTypes.ALL_CONTENT_SUBSCRIPTION_DATA_SUCCESS,
        payload: data,
    };
};
export const getAllContentSubscriptionDataFailure = (data) => {
    return {
        type: actionTypes.ALL_CONTENT_SUBSCRIPTION_DATA_FAILURE,
        payload: data,
    };
};

export const getContentSubscriptionDataStart = (data) => {
    return {
        type: actionTypes.CONTENT_SUBSCRIPTION_DATA_START,
        payload: data,
    };
};
export const getContentSubscriptionDataSuccess = (data) => {
    return {
        type: actionTypes.CONTENT_SUBSCRIPTION_DATA_SUCCESS,
        payload: data,
    };
};
export const getContentSubscriptionDataFailure = (data) => {
    return {
        type: actionTypes.CONTENT_SUBSCRIPTION_DATA_FAILURE,
        payload: data,
    };
};

export const getProfileFavouritesDataStart = (data) => {
    return {
        type: actionTypes.PROFILE_FAVOURITES_DATA_START,
        payload: data,
    };
};
export const getProfileFavouritesDataSuccess = (data) => {
    return {
        type: actionTypes.PROFILE_FAVOURITES_DATA_SUCCESS,
        payload: data,
    };
};
export const getProfileFavouritesDataFailure = (data) => {
    return {
        type: actionTypes.PROFILE_FAVOURITES_DATA_FAILURE,
        payload: data,
    };
};

export const setAsFavouriteStart = (data) => {
    return {
        type: actionTypes.SET_AS_FAVOURITE_START,
        payload: data,
    };
};
export const setAsFavouriteSuccess = (data) => {
    return {
        type: actionTypes.SET_AS_FAVOURITE_SUCCESS,
        payload: data,
    };
};
export const setAsFavouriteFailure = (data) => {
    return {
        type: actionTypes.SET_AS_FAVOURITE_FAILURE,
        payload: data,
    };
};
export const updateFavouriteStart = (data) => {
    return {
        type: actionTypes.UPDATE_FAVOURITE_START,
        payload: data,
    };
};
export const updateFavouriteSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_FAVOURITE_SUCCESS,
        payload: data,
    };
};
export const updateFavouriteFailure = (data) => {
    return {
        type: actionTypes.UPDATE_FAVOURITE_FAILURE,
        payload: data,
    };
};
export const setAsFavouriteSuccessHandler = () => {
    return {
        type: actionTypes.SET_AS_FAVOURITE_SUCCESS_HANDLER,
        payload: null,
    };
}

export const searchContentDataStart = (data) => {
    return {
        type: actionTypes.SEARCH_CONTENT_DATA_START,
        payload: data,
    };
};
export const searchContentDataSuccess = (data) => {
    return {
        type: actionTypes.SEARCH_CONTENT_DATA_SUCCESS,
        payload: data,
    };
};
export const searchContentDataFailure = (data) => {
    return {
        type: actionTypes.SEARCH_CONTENT_DATA_FAILURE,
        payload: data,
    };
};

export const setAsJazzTuneStart = (data) => {
    return {
        type: actionTypes.SET_AS_JAZZ_TUNE_START,
        payload: data,
    };
};

export const setAsJazzTuneSuccess = (data) => {
    return {
        type: actionTypes.SET_AS_JAZZ_TUNE_SUCCESS,
        payload: data,
    };
};
export const setAsJazzTuneFailure = (data) => {
    return {
        type: actionTypes.SET_AS_JAZZ_TUNE_FAILURE,
        payload: data,
    };
};
export const setAsJazzTuneSuccessHandler = () => {
    return {
        type: actionTypes.SET_AS_JAZZ_TUNE_SUCCESS_HANDLER,
        payload: null,
    };
};


// Home
export const getWatchVideoStart = (data) => {
    return {
        type: actionTypes.GET_WATCH_VIDEO_START,
        payload: data,
    };
};
export const getWatchVideoSuccess = (data) => {
    return {
        type: actionTypes.GET_WATCH_VIDEO_SUCCESS,
        payload: data,
    };
};
export const getWatchVideoFailure = (data) => {
    return {
        type: actionTypes.GET_WATCH_VIDEO_FAILURE,
        payload: data,
    };
};


export const getBannersStart = (data) => {
    return {
        type: actionTypes.GET_BANNERS_START,
        payload: data,
    };
};
export const getBannersSuccess = (data) => {
    return {
        type: actionTypes.GET_BANNERS_SUCCESS,
        payload: data,
    };
};
export const getBannersFailure = (data) => {
    return {
        type: actionTypes.GET_BANNERS_FAILURE,
        payload: data,
    };
};

export const getSectionContentStart = (data) => {
    return {
        type: actionTypes.GET_SECTION_CONTENT_START,
        payload: data,
    };
};
export const getSectionContentSuccess = (data) => {
    return {
        type: actionTypes.GET_SECTION_CONTENT_SUCCESS,
        payload: data,
    };
};
export const getSectionContentFailure = (data) => {
    return {
        type: actionTypes.GET_SECTION_CONTENT_FAILURE,
        payload: data,
    };
};

export const getSongsByArtistStart = (data) => {
    return {
        type: actionTypes.GET_SONG_BY_ARTIST_START,
        payload: data,
    };
};
export const getSongsByArtistSuccess = (data) => {
    return {
        type: actionTypes.GET_SONG_BY_ARTIST_SUCCESS,
        payload: data,
    };
};
export const getSongsByArtistFailure = (data) => {
    return {
        type: actionTypes.GET_SONG_BY_ARTIST_FAILURE,
        payload: data,
    };
};

export const getLiveChannelsStart = (data) => {
    return {
        type: actionTypes.GET_LIVE_CHANNELS_START,
        payload: data,
    };
};
export const getLiveChannelsSuccess = (data) => {
    return {
        type: actionTypes.GET_LIVE_CHANNELS_SUCCESS,
        payload: data,
    };
};
export const getLiveChannelsFailure = (data) => {
    return {
        type: actionTypes.GET_LIVE_CHANNELS_FAILURE,
        payload: data,
    };
};

export const getLiveChannelByIdStart = (data) => {
    return {
        type: actionTypes.GET_LIVE_CHANNEL_BY_ID_START,
        payload: data,
    };
};
export const getLiveChannelByIdSuccess = (data) => {
    return {
        type: actionTypes.GET_LIVE_CHANNEL_BY_ID_SUCCESS,
        payload: data,
    };
};
export const getLiveChannelByIdFailure = (data) => {
    return {
        type: actionTypes.GET_LIVE_CHANNEL_BY_ID_FAILURE,
        payload: data,
    };
};

export const getGradesStart = (data) => {
    return {
        type: actionTypes.GET_GRADES_START,
        payload: data,
    };
};
export const getGradesSuccess = (data) => {
    return {
        type: actionTypes.GET_GRADES_SUCCESS,
        payload: data,
    };
};
export const getGradesFailure = (data) => {
    return {
        type: actionTypes.GET_GRADES_FAILURE,
        payload: data,
    };
};

export const getContentStart = (data) => {
    return {
        type: actionTypes.GET_CONTENT_START,
        payload: data,
    };
};
export const getContentSuccess = (data) => {
    return {
        type: actionTypes.GET_CONTENT_SUCCESS,
        payload: data,
    };
};
export const getContentFailure = (data) => {
    return {
        type: actionTypes.GET_CONTENT_FAILURE,
        payload: data,
    };
};
export const getContentHandler = () => {
    return {
        type: actionTypes.GET_CONTENT_HANDLER,
        payload: null,
    };
};

export const getContentByGradeIdStart = (data) => {
    return {
        type: actionTypes.GET_CONTENT_BY_GRADE_ID_START,
        payload: data,
    };
};
export const getContentByGradeIdSuccess = (data) => {
    return {
        type: actionTypes.GET_CONTENT_BY_GRADE_ID_SUCCESS,
        payload: data,
    };
};
export const getContentByGradeIdFailure = (data) => {
    return {
        type: actionTypes.GET_CONTENT_BY_GRADE_ID_FAILURE,
        payload: data,
    };
};

export const getSearchStart = (data) => {
    return {
        type: actionTypes.GET_SEARCH_START,
        payload: data,
    };
};

export const getSearchSuccess = (data) => {
    return {
        type: actionTypes.GET_SEARCH_SUCCESS,
        payload: data,
    };
};

export const getContentSearchStart = (data) => {
    return {
        type: actionTypes.GET_CONTENT_SEARCH_START,
        payload: data,
    };
};
export const getContentSearchSuccess = (data) => {
    return {
        type: actionTypes.GET_CONTENT_SEARCH_SUCCESS,
        payload: data,
    };
};
export const getContentSearchFailure = (data) => {
    return {
        type: actionTypes.GET_CONTENT_SEARCH_FAILURE,
        payload: data,
    };
};

export const getSubjectStart = (data) => {
    return {
        type: actionTypes.GET_SUBJECT_START,
        payload: data,
    };
};
export const getSubjectSuccess = (data) => {
    return {
        type: actionTypes.GET_SUBJECT_SUCCESS,
        payload: data,
    };
};
export const getSubjectFailure = (data) => {
    return {
        type: actionTypes.GET_SUBJECT_FAILURE,
        payload: data,
    };
};

export const continueWatchingStart = (data) => {
    return {
        type: actionTypes.CONTINUE_WATCHING_START,
        payload: data,
    };
};
export const continueWatchingSuccess = (data) => {
    return {
        type: actionTypes.CONTINUE_WATCHING_SUCCESS,
        payload: data,
    };
};
export const continueWatchingFailure = (data) => {
    return {
        type: actionTypes.CONTINUE_WATCHING_FAILURE,
        payload: data,
    };
};

export const updateDurationWatchedStart = (data) => {
    return {
        type: actionTypes.UPDATE_DURATION_WATCHED_START,
        payload: data,
    };
};
export const updateDurationWatchedSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_DURATION_WATCHED_SUCCESS,
        payload: data,
    };
};
export const updateDurationWatchedFailure = (data) => {
    return {
        type: actionTypes.UPDATE_DURATION_WATCHED_FAILURE,
        payload: data,
    };
};

export const getContentByChapterIdStart = (data) => {
    return {
        type: actionTypes.GET_CONTENT_BY_CHAPTER_ID_START,
        payload: data,
    };
};
export const getContentByChapterIdSuccess = (data) => {
    return {
        type: actionTypes.GET_CONTENT_BY_CHAPTER_ID_SUCCESS,
        payload: data,
    };
};
export const getContentByChapterIdFailure = (data) => {
    return {
        type: actionTypes.GET_CONTENT_BY_CHAPTER_ID_FAILURE,
        payload: data,
    };
};

export const watchHistoryStart = (data) => {
    return {
        type: actionTypes.WATCH_HISTORY_START,
        payload: data,
    };
};
export const watchHistorySuccess = (data) => {
    return {
        type: actionTypes.WATCH_HISTORY_SUCCESS,
        payload: data,
    };
};
export const watchHistoryFailure = (data) => {
    return {
        type: actionTypes.WATCH_HISTORY_FAILURE,
        payload: data,
    };
};

export const getIntroStreamLinkStart = (data) => {
    return {
        type: actionTypes.GET_INTRO_STREAM_LINK_START,
        payload: data,
    };
};
export const getIntroStreamLinkSuccess = (data) => {
    return {
        type: actionTypes.GET_INTRO_STREAM_LINK_SUCCESS,
        payload: data,
    };
};
export const getIntroStreamLinkFailure = (data) => {
    return {
        type: actionTypes.GET_INTRO_STREAM_LINK_FAILURE,
        payload: data,
    };
};

export const getProvinceStart = (data) => {
    return {
        type: actionTypes.GET_PROVINCE_START,
        payload: data,
    };
};
export const getProvinceSuccess = (data) => {
    return {
        type: actionTypes.GET_PROVINCE_SUCCESS,
        payload: data,
    };
};
export const getProvinceFailure = (data) => {
    return {
        type: actionTypes.GET_PROVINCE_FAILURE,
        payload: data,
    };
};

export const getSchoolsByCityStart = (data) => {
    return {
        type: actionTypes.GET_SCHOOLS_BY_CITY_START,
        payload: data,
    };
};
export const getSchoolsByCitySuccess = (data) => {
    return {
        type: actionTypes.GET_SCHOOLS_BY_CITY_SUCCESS,
        payload: data,
    };
};
export const getSchoolsByCityFailure = (data) => {
    return {
        type: actionTypes.GET_SCHOOLS_BY_CITY_FAILURE,
        payload: data,
    };
};

export const homeMessageHandler = () => {
    return {
        type: actionTypes.HOME_MESSAGE_HANDLER,
        payload: null,
    };
};
