import * as actionTypes from "../actionTypes";

const initialState = {
    loading: false,
    catData: null,
    subCatData:null,
    songsByCategoryData:null,
    error: null,
    success:null
};

export const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CATEGORY_START:
            return {...state, loading: true, error: null, success:false,catData: null};
        case actionTypes.GET_CATEGORY_SUCCESS:
            return {...state, loading: false, error: null, success:true,catData: action.payload};
        case actionTypes.GET_CATEGORY_FAILURE:
            return {...state, loading: false, error: action.payload,success:false, catData: null};

            case actionTypes.GET_SONG_BY_CATEGORY_START:
            return { ...state, songsByCategoryLoading: true, error: null, success: null, songsByCategoryData: null };
        case actionTypes.GET_SONG_BY_CATEGORY_SUCCESS:
            return { ...state, songsByCategoryLoading: false, error: null, success: true, songsByCategoryData: action.payload, };
        case actionTypes.GET_SONG_BY_CATEGORY_FAILURE:
            return { ...state, songsByCategoryLoading: false, error: action.payload, success: null, };

        case actionTypes.GET_CATEGORY_DATA_START:
            return {...state, loading: true, error: null, success:false,subCatData: null};
        case actionTypes.GET_CATEGORY_DATA_SUCCESS:
            return {...state, loading: false, error: null, success:true,subCatData: action.payload};
        case actionTypes.GET_CATEGORY_DATA_FAILURE:
            return {...state, loading: false, error: action.payload,success:false, subCatData: null};
        default:
            return {...state};
    }
};
