import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 249;
export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  logoImage: {
    width: "94px",
    height: "auto !important",
  },
  appBar: {
    width: `100% !important`,
  },
  headerWhite: {
    // backgroundColor: '#1B2132 !important',
    backgroundColor: '#fff !important',
    boxShadow: "none !important",
  },
  appBarShift: {
    backgroundColor: '#1B2132',
    color: '#fff',
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarShift1: {
    backgroundColor: '#1B2132',
    marginLeft: 50,
    width: `calc(100% ) !important`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 6,
    fontSize: 25,
  },
  hide: {
    display: "none",
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    paddingBottom: 25,
    height: '100%',
    borderRight: ' 1px solid rgba(198, 47, 73, 0.05)',
    background: '#FFF',
    boxShadow: '0px 4px 20px 0px rgba(239, 54, 82, 0.16)',
  },
  logoTextContainer: {
    width: drawerWidth - 20,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: '#131516',
    // border: '2px solid #000',
    outline: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(6, 4, 3),
    width: '460px',
    height: '647px', //226px
    borderRadius: '20px',
    boxSizing: 'border-box',
  },
  logoTextContainerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
    backgroundColor: "#fff",
    //top:65
  },
  drawerOpen: {
    // width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    height: '90%',
    backgroundColor: "transparent",
    // boxShadow: '0px 4px 20px 0px rgba(239, 54, 82, 0.16) !important',

    //top: 65
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    // width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
    backgroundColor: "#f8fcfd",
    //top:65
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: 'column',
    paddingBottom: 250
  }, small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    padding: 0,
    position: "relative",
    // overflow: "hidden",
    // width: '-moz-available',
    width: `100% !important`,
    marginTop: 73,
    [theme.breakpoints.up("lg")]: {
      // width: `calc(100% - ${drawerWidth}px) !important`,
      marginTop: 110,
    },

    // overflowX: 'auto'
  },

  breadCrumbsWidth: {
    width: `calc(100% - ${drawerWidth + 40}px) !important`,
  },
  loginButton: {
    backgroundColor: '#3FB16B !important',
    boxShadow: '0px 10px 20px rgba(255, 184, 73, 0.2) !important',
    borderRadius: '16px !important',
    fontSize: '12px !important',
    lineHeight: '21px !important',
    textAlign: 'center !important',
    letterSpacing: '0.00050625px !important',
    color: '#FFFFFF !important',
  },
  loginBtn: {
    backgroundColor: '#3FB16B !important',
    boxShadow: '0px 10px 20px rgba(255, 184, 73, 0.2) !important',
    borderRadius: '8px !important',
    width: 325,
    height: 55,
    marginBottom: '175px !important',
    fontFamily: 'IBM Plex Sans !important',
    fontStyle: 'normal !important',
    fontWeight: '600px !important',
    fontSize: '18px !important',
    lineHeight: '23px !important',
    letterSpacing: '0.00050625px !important',
    color: '#FFFFFF !important',
    textTransform: 'none !important'
  },
  signupBtn: {
    backgroundColor: '#3FB16B !important',
    boxShadow: '0px 10px 20px rgba(255, 184, 73, 0.2) !important',
    borderRadius: '8px !important',
    width: 325,
    height: 55,
    marginBottom: '20px !important',
    fontFamily: 'IBM Plex Sans !important',
    fontStyle: 'normal !important',
    fontWeight: '600px !important',
    fontSize: '18px !important',
    lineHeight: '23px !important',
    letterSpacing: '0.00050625px !important',
    color: '#FFFFFF !important',
    textTransform: 'none !important'
  },
  continue: {
    backgroundColor: '#3FB16B !important',
    boxShadow: '0px 10px 20px rgba(255, 184, 73, 0.2) !important',
    borderRadius: '8px !important',
    width: 325,
    height: 55,
    marginBottom: '175px !important',
    fontFamily: 'IBM Plex Sans !important',
    fontStyle: 'normal !important',
    fontWeight: '600px !important',
    fontSize: '18px !important',
    lineHeight: '23px !important',
    letterSpacing: '0.00050625px !important',
    color: '#FFFFFF !important',
    textTransform: 'none !important'
  },
  submit: {
    backgroundColor: '#3FB16B !important',
    boxShadow: '0px 10px 20px rgba(255, 184, 73, 0.2) !important',
    borderRadius: '8px !important',
    width: 325,
    height: 55,
    marginBottom: '20px !important',
    fontFamily: 'IBM Plex Sans !important',
    fontStyle: 'normal !important',
    fontWeight: '600px !important',
    fontSize: '18px !important',
    lineHeight: '23px !important',
    letterSpacing: '0.00050625px !important',
    color: '#FFFFFF !important',
    textTransform: 'none !important'
  },
  addLocation: {
    backgroundColor: '#3FB16B !important',
    boxShadow: '0px 10px 20px rgba(255, 184, 73, 0.2) !important',
    borderRadius: '8px !important',
    width: 325,
    height: 55,
    fontFamily: 'IBM Plex Sans !important',
    fontStyle: 'normal !important',
    fontWeight: '600px !important',
    fontSize: '18px !important',
    lineHeight: '23px !important',
    letterSpacing: '0.00050625px !important',
    color: '#FFFFFF !important',
    textTransform: 'none !important'
  },
  rootButton: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  inputSearchOuter: {
    // flex: 1,
    width: '409px',
    backgroundColor: '#F5F6F7',
    height: "46px",
    fontFamily: "inherit !important",
    borderRadius: '100px',
    background: 'rgba(0, 0, 0, 0.05)',
    paddingLeft: 15
  },
  iconButton: {
    padding: 10,
  },
  footerLogo: {
    position: "absolute",
    bottom: "40px",
    width: "100%",
    '& p': {
      fontWeight: "bold",
      lineHeight: "4px",
      fontSize: "12px"
    },
  },
  tabs: {
    "& .MuiTab-root": {
      width: '6px !important',
      minWidth: '0px !important',
      background: '#DDDEE1',
      minHeight: '0px !important',
      borderRadius: '100% !important',
      height: '6px',
      padding: '0px !important',
      marginRight: '4px'
    },
    "& .MuiTab-root.Mui-selected": {
      width: '19px !important',
      minWidth: '0px !important',
      background: '#3FB16B',
      minHeight: '0px !important',
      borderRadius: '20px !important'
    }
  },
  tabPanelPaddOff: {
    "& > .MuiBox-root": {
      padding: "0px"
    }
  },
  genderButtons: {
    "& > .MuiToggleButton-root.MuiToggleButtonGroup-groupedHorizontal": {
      width: '157px',
      background: '#F6F7FB',
      color: '#B8C0C9',
      border: '1px solid transparent',
      borderRadius: '14px'
    },
    "& > .MuiToggleButton-root.MuiToggleButtonGroup-groupedHorizontal.Mui-selected": {
      background: '#FFFFFF',
      border: '1px dashed #3FB16B',
      color: '#3FB16B'
    }
  },
  radioButtons: {
    "&$checked": {
      color: '#3FB16B'
    }
  },
  schoolRadio: {
    marginRight: '36px !important'
  },
  radioRoot: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 24,
    height: 24,
    backgroundColor: '#F4F7FA'
  },
  checkedIcon: {
    backgroundColor: '#3FB16B',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 24,
      height: 24,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
  },
  sideBarIcon: {
    height: "24px",
    width: "24px",
    backgroundColor: '#404B63',
    marginLeft: "8px"
  },
  toolBarStyle: {
    backgroundColor: 'White',
    height: "111px",
  },
  modalSelectField: {
    '& .MuiSelect-root': {
      padding: "12px 14px 4px",
      border: "1px solid #D8D8D8",
      borderRadius: "6px",
      height: "30px",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "21px",
      color: "#667488"
    }
  },

  schoolSelectField: {
    '& .MuiSelect-root': {
      padding: "12px 14px 4px",
      border: "1px solid #D8D8D8",
      borderRadius: "6px",
      height: "30px",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "21px",
      color: "#667488"
    }
  },

  modalAccount: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperAccount: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    outline: 'none',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    padding: '21px 32px 40px',
    // width: '400px',
    // height: '644px',
    position: 'relative',
    // borderRadius: '20px',
    boxSizing: 'border-box',
  },

  textFieldBorder: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid rgba(14, 151, 255, 0.3)',
    },
  },

  provinceLoader: {
    position: 'absolute',
    right: '35px',
    // marginRight: '15px'
  },
  btnLoader: {
    display: 'flex !important',
    margin: '0 auto'
  },

}));


