import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import MainLayoutRoutes from "./mainRoutes";
import { Confirmation } from "../components";
import Dashboard from "../container/homePage";
import SignIn from "../container/auth/signIn";

import { connect, useDispatch } from "react-redux";

const Search = React.lazy(() => import('../container/homePage/search'));
const MyMusic = React.lazy(() => import('../container/myMusic'));
const BrowseCategory = React.lazy(() => import('../container/browseCategory'));
const CategoryDetails = React.lazy(() => import('../container/categoryDetails'));
const SearchResult = React.lazy(() => import('../container/searchResult'));
const BrowseGenre = React.lazy(() => import('../container/browseGenre'));
const Tracks = React.lazy(() => import('../container/tracks'));
const Profile = React.lazy(() => import('../container/profile'));
const BrowseAll = React.lazy(() => import('../container/browseAll'));
const Video = React.lazy(() => import('../container/tracks/video'));

function Routes(props) {
  const dispatch = useDispatch();

  return (

    <Router>
      <Switch>
        <Route path="/signIn" component={SignIn} />
        <Route path="/create-account" component={SignIn} />
        <Route path="/verification" component={SignIn} />
        <MainLayoutRoutes exact path="/" render={matchprops => (<Dashboard {...matchprops} />)} routeName={'home'} />
        <MainLayoutRoutes exact path="/home" render={matchprops => (<Dashboard {...matchprops} />)} routeName={'home'} />
        <MainLayoutRoutes exact path="/search" render={matchprops => (<Search {...matchprops} />)} routeName={'search'} />
        <MainLayoutRoutes exact path="/my-music" render={matchprops => (<MyMusic {...matchprops} />)} routeName={'myMusic'} />
        <MainLayoutRoutes exact path="/browse-category" render={matchprops => (<BrowseCategory {...matchprops} />)} routeName={'browseCategory'} />
        <MainLayoutRoutes exact path="/category-details" render={matchprops => (<CategoryDetails {...matchprops} />)} routeName={'categoryDetails'} />
        <MainLayoutRoutes exact path="/search-result" render={matchprops => (<SearchResult {...matchprops} />)} routeName={'searchResult'} />
        <MainLayoutRoutes exact path="/genre" render={matchprops => (<BrowseGenre {...matchprops} />)} routeName={'browseGenre'} />
        {/* <MainLayoutRoutes exact path="/genre/:category" render={matchprops => (<BrowseGenre {...matchprops} />)} routeName={'browseGenre'} /> */}
        <MainLayoutRoutes exact path="/tracks" render={matchprops => (<Tracks {...matchprops} />)} routeName={'Tracks'} />
        <MainLayoutRoutes exact path="/profile" render={matchprops => (<Profile {...matchprops} />)} routeName={'Profile'} />
        <MainLayoutRoutes exact path="/categories" render={matchprops => (<BrowseAll {...matchprops} />)} routeName={'Tracks'} />
        <MainLayoutRoutes exact path="/video" render={matchprops => (<Video {...matchprops} />)} routeName={'Video'} />
      </Switch>
      <Confirmation />
    </Router>

  );
}

// export default Routes;
const mapStateToProps = ({ authReducer = {} }) => {
  const { preAuthSuccess, preAuthError, preAuth, loggedInSuccess } = authReducer
  return { preAuthSuccess, preAuthError, preAuth, loggedInSuccess };
};

export default connect(mapStateToProps)(Routes);