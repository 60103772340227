import * as actions from "../actions";
import * as actionsTypes from "../actionTypes";
import { put, takeEvery, all, fork } from "redux-saga/effects";
// import axios from 'axios';
import HomeService from '../../services/home.service'

function* allContentSubscriptionData(action) {
  try {
    if (action.payload) {
      const response = yield HomeService.getContentSubsciptionData(action.payload);

      if (response?.data.statusCode === 200) {
        yield put(actions.getAllContentSubscriptionDataSuccess(response?.data.data));
      } else {
        yield put(actions.getAllContentSubscriptionDataFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.getAllContentSubscriptionDataFailure(error));
  }
}
function* allContentSubscriptionDataSaga() {
  yield takeEvery(actionsTypes.ALL_CONTENT_SUBSCRIPTION_DATA_START, allContentSubscriptionData);
}

function* contentSubscriptionData(action) {
  try {
    if (action.payload) {
      const response = yield HomeService.getContentSubsciptionData(action.payload);

      if (response?.data.statusCode === 200) {
        yield put(actions.getContentSubscriptionDataSuccess(response?.data.data));
      } else {
        yield put(actions.getContentSubscriptionDataFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.getContentSubscriptionDataFailure(error));
  }
}
function* contentSubscriptionDataSaga() {
  yield takeEvery(actionsTypes.CONTENT_SUBSCRIPTION_DATA_START, contentSubscriptionData);
}

function* profileFavouritesData(action) {
  try {
    if (action.payload) {
      const response = yield HomeService.getProfileFavouritesData(action.payload);

      if (response?.data.statusCode === 200) {
        yield put(actions.getProfileFavouritesDataSuccess(response?.data.data));
      } else {
        yield put(actions.getProfileFavouritesDataFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.getProfileFavouritesDataFailure(error));
  }
}
function* profileFavouritesDataSaga() {
  yield takeEvery(actionsTypes.PROFILE_FAVOURITES_DATA_START, profileFavouritesData);
}

function* searchContentData(action) {
  try {
    if (action.payload) {
      const response = yield HomeService.searchContentData(action.payload);

      if (response?.data.statusCode === 200) {
        yield put(actions.searchContentDataSuccess(response?.data.data));
      } else {
        yield put(actions.searchContentDataFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.searchContentDataFailure(error));
  }
}
function* searchContentDataSaga() {
  yield takeEvery(actionsTypes.SEARCH_CONTENT_DATA_START, searchContentData);
}

function* setAsFavourite(action) {
  try {
    if (action.payload) {
      const response = yield HomeService.setAsFavourite(action.payload);

      if (response?.data.statusCode === 201) {
        yield put(actions.setAsFavouriteSuccess(response?.data));
      } else {
        yield put(actions.setAsFavouriteFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.setAsFavouriteFailure(error));
  }
}
function* setAsFavouriteSaga() {
  yield takeEvery(actionsTypes.SET_AS_FAVOURITE_START, setAsFavourite);
}

function* updateFavourite(action) {
  try {
    if (action.payload) {
      const response = yield HomeService.updateFavourite(action.payload.id, action.payload);

      if (response?.data.statusCode === 200) {
        yield put(actions.updateFavouriteSuccess(response?.data));
      } else {
        yield put(actions.updateFavouriteFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.updateFavouriteFailure(error));
  }
}
function* updateFavouriteSaga() {
  yield takeEvery(actionsTypes.UPDATE_FAVOURITE_START, updateFavourite);
}

function* setAsJazzTune(action) {
  try {
    if (action.payload) {
      const response = yield HomeService.setAsJazzTune(action.payload);

      if (response?.data.statusCode === 201) {
        yield put(actions.setAsJazzTuneSuccess(response?.data));
      } else {
        yield put(actions.setAsJazzTuneFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.setAsJazzTuneFailure(error));
  }
}
function* setAsJazzTuneSaga() {
  yield takeEvery(actionsTypes.SET_AS_JAZZ_TUNE_START, setAsJazzTune);
}

function* getWatchVideoStart(action) {
  try {
    if (action.payload) {
      let payload = {}
      let response;
      if(action.payload.user){
        payload = {
          "user_id" : action.payload.user,
          "content_id":action.payload.song
        }

      response = yield HomeService.setViewLogin(payload);

      }
      else{
        payload = {
          "content_id":action.payload.song
        }

      response = yield HomeService.setView(payload);
      }
       
      // const content_code = action?.payload?.contentCode;
      // const user_id = action?.payload?.userId;
      // let params = { content_code, user_id };
      if (response?.data) {
        yield put(actions.getWatchVideoSuccess(response?.data));
      } else {
        yield put(actions.getWatchVideoFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.getWatchVideoFailure(error));
  }
}
function* getWatchVideoSaga() {
  yield takeEvery(actionsTypes.GET_WATCH_VIDEO_START, getWatchVideoStart);
}

function* updateDurationWatchedStart(action) {
  try {
    if (action.payload) {
      const config = {
        headers: {
          contentId: action?.payload?.contentCode,
          durationWatched: action?.payload?.durWatched,
          type: action?.payload?.vidType,
        }
      };
      const response = yield HomeService.updateDurWatched(config);

      if (response?.data) {
        yield put(actions.updateDurationWatchedSuccess(response?.data));
      } else {
        yield put(actions.updateDurationWatchedFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.updateDurationWatchedFailure(error));
  }
}
function* updateDurationWatchedSaga() {
  yield takeEvery(actionsTypes.UPDATE_DURATION_WATCHED_START, updateDurationWatchedStart);
}

function* getBannersStart(action) {
  try {
    const response = yield HomeService.getBanners();
    if (response?.data) {
      yield put(actions.getBannersSuccess(response?.data));
    } else {
      yield put(actions.getBannersFailure("network error"));
    }

  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.getBannersFailure(error));
  }
}
function* getBannersSaga() {
  yield takeEvery(actionsTypes.GET_BANNERS_START, getBannersStart);
}

function* getSectionContentStart(action) {
  try {
    console.log('Payload',action.payload);
    const response = yield HomeService.getSectionContent(action.payload.id, action.payload);
    if (response?.data) {
      yield put(actions.getSectionContentSuccess(response.data));
    } else {
      yield put(actions.getSectionContentFailure("network error"));
    }

  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.getSectionContentFailure(error));
  }
}
function* getSectionContentSaga() {
  yield takeEvery(actionsTypes.GET_SECTION_CONTENT_START, getSectionContentStart);
}

function* getSongsByArtistStart(action) {
  try {
    const response = yield HomeService.getSongsByArtist(action.payload);
    if (response?.data) {      
      yield put(actions.getSongsByArtistSuccess(response.data));
    } else {
      yield put(actions.getSongsByArtistFailure("network error"));
    }

  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.getSongsByArtistFailure(error));
  }
}
function* getSongsByArtistSaga() {
  yield takeEvery(actionsTypes.GET_SONG_BY_ARTIST_START, getSongsByArtistStart);
}

function* getLiveChannelsStart(action) {
  try {
    if (action) {
      // let params = { };

      const response = yield HomeService.getChannel();

      if (response?.data) {
        yield put(actions.getLiveChannelsSuccess(response?.data));
      } else {
        yield put(actions.getLiveChannelsFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.getLiveChannelsFailure(error));
  }
}
function* getLiveChannelsSaga() {
  yield takeEvery(actionsTypes.GET_LIVE_CHANNELS_START, getLiveChannelsStart);
}

function* getLiveChannelByIdStart(action) {
  try {
    if (action.payload) {
      const config = {
        headers: {
          channelid: action?.payload?.id
        }
      };
      // const channel_id = action?.payload?.id;
      // let params = { channel_id };

      const response = yield HomeService.getChannelById(config);

      if (response?.data) {
        yield put(actions.getLiveChannelByIdSuccess(response?.data));
      } else {
        yield put(actions.getLiveChannelByIdFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.getLiveChannelByIdFailure(error));
  }
}
function* getLiveChannelByIdSaga() {
  yield takeEvery(actionsTypes.GET_LIVE_CHANNEL_BY_ID_START, getLiveChannelByIdStart);
}

function* getGradesStart(action) {
  try {
    const response = yield HomeService.getGrades();
    if (response?.data) {
      yield put(actions.getGradesSuccess(response?.data));
    } else {
      yield put(actions.getGradesFailure("network error"));
    }

  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.getGradesFailure(error));
  }
}
function* getGradesSaga() {
  yield takeEvery(actionsTypes.GET_GRADES_START, getGradesStart);
}

function* getContentStart(action) {
  try {
    const response = yield HomeService.getContent(action.payload);
    if (response?.data) {
      yield put(actions.getContentSuccess(response?.data));
    } else {
      yield put(actions.getContentFailure("network error"));
    }

  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.getContentFailure(error));
  }
}
function* getContentSaga() {
  yield takeEvery(actionsTypes.GET_CONTENT_START, getContentStart);
}

function* getContentByGradeIdStart(action) {
  try {
    if (action?.payload) {
      const config = {
        headers: {
          gradeid: action?.payload.gradeId
        }
      };
      // const grade_id = action?.payload?.gradeId;
      // let params = { grade_id };

      const response = yield HomeService.getContentByGradeId(config);

      if (response?.data) {
        yield put(actions.getContentByGradeIdSuccess(response?.data));
      } else {
        yield put(actions.getContentByGradeIdFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.getContentByGradeIdFailure(error));
  }
}
function* getContentByGradeIdSaga() {
  yield takeEvery(actionsTypes.GET_CONTENT_BY_GRADE_ID_START, getContentByGradeIdStart);
}

function* getCityStart(action) {
  try {
    if (action) {
      const response = yield HomeService.getCity();
      if (response?.data) {
        yield put(actions.getCitySuccess(response?.data));
      }
      else {
        yield put(actions.getCityFailure(response?.message));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'
    yield put(actions.getCityFailure(error));
  }
}

function* getCitySaga() {
  yield takeEvery(actionsTypes.GET_CITY_START, getCityStart);
}

function* getSubjectStart(action) {
  try {
    if (action) {
      const response = yield HomeService.getSubject(action.payload);
      if (response?.data?.isSuccess) {
        yield put(actions.getSubjectSuccess(response.data.data));
      }
      else {
        yield put(actions.getSubjectFailure(response?.message));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'
    yield put(actions.getSubjectFailure(error));
  }
}
function* getSubjectSaga() {
  yield takeEvery(actionsTypes.GET_SUBJECT_START, getSubjectStart);
}

function* continueWatchingStart(action) {
  try {
    // const config = {
    //   headers: {
    //     userid: action?.payload?.userId
    //   }
    // };

    const response = yield HomeService.continueWatching();
    if (response?.data) {
      yield put(actions.continueWatchingSuccess(response?.data));
    } else {
      yield put(actions.continueWatchingFailure("network error"));
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.continueWatchingFailure(error));
  }
}
function* continueWatchingStartSaga() {
  yield takeEvery(actionsTypes.CONTINUE_WATCHING_START, continueWatchingStart);
}

function* getContentByChapterIdStart(action) {
  try {
    if (action?.payload) {
      const config = {
        headers: {
          chapterid: action?.payload.chapterId
        }
      };
      // const grade_id = action?.payload?.gradeId;
      // let params = { grade_id };

      const response = yield HomeService.getContentByChapterId(config);

      if (response?.data) {
        yield put(actions.getContentByChapterIdSuccess(response?.data));
      } else {
        yield put(actions.getContentByChapterIdFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.getContentByChapterIdFailure(error));
  }
}
function* getContentByChapterIdSaga() {
  yield takeEvery(actionsTypes.GET_CONTENT_BY_CHAPTER_ID_START, getContentByChapterIdStart);
}

function* getContentSearchStart(action) {
  try {
    let config = {};
    if (action?.payload) {
      config = {
        headers: {
          keyword: action?.payload.search
        }
      };
    } 
    const response = yield HomeService.searchContent(config);
    if (response?.data?.isSuccess) {
      yield put(actions.getContentSearchSuccess(response?.data));
    } else {
      yield put(actions.getContentSearchFailure("network error"));
    }

  } catch (err) {
    let error = err?.response?.message
    if (!error) error = 'network error'
    yield put(actions.getContentFailure(error));
  }
}
function* getContentSearchSagaStart() {
  yield takeEvery(actionsTypes.GET_CONTENT_SEARCH_START, getContentSearchStart);
}

function* getSearchStart(action) {
  try {
    // let config = {};
    if (action?.payload) {
      yield put(actions.getSearchSuccess(action.payload));
    }

  } catch (err) {
    let error = err?.response?.message
    if (!error) error = 'network error'
    //yield put(actions.getContentFailure(error));
  }
}
function* getSearchSagaStart() {
  yield takeEvery(actionsTypes.GET_SEARCH_START, getSearchStart);
}

function* watchHistoryStart(action) {
  try {
    // const config = {
    //   headers: {
    //     userid: action?.payload?.userId
    //   }
    // };

    const response = yield HomeService.watchHistory();
    if (response?.data) {
      yield put(actions.watchHistorySuccess(response?.data));
    } else {
      yield put(actions.watchHistoryFailure("network error"));
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.watchHistoryFailure(error));
  }
}
function* watchHistorySaga() {
  yield takeEvery(actionsTypes.WATCH_HISTORY_START, watchHistoryStart);
}

function* getIntroStreamLinkStart(action) {
  try {
    // const config = {
    //   headers: {
    //     userid: action?.payload?.userId
    //   }
    // };

    const response = yield HomeService.introStreamLink();
    if (response?.data) {
      yield put(actions.getIntroStreamLinkSuccess(response?.data));
    } else {
      yield put(actions.getIntroStreamLinkFailure("network error"));
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.getIntroStreamLinkFailure(error));
  }
}
function* getIntroStreamLinkSaga() {
  yield takeEvery(actionsTypes.GET_INTRO_STREAM_LINK_START, getIntroStreamLinkStart);
}

function* getProvinceStart(action) {
  try {
    if (action) {
      const response = yield HomeService.getProvince();
      if (response?.data) {
        yield put(actions.getProvinceSuccess(response?.data));
      }
      else {
        yield put(actions.getProvinceFailure(response?.message));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'
    yield put(actions.getProvinceFailure(error));
  }
}
function* getProvinceSaga() {
  yield takeEvery(actionsTypes.GET_PROVINCE_START, getProvinceStart);
}

function* getSchoolsByCityStart(action) {
  try {
    if (action.payload) {
      const config = {
        headers: {
          cityid: action?.payload?.cityId,
          start: 0,
          fetch: 200
        }
      };
      const response = yield HomeService.getSchoolsByCity(config);
      if (response?.data) {
        yield put(actions.getSchoolsByCitySuccess(response?.data));
      }
      else {
        yield put(actions.getSchoolsByCityFailure(response?.message));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'
    yield put(actions.getSchoolsByCityFailure(error));
  }
}
function* getSchoolsByCitySaga() {
  yield takeEvery(actionsTypes.GET_SCHOOLS_BY_CITY_START, getSchoolsByCityStart);
}

export function* homeSaga() {
  yield all([
    fork(allContentSubscriptionDataSaga),
    fork(contentSubscriptionDataSaga),
    fork(profileFavouritesDataSaga),
    fork(setAsFavouriteSaga),
    fork(updateFavouriteSaga),
    fork(searchContentDataSaga),
    fork(setAsJazzTuneSaga),
    fork(getWatchVideoSaga),
    fork(getBannersSaga),
    fork(getSectionContentSaga),
    fork(getSongsByArtistSaga),
    fork(getGradesSaga),
    fork(getContentSaga),
    fork(getContentByGradeIdSaga),
    fork(getContentSearchSagaStart),
    fork(getSearchSagaStart),
    fork(getLiveChannelsSaga),
    fork(getLiveChannelByIdSaga),
    fork(getCitySaga),
    fork(getSubjectSaga),
    fork(continueWatchingStartSaga),
    fork(updateDurationWatchedSaga),
    fork(getContentByChapterIdSaga),
    fork(watchHistorySaga),
    fork(getIntroStreamLinkSaga),
    fork(getProvinceSaga),
    fork(getSchoolsByCitySaga),
  ]);
}
