import './App.css';
import './assets/index.scss'
import React, { FC, Suspense } from 'react';
import Routes from "./routes/routes";
import { Provider } from 'react-redux';
import PersistedStore from "./store";
import axios from "axios";
import { logout } from "../src/store/actions";
import { ThemeProvider } from './components/themeProvider'
import './i18n'

const store = PersistedStore.getDefaultStore().store;

const App: FC = () => {

  return (
      <Provider store={store}>
        <ThemeProvider>
          <Suspense fallback={null}>
            <Routes />
          </Suspense>
        </ThemeProvider>
      </Provider>
  )
}

const { dispatch } = store;
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  console.log("error ", error);
  if (error.response.status == 403 || error.response.status == 401) {
    window.location.reload();
  }
  return Promise.reject(error);
});


export default App;

// https://www.codementor.io/blog/react-optimization-5wiwjnf9hj#.YbD42UeP0l4.linkedin
