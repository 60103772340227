import React from 'react';

import {CSVLink} from "react-csv";
import Loader from '../loading'

function DownloadAddButton(props) {
  const {addHandler, downloadHandler, loading, fileName} = props;
  return (
    <div>
      {/* /////add-icon/// */}
			<span className={downloadHandler ? 'add-icon two-iconst' : 'add-icon'}>     
				{downloadHandler &&
        <CSVLink
          filename={fileName ? `${fileName}.csv` : "my-file.csv"}
          data={props?.data?.data}
          headers={props?.data?.headers}
          asyncOnClick={true}
          onClick={!loading && downloadHandler}
        >
          <span className={`cn_item first-ic cursor-pointer ${loading && ' display-flex'}`} title='Export File'>
          {!loading ?
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="21" viewBox="0 0 13 21" fill=" #1B2132">
              <path
                d="M5.925,16.9h0c0.019,0.019.039,0.037,0.059,0.055l0.029,0.022L6.049,17l0.035,0.022,0.033,0.02,0.037,
                  0.018,0.035,0.017L6.225,17.1l0.039,0.014L6.3,17.119l0.04,0.01,0.043,0.007L6.42,17.14a0.8,0.8,0,0,0,.16,
                  0l0.036-.005,0.043-.007,0.04-.01,0.037-.01L6.774,17.1l0.037-.014,0.035-.017,0.037-.018,0.033-.02L6.951,
                  17l0.035-.026,0.029-.022c0.021-.017.04-0.035,0.059-0.055h0l5.687-5.814a0.844,0.844,0,0,0,0-1.175,0.8,
                  0.8,0,0,0-1.149,0l-4.3,4.4V1.364a0.813,0.813,0,1,0-1.625,0V14.309l-4.3-4.4a0.8,0.8,0,0,0-1.149,0,0.844,
                  0.844,0,0,0,0,1.175Zm6.262,1.9H0.813a0.831,0.831,0,0,0,0,1.661H12.187A0.831,0.831,0,0,0,12.187,18.806Z"
              />
            </svg> :
            <Loader className={'small-loading'}/>}
          </span>
        </CSVLink>
        }
        {addHandler &&
        <span className="cn_item second-ic cursor-pointer" onClick={addHandler} title='Add New'>
					<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill=" #fff">
						<path
              d="M20.5,11.086c-0.065.456-.112,0.915-0.2,1.366a9.81,9.81,0,0,1-1.472,3.563,0.781,0.781,0,1,1-1.308-.849,
							8.341,8.341,0,0,0,1.307-3.353,8.226,8.226,0,0,0-1.9-6.767,8.243,8.243,0,0,0-5.183-2.893,8.467,8.467,0,0,
							0-9.2,5.553A8.354,8.354,0,0,0,6.1,17.676a8.2,8.2,0,0,0,8.663.107,0.935,0.935,0,0,1,.665-0.158,0.78,0.78,0,0
							,1,.234,1.444,9.127,9.127,0,0,1-2.672,1.111c-0.562.129-1.139,0.193-1.709,0.287-0.051.008-.1,0.022-0.15,
							0.033H9.875c-0.05-.011-0.1-0.025-0.15-0.033-0.469-.069-0.944-0.111-1.407-0.208A10,10,0,0,1,.654,
							12.243C0.592,11.872.55,11.5,0.5,11.125V9.875c0.011-.056.025-0.112,0.033-0.169C0.6,9.243.644,8.775,0.74,
							8.318A10,10,0,0,1,8.757.653C9.128,0.592,9.5.55,9.875,0.5h1.25a1.116,1.116,0,0,0,.151.032,9.594,9.594,0,0,1,
							4.74,1.628,9.849,9.849,0,0,1,4.332,6.617c0.066,0.363.1,0.732,0.152,1.1v1.211ZM9.719,9.719c0-.925,0-1.822,
							0-2.719a0.78,0.78,0,1,1,1.56.011c0,0.833,0,1.666,0,2.5,0,0.063,0,.127,0,0.209h2.669a0.783,0.783,0,1,1,.008,
							1.562c-0.813,0-1.627,0-2.44,0H11.281V11.5q0,1.22,0,2.44a0.784,0.784,0,1,1-1.562.008c0-.878,0-1.756
							,0-2.666H9.507c-0.839,0-1.679,0-2.518,0a0.766,0.766,0,0,1-.764-0.6,0.754,0.754,0,0,1,.388-0.856,1.041,1.041
							,0,0,1,.43-0.1c0.813-.009,1.627,0,2.44,0H9.719Z"/>
					</svg>
				</span>}
			</span>
    </div>
  );
}

export default DownloadAddButton;
