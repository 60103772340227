import React, { createContext, useState,useEffect } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [type, setType] = useState(null);
  const [isModel, setModel] = useState(null);
  const [isShow, setShow] = useState(true);

  const login = (userData,type) => {
    setType(type)
    if(userData){
      setUser(userData);
      setShow(false)
    }
  };

  useEffect(() => {
    const storedLoginStatus = localStorage.getItem('isLoggedIn');
    if (storedLoginStatus) {
      setUser(true);
      setUser(false);
      setShow(false)

    }
  }, []);

  useEffect(() => {
    if (user) {
      setShow(false)
    }
  }, []);

  const logout = () => {
    setUser(null);      
    setShow(true)

  };

  return (
    <UserContext.Provider value={{ user, login, logout,type,setType,isModel,setModel,setShow,isShow}}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
