// Users Action Types
 

export const MESSAGE_HANDLER = "MESSAGE_HANDLER"; 

/////////////////////////////File Uplaod Action types

export const MANAGE_FILE_START = "MANAGE_FILE_START";
export const MANAGE_FILE_SUCCESS = "MANAGE_FILE_SUCCESS";
export const MANAGE_FILE_FAILURE = "MANAGE_FILE_FAILURE";

export const GET_FILE_LIST_START = "GET_FILE_LIST_START";
export const GET_FILE_LIST_SUCCESS = "GET_FILE_LIST_SUCCESS";
export const GET_FILE_LIST_FAILURE = "GET_FILE_LIST_FAILURE";

export const GET_CATEGORY_START = "GET_CATEGORY_START";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_FAILURE = "GET_CATEGORY_FAILURE";


export const GET_CATEGORY_DATA_START = "GET_CATEGORY_DATA_START";
export const GET_CATEGORY_DATA_SUCCESS = "GET_CATEGORY_DATA_SUCCESS";
export const GET_CATEGORY_DATA_FAILURE = "GET_CATEGORY_DATA_FAILURE";

export const DELETE_FILE_START = "DELETE_FILE_START";
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";
export const DELETE_FILE_FAILURE = "DELETE_FILE_FAILURE";


export const SIGNUP_START = "SIGNUP_START";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

// PRE-AUTH & REFRESH TOKEN
export const PRE_AUTH_START = "PRE_AUTH_START";
export const PRE_AUTH_SUCCESS = "PRE_AUTH_SUCCESS";
export const PRE_AUTH_FAILURE = "PRE_AUTH_FAILURE";

export const REFRESH_TOKEN_START = "REFRESH_TOKEN_START";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILURE = "REFRESH_TOKEN_FAILURE";

export const SEND_OTP_START = "SEND_OTP_START";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAILURE = "SEND_OTP_ERROR";
export const OTP_SUCCESS_HANDLER = "OTP_SUCCESS_HANDLER";


export const VERIFY_OTP_START = "VERIFY_OTP_START";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_ERROR";
export const VERIFY_OTP_SUCCESS_HANDLER = "VERIFY_OTP_SUCCESS_HANDLER";

export const SET_AS_FAVOURITE_START = "SET_AS_FAVOURITE_START";
export const SET_AS_FAVOURITE_SUCCESS = "SET_AS_FAVOURITE_SUCCESS";
export const SET_AS_FAVOURITE_FAILURE = "SET_AS_FAVOURITE_FAILURE";

export const UPDATE_FAVOURITE_START = "UPDATE_FAVOURITE_START";
export const UPDATE_FAVOURITE_SUCCESS = "UPDATE_FAVOURITE_SUCCESS";
export const UPDATE_FAVOURITE_FAILURE = "UPDATE_FAVOURITE_FAILURE";

export const SET_AS_FAVOURITE_SUCCESS_HANDLER = "SET_AS_FAVOURITE_SUCCESS_HANDLER";

export const SEARCH_CONTENT_DATA_START = "SEARCH_CONTENT_DATA_START";
export const SEARCH_CONTENT_DATA_SUCCESS = "SEARCH_CONTENT_DATA_SUCCESS";
export const SEARCH_CONTENT_DATA_FAILURE = "SEARCH_CONTENT_DATA_FAILURE";

export const FORGET_PASSWORD_START = "FORGET_PASSWORD_START";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAILURE = "FORGET_PASSWORD_FAILURE";

export const GET_CITY_START = "GET_CITY_START";
export const GET_CITY_SUCCESS = "GET_CITY_SUCCESS";
export const GET_CITY_FAILURE = "GET_CITY_FAILURE";

export const CONFIRM_DETAILS_START = "CONFIRM_DETAILS_START";
export const CONFIRM_DETAILS_SUCCESS = "CONFIRM_DETAILS_SUCCESS";
export const CONFIRM_DETAILS_FAILURE = "CONFIRM_DETAILS_ERROR";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const CHECK_USER_START = "CHECK_USER_START";
export const CHECK_USER_SUCCESS = "CHECK_USER_SUCCESS";
export const CHECK_USER_FAILURE = "CHECK_USER_FAILURE";
 
export const LOGIN_ERROR_HANDLER_SUCCESS = "LOGIN_ERROR_HANDLER_SUCCESS";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";


export const DASHBOARD_START = "DASHBOARD_START";
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";
export const DASHBOARD_ERROR_HANDLER_SUCCESS = "DASHBOARD_ERROR_HANDLER_SUCCESS";

/** HOME */
export const ALL_CONTENT_SUBSCRIPTION_DATA_START = "ALL_CONTENT_SUBSCRIPTION_DATA_START";
export const ALL_CONTENT_SUBSCRIPTION_DATA_SUCCESS = "ALL_CONTENT_SUBSCRIPTION_DATA_SUCCESS";
export const ALL_CONTENT_SUBSCRIPTION_DATA_FAILURE = "ALL_CONTENT_SUBSCRIPTION_DATA_FAILURE";

export const CONTENT_SUBSCRIPTION_DATA_START = "CONTENT_SUBSCRIPTION_DATA_START";
export const CONTENT_SUBSCRIPTION_DATA_SUCCESS = "CONTENT_SUBSCRIPTION_DATA_SUCCESS";
export const CONTENT_SUBSCRIPTION_DATA_FAILURE = "CONTENT_SUBSCRIPTION_DATA_FAILURE";

export const PROFILE_FAVOURITES_DATA_START = "PROFILE_FAVOURITES_DATA_START";
export const PROFILE_FAVOURITES_DATA_SUCCESS = "PROFILE_FAVOURITES_DATA_SUCCESS";
export const PROFILE_FAVOURITES_DATA_FAILURE = "PROFILE_FAVOURITES_DATA_FAILURE";

export const SET_AS_JAZZ_TUNE_START = "SET_AS_JAZZ_TUNE_START";
export const SET_AS_JAZZ_TUNE_SUCCESS = "SET_AS_JAZZ_TUNE_SUCCESS";
export const SET_AS_JAZZ_TUNE_FAILURE = "SET_AS_JAZZ_TUNE_FAILURE";
export const SET_AS_JAZZ_TUNE_SUCCESS_HANDLER = "SET_AS_JAZZ_TUNE_SUCCESS_HANDLER";

export const GET_WATCH_VIDEO_START = "GET_WATCH_VIDEO_START";
export const GET_WATCH_VIDEO_SUCCESS = "GET_WATCH_VIDEO_SUCCESS";
export const GET_WATCH_VIDEO_FAILURE = "GET_WATCH_VIDEO_FAILURE";

export const GET_BANNERS_START = "GET_BANNERS_START";
export const GET_BANNERS_SUCCESS = "GET_BANNERS_SUCCESS";
export const GET_BANNERS_FAILURE = "GET_BANNERS_FAILURE";

export const GET_SECTION_CONTENT_START = "GET_SECTION_CONTENT_START";
export const GET_SECTION_CONTENT_SUCCESS = "GET_SECTION_CONTENT_SUCCESS";
export const GET_SECTION_CONTENT_FAILURE = "GET_SECTION_CONTENT_FAILURE";

export const GET_SONG_BY_ARTIST_START = "GET_SONG_BY_ARTIST_START";
export const GET_SONG_BY_ARTIST_SUCCESS = "GET_SONG_BY_ARTIST_SUCCESS";
export const GET_SONG_BY_ARTIST_FAILURE = "GET_SONG_BY_ARTIST_FAILURE";

export const GET_SONG_BY_CATEGORY_START = "GET_SONG_BY_CATEGORY_START";
export const GET_SONG_BY_CATEGORY_SUCCESS = "GET_SONG_BY_CATEGORY_SUCCESS";
export const GET_SONG_BY_CATEGORY_FAILURE = "GET_SONG_BY_CATEGORY_FAILURE";

export const GET_GRADES_START = "GET_GRADES_START";
export const GET_GRADES_SUCCESS = "GET_GRADES_SUCCESS";
export const GET_GRADES_FAILURE = "GET_GRADES_FAILURE";

export const GET_CONTENT_START = "GET_CONTENT_START";
export const GET_CONTENT_SUCCESS = "GET_CONTENT_SUCCESS";
export const GET_CONTENT_FAILURE = "GET_CONTENT_FAILURE";
export const GET_CONTENT_HANDLER = "GET_CONTENT_HANDLER";

export const GET_CONTENT_BY_GRADE_ID_START = "GET_CONTENT_BY_GRADE_ID_START";
export const GET_CONTENT_BY_GRADE_ID_SUCCESS = "GET_CONTENT_BY_GRADE_ID_SUCCESS";
export const GET_CONTENT_BY_GRADE_ID_FAILURE = "GET_CONTENT_BY_GRADE_ID_FAILURE";

export const GET_SEARCH_START = "GET_SEARCH_START";
export const GET_SEARCH_SUCCESS = "GET_SEARCH_SUCCESS";
export const GET_CONTENT_SEARCH_START = "GET_CONTENT_SEARCH_START";
export const GET_CONTENT_SEARCH_SUCCESS = "GET_CONTENT_SEARCH_SUCCESS";
export const GET_CONTENT_SEARCH_FAILURE = "GET_CONTENT_SEARCH_FAILURE";

export const GET_LIVE_CHANNELS_START = "GET_LIVE_CHANNELS_START";
export const GET_LIVE_CHANNELS_SUCCESS = "GET_LIVE_CHANNELS_SUCCESS";
export const GET_LIVE_CHANNELS_FAILURE = "GET_LIVE_CHANNELS_FAILURE";

export const GET_LIVE_CHANNEL_BY_ID_START = "GET_LIVE_CHANNEL_BY_ID_START";
export const GET_LIVE_CHANNEL_BY_ID_SUCCESS = "GET_LIVE_CHANNEL_BY_ID_SUCCESS";
export const GET_LIVE_CHANNEL_BY_ID_FAILURE = "GET_LIVE_CHANNEL_BY_ID_FAILURE";

export const GET_SUBJECT_START = "GET_SUBJECT_START";
export const GET_SUBJECT_SUCCESS = "GET_SUBJECT_SUCCESS";
export const GET_SUBJECT_FAILURE = "GET_SUBJECT_FAILURE";

export const CONTINUE_WATCHING_START = "CONTINUE_WATCHING_START";
export const CONTINUE_WATCHING_SUCCESS = "CONTINUE_WATCHING_SUCCESS";
export const CONTINUE_WATCHING_FAILURE = "CONTINUE_WATCHING_FAILURE";

export const UPDATE_DURATION_WATCHED_START = "UPDATE_DURATION_WATCHED_START";
export const UPDATE_DURATION_WATCHED_SUCCESS = "UPDATE_DURATION_WATCHED_SUCCESS";
export const UPDATE_DURATION_WATCHED_FAILURE = "UPDATE_DURATION_WATCHED_FAILURE";

export const GET_CONTENT_BY_CHAPTER_ID_START = "GET_CONTENT_BY_CHAPTER_ID_START";
export const GET_CONTENT_BY_CHAPTER_ID_SUCCESS = "GET_CONTENT_BY_CHAPTER_ID_SUCCESS";
export const GET_CONTENT_BY_CHAPTER_ID_FAILURE = "GET_CONTENT_BY_CHAPTER_ID_FAILURE";

export const WATCH_HISTORY_START = "WATCH_HISTORY_START";
export const WATCH_HISTORY_SUCCESS = "WATCH_HISTORY_SUCCESS";
export const WATCH_HISTORY_FAILURE = "WATCH_HISTORY_FAILURE";

export const GET_INTRO_STREAM_LINK_START = "GET_INTRO_STREAM_LINK_START";
export const GET_INTRO_STREAM_LINK_SUCCESS = "GET_INTRO_STREAM_LINK_SUCCESS";
export const GET_INTRO_STREAM_LINK_FAILURE = "GET_INTRO_STREAM_LINK_FAILURE";

export const GET_PROVINCE_START = "GET_PROVINCE_START";
export const GET_PROVINCE_SUCCESS = "GET_PROVINCE_SUCCESS";
export const GET_PROVINCE_FAILURE = "GET_PROVINCE_FAILURE";

export const GET_SCHOOLS_BY_CITY_START = "GET_SCHOOLS_BY_CITY_START";
export const GET_SCHOOLS_BY_CITY_SUCCESS = "GET_SCHOOLS_BY_CITY_SUCCESS";
export const GET_SCHOOLS_BY_CITY_FAILURE = "GET_SCHOOLS_BY_CITY_FAILURE";

export const HOME_MESSAGE_HANDLER = "HOME_MESSAGE_HANDLER";

/** ACCOUNTS */
export const GET_USER_ACCOUNT_START = "GET_USER_ACCOUNT_START";
export const GET_USER_ACCOUNT_SUCCESS = "GET_USER_ACCOUNT_SUCCESS";
export const GET_USER_ACCOUNT_FAILURE = "GET_USER_ACCOUNT_FAILURE";
export const ADD_USER_ACCOUNT_START = "ADD_USER_ACCOUNT_START";
export const ADD_USER_ACCOUNT_SUCCESS = "ADD_USER_ACCOUNT_SUCCESS";
export const ADD_USER_ACCOUNT_FAILURE = "ADD_USER_ACCOUNT_FAILURE";
export const UPDATE_USER_ACCOUNT_START = "UPDATE_USER_ACCOUNT_START";
export const UPDATE_USER_ACCOUNT_SUCCESS = "UPDATE_USER_ACCOUNT_SUCCESS";
export const UPDATE_USER_ACCOUNT_FAILURE = "UPDATE_USER_ACCOUNT_FAILURE";
export const DELETE_USER_ACCOUNT_START = "DELETE_USER_ACCOUNT_START";
export const DELETE_USER_ACCOUNT_SUCCESS = "DELETE_USER_ACCOUNT_SUCCESS";
export const DELETE_USER_ACCOUNT_FAILURE = "DELETE_USER_ACCOUNT_FAILURE";
export const UPDATE_PASSWORD_START = "UPDATE_PASSWORD_START";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILURE = "UPDATE_PASSWORD_FAILURE";

export const ACCOUNT_MESSAGE_HANDLER = "ACCOUNT_MESSAGE_HANDLER";

export const CONFIRMATION_MODAL = "CONFIRMATION_MODAL";
export const HELP_HANDLER = "HELP_HANDLER";

