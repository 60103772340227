import { combineReducers } from "redux";
import { authReducer } from "./auth";
import { dashboardReducer } from "./dashboard";
import { homeReducer } from "./homeReducer";
import { accountReducer } from "./accountReducer";
import { confirmationReducer } from './confirmationReducer';
import { categoryReducer } from './categoryReducer';
export const rootReducer = combineReducers({
  authReducer, dashboardReducer, confirmationReducer,
  homeReducer, accountReducer,categoryReducer
});
