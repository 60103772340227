import React, { useEffect, useState } from 'react';

import './styles.js'

import { useStyles } from './styles.js';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import { Modal, Backdrop, CircularProgress } from '@material-ui/core';
import { APP_DOMAIN_PREFIX } from '../../environment';
import { thanksIcon } from '../../assets/images/img/index.js';
import Loader from '../loading/index.js';

function SubscriptionModal(props) {
  const {openModal, setOpen, tuneType, rowData, setAsJazzTuneLoading, setAsJazzTuneError, setAsJazzTuneSuccess, setAsJazzTuneData} = props
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [codeValue, setCodeValue] = useState('');
  const [timerValue, setTimerValue] = useState('0:00');
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [subscriptionModal, setSubscriptionModal] = useState(true);
  const [thankYouModal, setThankYouModal] = useState(false);
  const [subModal, setSubModal] = useState(true);
  const [modalImage, setModalImage] = useState('');

  useEffect(() => {
    setOpen(props.openModal)
  }, [props.openModal])

  useEffect(() => {
    if(setAsJazzTuneData){
      setSubscriptionModal(false);
      setThankYouModal(true);
      setTimeout(() => {
        setThankYouModal(false);
        setSubscriptionModal(true);
        props.setAsJazzTuneSuccessHandler();
        setOpen(false)
      }, 3000)
    }
  }, [setAsJazzTuneData])

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem('user'));
    if (userData)
      setUserData(userData);

    function handleClickOutside(event) {
      const clickedInsideDiv = event.target.closest('.modal-div');
      if (!clickedInsideDiv) {
        setOpen(false)

      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCloseLogin = () => {
    setOpen(false)
  }

  const setAsJazzTune = () => {
    if(userData && rowData){
      props.setAsJazzTune({
        user_id: userData.id, phone_number: userData.mobile, type: tuneType, app: 'WEB', content_id: rowData.old_content_id, platform: 'bajao'
      });
    }
  }
  return (
    <div>
      <div>
        <div>
        <Modal
            open={openModal}
            onClose={handleCloseLogin}
            BackdropComponent={Backdrop}
            className={classes.modal}
        >
            <div className='modal-div'>
            {
              subscriptionModal &&
              <div>
                <div className={`${''} position-relative modal-logo-main  new-sub-jazztune`}>
                    <div className='close-song-image'>
                      <div className='close-button-new-outer d-flex justify-content-center'>
                        <div className='close-button-new'>
                            <div onClick={handleCloseLogin} style={{ margin: '4px 11px' }}>
                              <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.690162 0.327551C0.542651 0.327589 0.409871 0.416999 0.354364 0.553667C0.298857 0.690336 0.331691 0.847009 0.437401 0.949891L3.98736 4.49985L0.437401 8.04982C0.342911 8.14078 0.304924 8.2757 0.338069 8.4026C0.371214 8.52951 0.470321 8.62861 0.597226 8.66176C0.724132 8.6949 0.859044 8.65692 0.950012 8.56243L4.49998 5.01247L8.04994 8.56243C8.14091 8.65692 8.27582 8.6949 8.40272 8.66176C8.52963 8.62861 8.62874 8.52951 8.66188 8.4026C8.69503 8.2757 8.65704 8.14078 8.56255 8.04982L5.01259 4.49985L8.56255 0.949891C8.66976 0.845676 8.702 0.68633 8.64373 0.548635C8.58545 0.41094 8.44862 0.323144 8.29917 0.327551C8.20499 0.330357 8.11561 0.369716 8.04995 0.437291L4.49999 3.98725L0.950027 0.437291C0.881778 0.367135 0.788063 0.327556 0.690187 0.327551H0.690162Z" fill="#0C0D34" />
                              </svg>
                            </div>
                        </div>
                      </div>
                      <div className='modal-logo'>
                        <img src={rowData?.thumbnail} className='modalImageNew' />
                      </div>
                      <div className='just-say-it'>
                        <div className='p1'>{rowData?.title}</div>
                        <div className='p2'>{rowData?.artist}</div>
                        <div className='p3'>RBT Code : {rowData?.old_content_id}</div>
                      </div>
                    </div>
                    <div className='texts-wrapper'>
                      {tuneType === 1 && <div className='cnfrm-txt'>Do You Want To Set This As Your Jazz Tune?</div>}
                      {tuneType === 2 && <div className='cnfrm-txt'>Do You Want To Set This As Your Apni Dhun?</div>}
                      {tuneType === 3 && <div className='cnfrm-txt'>Do You Want To Set This As Your Jazz Tune and Apni Dhun?</div>}
                      <div className='sub-btn-main'>
                      <div>
                        <button disabled={setAsJazzTuneLoading} className='d-flex sub-btn-yes' onClick={() => setAsJazzTune()}>Yes
                        { 
                          setAsJazzTuneLoading && 
                          <CircularProgress size={16} className='subscription-loading' /> 
                        }                        
                        </button>
                        </div>
                      <div><button className='sub-btn-no' onClick={() => setOpen(false)}>No</button></div>
                      </div>
                      <div><p className='charge-txt'>Subscription Charges: Rs. 3.01/day (incl. tax) Download Price: Rs. 11.53 (incl. tax)</p></div>
                      <div><p className='or-txt'>OR</p></div>
                      
                      <div className='dial-txt'>
                        Dial *2301*RBT Code# From Your Jazz Number
                      </div>
                      {
                        setAsJazzTuneData &&
                        <div className='send-otp-message'>
                          <p>{setAsJazzTuneData?.message}</p>
                        </div>
                      }
                      {
                        setAsJazzTuneError &&
                        <div className='send-otp-message'>
                          <p>{setAsJazzTuneError}</p>
                        </div>
                      }
                    </div>
                </div>
              </div>
            }

            {
              thankYouModal &&
              <div>
                <div className={`${classes.paperNew} position-relative modal-logo-main`}>
                    <div className='close-song-image'>
                      <div className='close-button-new-outer d-flex justify-content-center'>
                        <div className='close-button-new'>
                            <div onClick={handleCloseLogin} style={{ margin: '4px 11px' }}>
                              <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.690162 0.327551C0.542651 0.327589 0.409871 0.416999 0.354364 0.553667C0.298857 0.690336 0.331691 0.847009 0.437401 0.949891L3.98736 4.49985L0.437401 8.04982C0.342911 8.14078 0.304924 8.2757 0.338069 8.4026C0.371214 8.52951 0.470321 8.62861 0.597226 8.66176C0.724132 8.6949 0.859044 8.65692 0.950012 8.56243L4.49998 5.01247L8.04994 8.56243C8.14091 8.65692 8.27582 8.6949 8.40272 8.66176C8.52963 8.62861 8.62874 8.52951 8.66188 8.4026C8.69503 8.2757 8.65704 8.14078 8.56255 8.04982L5.01259 4.49985L8.56255 0.949891C8.66976 0.845676 8.702 0.68633 8.64373 0.548635C8.58545 0.41094 8.44862 0.323144 8.29917 0.327551C8.20499 0.330357 8.11561 0.369716 8.04995 0.437291L4.49999 3.98725L0.950027 0.437291C0.881778 0.367135 0.788063 0.327556 0.690187 0.327551H0.690162Z" fill="#0C0D34" />
                              </svg>
                            </div>
                        </div>
                      </div>
                      <div className='modal-logo'>
                        <img src={rowData?.thumbnail} className='modalImageNew' />
                      </div>
                      <div className='position-relative thanks-icon-subscribe'>
                        <img src={thanksIcon} className='modal-logo-img-2' />
                      </div>
                      <div className='just-say-it-on-thanks'>
                        <div className='p1'>{rowData?.title}</div>
                        <div className='p2'>{rowData?.artist}</div>
                        <div className='p3'>RBT Code : {rowData?.old_content_id}</div>
                      </div>
                    </div>
                    <div className='texts-wrapper'>
                      <div className='cnfrm-txt'>Subscription Request Received Successfully.</div>
                      <div><p className='conf-msg-txt'>You Will Receive A Confirmation SMS Shortly. </p></div>
                      <div><p className='unsubscribe'>To Unsubscribe</p></div>
                      
                      <div className='dial-txt'>
                        Send <span>unsub</span> to 230 From Your Phone
                      </div>
                    </div>
                </div>
              </div>
            }
            </div>
          </Modal>
        </div>
        </div>
    </div>
    
  );
}

const mapStateToProps = ({homeReducer}) => {
  const {setAsJazzTuneLoading,setAsJazzTuneError,setAsJazzTuneSuccess,setAsJazzTuneData} = homeReducer;
  return {setAsJazzTuneLoading,setAsJazzTuneError,setAsJazzTuneSuccess,setAsJazzTuneData};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAsJazzTune: (data) => dispatch(actions.setAsJazzTuneStart(data)),
    setAsJazzTuneSuccessHandler: () => dispatch(actions.setAsJazzTuneSuccessHandler()),
    homeMessageHandler: () => dispatch(actions.homeMessageHandler()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionModal);
