import {
  homeIcon,
  exploreIcon,
  accountsIcon
} from '../assets/images/images';
import { dashboardIcon } from '../assets/images/img';
import {
  MusicPoster, songPoster1, songPoster2, songPoster3, songPoster4,
  songPoster5, ring, Weekly, Top, pauseBtn, forwardBtn, backBtn, repeatBtn, Trend, Rectangle1, Rectangle2, ImagePlaceholder, ImagePlaceholder1, ImagePlaceholder2, ImagePlaceholder3, Qawali, movieBanner, Patrotric, Folk, classicImage, playBtn, banner1, banner2, banner3, artist1, artist2, artist3, artist4, artist5, playIcon, pauseIcon
} from '../assets/images/img/index.js';
import audio from '../audio/Tere Hawaale(PagalWorld.com.pe).mp3';
import audio1 from '../audio/Tum Kya Mile Rocky Aur Rani Kii Prem Kahaani 128 Kbps.mp3';
import ghazal from '../audio/Hoshwalon Ko Khabar Kya - Sarfarosh 128 Kbps.mp3';
import ghazal1 from '../audio/old_Dushman-Chithi Na Koi Sandesh.mp3';

let isLocal = false;
let frontendUrl = "";
let backendUrl = "";

if (isLocal) {
  frontendUrl = `http://localhost:3000`;
  backendUrl = `http://localhost:5500`;
}
else {
  frontendUrl = `https://jazz-tunes.com.pk`;
  backendUrl = `https://api.jazz-tunes.com.pk`;
}
export const FRONTEND_DOMAIN_PREFIX = frontendUrl;
export const APP_DOMAIN_PREFIX = backendUrl;

export const API_URL = `${APP_DOMAIN_PREFIX}/api/v1`;
export const FILE_BASE_URL = 'http://localhost:3004/';

export const TIME_DURATION = '00:15';

export const LABELS = {
  GO_BACK: '← Back',
  LOGOUT: '↶ Logout',
  LOGIN: 'Login',
  SIGNUP: 'Sign Up',
  REGISTER: 'Create User',
  EMAIL: 'Email Address',
  NAME: 'Username',
  FULL_NAME: 'Full Name',
  PASSWORD: 'Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  INVALID_MOBILE: 'Invalid mobile number'
};
export const PASSWORD = {
  passwordLength: 6,
  passwordLengthError: 'password is to short'
}
export const REGISTER = {
  SUCCESS_HEADER: "Success",
  SUCCESS_MESSAGE: "User Created Successfully!",
  FAILURE_HEADER: "Failure",
  FAILURE_MESSAGE: "Cannot Create User! User may already have been created with the given email!"
};
export const REGEXP_EMAIL = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2;3})+$/;
export const C_OTC_STORAGE = 'c_d_storage';


export const sidebarTabsList = [
  {
    text: '', key: 'home',
    icon:
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M0.878986 9.06874L8.46399 1.48274C9.40274 0.546733 10.6743 0.0211182 12 0.0211182C13.3256 0.0211182 14.5972 0.546733 15.536 1.48274L23.121 9.06874C23.4006 9.34653 23.6222 9.67704 23.7731 10.0411C23.924 10.4052 24.0011 10.7956 24 11.1897V21.0067C24 21.8024 23.6839 22.5655 23.1213 23.1281C22.5587 23.6907 21.7956 24.0067 21 24.0067H2.99999C2.20434 24.0067 1.44127 23.6907 0.878666 23.1281C0.316055 22.5655 -1.52588e-05 21.8024 -1.52588e-05 21.0067V11.1897C-0.00113678 10.7956 0.0759811 10.4052 0.22687 10.0411C0.377756 9.67704 0.599411 9.34653 0.878986 9.06874ZM8.99999 22.0067H15V18.0727C15 17.2771 14.6839 16.514 14.1213 15.9514C13.5587 15.3888 12.7956 15.0727 12 15.0727C11.2043 15.0727 10.4413 15.3888 9.87866 15.9514C9.31606 16.514 8.99999 17.2771 8.99999 18.0727V22.0067ZM1.99999 21.0067C1.99999 21.272 2.10534 21.5263 2.29288 21.7139C2.48042 21.9014 2.73477 22.0067 2.99999 22.0067H6.99999V18.0727C6.99999 16.7467 7.52677 15.4749 8.46445 14.5372C9.40213 13.5995 10.6739 13.0727 12 13.0727C13.3261 13.0727 14.5978 13.5995 15.5355 14.5372C16.4732 15.4749 17 16.7467 17 18.0727V22.0067H21C21.2652 22.0067 21.5196 21.9014 21.7071 21.7139C21.8946 21.5263 22 21.272 22 21.0067V11.1897C21.9991 10.9247 21.8938 10.6707 21.707 10.4827L14.122 2.89974C13.5583 2.33867 12.7953 2.02369 12 2.02369C11.2047 2.02369 10.4417 2.33867 9.87799 2.89974L2.29299 10.4857C2.1069 10.673 2.0017 10.9258 1.99999 11.1897V21.0067Z" fill="#404B63" />
      </svg>
  },
  {
    text: '', key: 'categories',
    icon:
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.24 2H5.34C3.15 2 2 3.15 2 5.33V7.23C2 9.41 3.15 10.56 5.33 10.56H7.23C9.41 10.56 10.56 9.41 10.56 7.23V5.33C10.57 3.15 9.42 2 7.24 2Z" fill="#4F524F" />
        <path d="M18.6695 2H16.7695C14.5895 2 13.4395 3.15 13.4395 5.33V7.23C13.4395 9.41 14.5895 10.56 16.7695 10.56H18.6695C20.8495 10.56 21.9995 9.41 21.9995 7.23V5.33C21.9995 3.15 20.8495 2 18.6695 2Z" fill="#4F524F" />
        <path d="M18.6695 13.4297H16.7695C14.5895 13.4297 13.4395 14.5797 13.4395 16.7597V18.6597C13.4395 20.8397 14.5895 21.9897 16.7695 21.9897H18.6695C20.8495 21.9897 21.9995 20.8397 21.9995 18.6597V16.7597C21.9995 14.5797 20.8495 13.4297 18.6695 13.4297Z" fill="#4F524F" />
        <path d="M7.24 13.4297H5.34C3.15 13.4297 2 14.5797 2 16.7597V18.6597C2 20.8497 3.15 21.9997 5.33 21.9997H7.23C9.41 21.9997 10.56 20.8497 10.56 18.6697V16.7697C10.57 14.5797 9.42 13.4297 7.24 13.4297Z" fill="#4F524F" />
      </svg>

  },
  {
    text: '', key: 'search-result',
    icon:
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z" fill="#4F524F" />
        <path d="M22.0004 22.75C21.8104 22.75 21.6204 22.68 21.4704 22.53L19.4704 20.53C19.1804 20.24 19.1804 19.76 19.4704 19.47C19.7604 19.18 20.2404 19.18 20.5304 19.47L22.5304 21.47C22.8204 21.76 22.8204 22.24 22.5304 22.53C22.3804 22.68 22.1904 22.75 22.0004 22.75Z" fill="#4F524F" />
      </svg>

  },

];

export const customIcons = {
  play: <img src={playIcon} alt="Pause" />,
  next: <svg xmlns="pauseIcon://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
    <path opacity="0.97" fill-rule="evenodd" clip-rule="evenodd" d="M6.61963 4.63982C7.36302 4.56507 8.06615 4.69788 8.729 5.03826C10.1898 5.87019 11.6429 6.71394 13.0884 7.5695C13.4142 7.97399 13.3986 8.3646 13.0415 8.74138C12.8136 8.89251 12.5636 8.93938 12.2915 8.88201C10.9245 8.1126 9.56511 7.33133 8.21338 6.53825C7.32491 6.00196 6.5046 6.08793 5.75244 6.79607C5.482 7.13854 5.34137 7.52919 5.33057 7.96794C5.29932 11.1554 5.29932 14.3429 5.33057 17.5304C5.41852 18.4779 5.92635 19.0639 6.85401 19.2883C7.19526 19.3368 7.52338 19.2899 7.83838 19.1476C10.7134 17.4914 13.5884 15.8351 16.4634 14.1789C17.2069 13.5321 17.3709 12.7586 16.9556 11.8586C16.7049 11.5298 16.4081 11.2486 16.0649 11.0148C15.7507 10.3308 15.9616 9.90894 16.6978 9.74919C17.564 10.045 18.1656 10.6231 18.5024 11.4836C19.0369 13.1048 18.6384 14.4407 17.3071 15.4914C14.5259 17.1007 11.7446 18.7101 8.96338 20.3195C7.44257 21.1557 6.02854 21.0073 4.72119 19.8742C4.25051 19.3703 3.95363 18.7766 3.83057 18.0929C3.78439 14.4507 3.80001 10.81 3.87744 7.17107C4.27686 5.76365 5.19092 4.91989 6.61963 4.63982Z" fill="white" />
    <path opacity="0.965" fill-rule="evenodd" clip-rule="evenodd" d="M20.7764 5.81175C21.2594 5.71397 21.5954 5.88586 21.7842 6.32737C21.8155 9.21801 21.8155 12.1086 21.7842 14.9992C21.4949 15.5435 21.0808 15.6607 20.542 15.3508C20.414 15.2277 20.3281 15.0793 20.2842 14.9055C20.2529 12.0774 20.2529 9.24923 20.2842 6.42112C20.3561 6.13879 20.5202 5.93569 20.7764 5.81175Z" fill="white" />
    <path opacity="0.948" fill-rule="evenodd" clip-rule="evenodd" d="M20.8702 16.9679C21.2941 16.9315 21.5988 17.1033 21.7842 17.4835C21.8155 18.046 21.8155 18.6085 21.7842 19.171C21.5267 19.6944 21.1283 19.8272 20.5889 19.5695C20.4339 19.4393 20.3323 19.2752 20.2842 19.0773C20.2415 18.5277 20.2571 17.9808 20.3311 17.4367C20.4514 17.2091 20.6311 17.0528 20.8702 16.9679Z" fill="white" />
  </svg>,
  previous: <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
    <path opacity="0.97" fill-rule="evenodd" clip-rule="evenodd" d="M18.3052 4.63982C17.5618 4.56507 16.8587 4.69788 16.1958 5.03826C14.735 5.87019 13.2819 6.71394 11.8364 7.5695C11.5106 7.97399 11.5263 8.3646 11.8833 8.74138C12.1112 8.89251 12.3612 8.93938 12.6333 8.88201C14.0003 8.1126 15.3597 7.33133 16.7114 6.53825C17.5999 6.00196 18.4202 6.08793 19.1724 6.79607C19.4428 7.13854 19.5834 7.52919 19.5942 7.96794C19.6255 11.1554 19.6255 14.3429 19.5942 17.5304C19.5063 18.4779 18.9985 19.0639 18.0708 19.2883C17.7295 19.3368 17.4014 19.2899 17.0864 19.1476C14.2114 17.4914 11.3364 15.8351 8.46142 14.1789C7.71794 13.5321 7.55388 12.7586 7.96924 11.8586C8.21988 11.5298 8.51674 11.2486 8.85986 11.0148C9.17411 10.3308 8.96317 9.90894 8.22705 9.74919C7.3608 10.045 6.75925 10.6231 6.42236 11.4836C5.88794 13.1048 6.28638 14.4407 7.61767 15.4914C10.3989 17.1007 13.1802 18.7101 15.9614 20.3195C17.4822 21.1557 18.8963 21.0073 20.2036 19.8742C20.6743 19.3703 20.9712 18.7766 21.0942 18.0929C21.1404 14.4507 21.1248 10.81 21.0474 7.17107C20.6479 5.76365 19.7339 4.91989 18.3052 4.63982Z" fill="white" />
    <path opacity="0.965" fill-rule="evenodd" clip-rule="evenodd" d="M4.14843 5.81175C3.66538 5.71397 3.32943 5.88586 3.14061 6.32737C3.10935 9.21801 3.10935 12.1086 3.14061 14.9992C3.42993 15.5435 3.84397 15.6607 4.3828 15.3508C4.51082 15.2277 4.59674 15.0793 4.64061 14.9055C4.67188 12.0774 4.67188 9.24923 4.64061 6.42112C4.56871 6.13879 4.40464 5.93569 4.14843 5.81175Z" fill="white" />
    <path opacity="0.948" fill-rule="evenodd" clip-rule="evenodd" d="M4.05464 16.9679C3.63066 16.9315 3.32597 17.1033 3.14058 17.4835C3.10931 18.046 3.10931 18.6085 3.14058 19.171C3.39811 19.6944 3.79655 19.8272 4.33589 19.5695C4.49091 19.4393 4.59248 19.2752 4.64058 19.0773C4.68333 18.5277 4.66772 17.9808 4.5937 17.4367C4.47342 17.2091 4.29375 17.0528 4.05464 16.9679Z" fill="white" />
  </svg>,
  pause: <img src={pauseIcon} alt="Play" />,
  // loop: <img src={repeatBtn} alt="Repeat" />,
  // volume: <img src={VolumeIcon} alt="Volume" />,
};

export const customIconsMobile = {
  play: <img src={playIcon} alt="Pause" />,
  pause: <img src={pauseIcon} alt="Play" />,

}




// export const allSongsData = [
//   {
//     type: "Top 25",
//     data: [
//       { image: MusicPoster, songName: 'Hello GoodBye', singerName: 'Arbaaz Khan', tune: 'Set As Jazz Tune' },
//       { image: songPoster1, songName: 'Shava', singerName: 'Nindy Kaur', tune: 'Set As Jazz Tune' },
//       { image: songPoster2, songName: 'Hello GoodBye', singerName: 'Arbaaz Khan', tune: 'Set As Jazz Tune' },
//       { image: songPoster3, songName: 'Dil Ronda Aye', singerName: 'Nabeel Shaukat Ali', tune: 'Set As Jazz Tune' },
//       { image: songPoster4, songName: 'Billo Rani', singerName: 'Falak Shabbir', tune: 'Set As Jazz Tune' },
//       { image: songPoster5, songName: 'Mitti Da Khadona', singerName: 'Bilal Saeed', tune: 'Set As Jazz Tune' },
//       { image: MusicPoster, songName: 'Hello GoodBye', singerName: 'Arbaaz Khan', tune: 'Set As Jazz Tune' },
//       { image: songPoster1, songName: 'Shava', singerName: 'Nindy Kaur', tune: 'Set As Jazz Tune' },
//       { image: songPoster2, songName: 'Hello GoodBye', singerName: 'Arbaaz Khan', tune: 'Set As Jazz Tune' },
//       { image: songPoster3, songName: 'Dil Ronda Aye', singerName: 'Nabeel Shaukat Ali', tune: 'Set As Jazz Tune' },
//       { image: songPoster4, songName: 'Billo Rani', singerName: 'Falak Shabbir', tune: 'Set As Jazz Tune' },
//       { image: songPoster5, songName: 'Mitti Da Khadona', singerName: 'Bilal Saeed', tune: 'Set As Jazz Tune' },
//     ],
//   },
//   {
//     type: "Latest Jazz Tunes",
//     data: [
//       { image: MusicPoster, songName: 'Hello GoodBye', singerName: 'Arbaaz Khan', tune: 'Set As Jazz Tune' },
//       { image: songPoster1, songName: 'Shava', singerName: 'Nindy Kaur', tune: 'Set As Jazz Tune' },
//       { image: songPoster2, songName: 'Hello GoodBye', singerName: 'Arbaaz Khan', tune: 'Set As Jazz Tune' },
//       { image: songPoster3, songName: 'Dil Ronda Aye', singerName: 'Nabeel Shaukat Ali', tune: 'Set As Jazz Tune' },
//       { image: songPoster4, songName: 'Billo Rani', singerName: 'Falak Shabbir', tune: 'Set As Jazz Tune' },
//       { image: songPoster5, songName: 'Mitti Da Khadona', singerName: 'Bilal Saeed', tune: 'Set As Jazz Tune' },
//       { image: MusicPoster, songName: 'Hello GoodBye', singerName: 'Arbaaz Khan', tune: 'Set As Jazz Tune' },
//       { image: songPoster1, songName: 'Shava', singerName: 'Nindy Kaur', tune: 'Set As Jazz Tune' },
//       { image: songPoster2, songName: 'Hello GoodBye', singerName: 'Arbaaz Khan', tune: 'Set As Jazz Tune' },
//       { image: songPoster3, songName: 'Dil Ronda Aye', singerName: 'Nabeel Shaukat Ali', tune: 'Set As Jazz Tune' },
//       { image: songPoster4, songName: 'Billo Rani', singerName: 'Falak Shabbir', tune: 'Set As Jazz Tune' },
//       { image: songPoster5, songName: 'Mitti Da Khadona', singerName: 'Bilal Saeed', tune: 'Set As Jazz Tune' },
//     ],
//   },
// ];

export const playList = [
  {
    type: "Recommended",
    data: [
      {
        image: MusicPoster,
        songName: 'Hello GoodBye',
        singerName: 'Arbaaz Khan',
        tune: 'Set As Jazz Tune',
        tracks: [
          {
            icon: Trend,
            title: 'Tera Hawale',
            artist: 'Arjit Singh',
            time: "02:04",
            src: audio,
            videoLink: "https://bajao.pk/video/7927668/Des-Mere-Sevak--The-Confessions--Bajao-Original-"
          },
          { icon: Rectangle1, title: 'Tum Kya mile', artist: 'Atif Aslam', time: "02:04", src: audio1 },
        ],
      },
      {
        image: songPoster1,
        title: 'Shava',
        songName: 'Shava',
        singerName: 'Nindy Kaur',
        tune: 'Set As Jazz Tune',
        tracks: [
          {
            icon: Rectangle2, title: 'Tera Hawale', artist: 'Arjit Singh', src: audio
          },
          { icon: Rectangle1, title: 'Tum Kya mile', artist: 'Atif Aslam', src: audio1 },
          { icon: Rectangle2, title: 'Tera Hawale', artist: 'Arjit Singh', src: audio },
        ],
      },
      {
        image: songPoster2,
        title: 'Hello GoodBye',
        songName: 'Hello GoodBye',
        singerName: 'Arbaaz Khan',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Rectangle2, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Rectangle1, title: 'Jhoom', artist: 'Jhoom', time: "02:04", src: audio },
          { icon: Trend, title: 'HUSN', artist: 'HUSN', time: "02:04", src: audio1 },
          { icon: Rectangle2, title: 'Dil Karey', artist: 'Dil Karey', time: "02:04", src: audio },
          { icon: Rectangle1, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
        ],
      },
      {
        image: songPoster2,
        title: 'Dil Ronda Aye',
        artist: 'Nabeel Shaukat Ali',
        songName: 'Dil Ronda Aye',
        singerName: 'Nabeel Shaukat Ali',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Rectangle1, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Rectangle2, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Rectangle1, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: songPoster1,
        title: 'Billo Rani',
        songName: 'Billo Rani',
        singerName: 'Falak Shabbir',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: songPoster2,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: MusicPoster,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: MusicPoster,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: songPoster1,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: songPoster2,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: MusicPoster,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: MusicPoster,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: songPoster1,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: songPoster2,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: MusicPoster,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
    ],
  },
];


export const allSongsData = [
  {
    type: "Recommended",
    data: [
      {
        image: MusicPoster,
        songName: 'Hello GoodBye',
        singerName: 'Arbaaz Khan',
        tune: 'Set As Jazz Tune',
        tracks: [
          {
            icon: Trend,
            title: 'Tera Hawale',
            artist: 'Arjit Singh',
            time: "02:04",
            src: audio,
            videoLink: "https://bajao.pk/video/7927668/Des-Mere-Sevak--The-Confessions--Bajao-Original-"
          },
          { icon: Rectangle1, title: 'Tum Kya mile', artist: 'Atif Aslam', time: "02:04", src: audio1 },
        ],
      },
      {
        image: songPoster1,
        title: 'Shava',
        songName: 'Shava',
        singerName: 'Nindy Kaur',
        tune: 'Set As Jazz Tune',
        tracks: [
          {
            icon: Rectangle2, title: 'Tera Hawale', artist: 'Arjit Singh', src: audio
          },
          { icon: Rectangle1, title: 'Tum Kya mile', artist: 'Atif Aslam', src: audio1 },
          { icon: Rectangle2, title: 'Tera Hawale', artist: 'Arjit Singh', src: audio },
        ],
      },
      {
        image: songPoster2,
        title: 'Hello GoodBye',
        songName: 'Hello GoodBye',
        singerName: 'Arbaaz Khan',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Rectangle2, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Rectangle1, title: 'Jhoom', artist: 'Jhoom', time: "02:04", src: audio },
          { icon: Trend, title: 'HUSN', artist: 'HUSN', time: "02:04", src: audio1 },
          { icon: Rectangle2, title: 'Dil Karey', artist: 'Dil Karey', time: "02:04", src: audio },
          { icon: Rectangle1, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
        ],
      },
      {
        image: songPoster2,
        title: 'Dil Ronda Aye',
        artist: 'Nabeel Shaukat Ali',
        songName: 'Dil Ronda Aye',
        singerName: 'Nabeel Shaukat Ali',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Rectangle1, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Rectangle2, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Rectangle1, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: songPoster1,
        title: 'Billo Rani',
        songName: 'Billo Rani',
        singerName: 'Falak Shabbir',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: songPoster2,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: MusicPoster,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: MusicPoster,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: songPoster1,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: songPoster2,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: MusicPoster,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: MusicPoster,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: songPoster1,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: songPoster2,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: MusicPoster,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
    ],
  },
  // {
  //   type: "Latest Jazz Tunes",
  //   data: [
  //     {
  //       image: MusicPoster,
  //       songName: 'Hello GoodBye',
  //       singerName: 'Arbaaz Khan',
  //       tune: 'Set As Jazz Tune',
  //       tracks: [
  //         {
  //           icon: Trend,
  //           title: 'Tera Hawale',
  //           artist: 'Arjit Singh',
  //           time: "02:04", src: audio1,
  //           videoLink: "https://bajao.pk/video/7927668/Des-Mere-Sevak--The-Confessions--Bajao-Original-"
  //         },
  //         { icon: Rectangle1, title: 'Tum Kya mile', artist: 'Atif Aslam', time: "02:04", src: audio },
  //       ],
  //     },
  //     {
  //       image: songPoster2,
  //       title: 'Shava',
  //       songName: 'Shava',
  //       singerName: 'Nindy Kaur',
  //       tune: 'Set As Jazz Tune',
  //       tracks: [
  //         {
  //           icon: Rectangle2, title: 'Tera Hawale', artist: 'Arjit Singh', src: audio
  //         },
  //         { icon: Rectangle1, title: 'Tum Kya mile', artist: 'Atif Aslam', src: audio1 },
  //         { icon: Rectangle2, title: 'Tera Hawale', artist: 'Arjit Singh', src: audio },
  //       ],
  //     },
  //     {
  //       image: songPoster1,
  //       title: 'Hello GoodBye',
  //       songName: 'Hello GoodBye',
  //       singerName: 'Arbaaz Khan',
  //       tune: 'Set As Jazz Tune',
  //       tracks: [
  //         { icon: Rectangle2, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
  //         { icon: Rectangle1, title: 'Jhoom', artist: 'Jhoom', time: "02:04", src: audio },
  //         { icon: Trend, title: 'HUSN', artist: 'HUSN', time: "02:04", src: audio1 },
  //         { icon: Rectangle2, title: 'Dil Karey', artist: 'Dil Karey', time: "02:04", src: audio },
  //         { icon: Rectangle1, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
  //       ],
  //     },
  //     {
  //       image: MusicPoster,
  //       title: 'Dil Ronda Aye',
  //       artist: 'Nabeel Shaukat Ali',
  //       songName: 'Dil Ronda Aye',
  //       singerName: 'Nabeel Shaukat Ali',
  //       tune: 'Set As Jazz Tune',
  //       tracks: [
  //         { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
  //         { icon: Rectangle1, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
  //         { icon: Rectangle2, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
  //         { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
  //         { icon: Rectangle1, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
  //         { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
  //       ],
  //     },
  //     {
  //       image: songPoster1,
  //       title: 'Billo Rani',
  //       songName: 'Billo Rani',
  //       singerName: 'Falak Shabbir',
  //       tune: 'Set As Jazz Tune',
  //       tracks: [
  //         { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
  //         { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
  //         { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
  //         { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
  //       ],
  //     },
  //     {
  //       image: songPoster2,
  //       title: 'Mitti Da Khadona',
  //       songName: 'Mitti Da Khadona',
  //       singerName: 'Bilal Saeed',
  //       tune: 'Set As Jazz Tune',
  //       tracks: [
  //         { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
  //         { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
  //         { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
  //         { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
  //       ],
  //     },
  //   ],
  // },
];

export const artist = [
  {
    type: "Artists",
    data: [
      {
        image: MusicPoster,
        songName: 'Hello GoodBye',
        singerName: 'Arbaaz Khan',
        tune: 'Set As Jazz Tune',
        tracks: [
          {
            icon: Trend,
            title: 'Tera Hawale',
            artist: 'Arjit Singh',
            time: "02:04",
            src: audio,
            videoLink: "https://bajao.pk/video/7927668/Des-Mere-Sevak--The-Confessions--Bajao-Original-"
          },
          { icon: Rectangle1, title: 'Tum Kya mile', artist: 'Atif Aslam', time: "02:04", src: audio1 },
        ],
      },
      {
        image: artist2,
        title: 'Shava',
        songName: 'Shava',
        singerName: 'Nindy Kaur',
        tune: 'Set As Jazz Tune',
        tracks: [
          {
            icon: Rectangle2, title: 'Tera Hawale', artist: 'Arjit Singh', src: audio
          },
          { icon: Rectangle1, title: 'Tum Kya mile', artist: 'Atif Aslam', src: audio1 },
          { icon: Rectangle2, title: 'Tera Hawale', artist: 'Arjit Singh', src: audio },
        ],
      },
      {
        image: artist3,
        title: 'Hello GoodBye',
        songName: 'Hello GoodBye',
        singerName: 'Arbaaz Khan',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Rectangle2, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Rectangle1, title: 'Jhoom', artist: 'Jhoom', time: "02:04", src: audio },
          { icon: Trend, title: 'HUSN', artist: 'HUSN', time: "02:04", src: audio1 },
          { icon: Rectangle2, title: 'Dil Karey', artist: 'Dil Karey', time: "02:04", src: audio },
          { icon: Rectangle1, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
        ],
      },
      {
        image: artist4,
        title: 'Dil Ronda Aye',
        artist: 'Nabeel Shaukat Ali',
        songName: 'Dil Ronda Aye',
        singerName: 'Nabeel Shaukat Ali',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Rectangle1, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Rectangle2, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Rectangle1, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: artist5,
        title: 'Billo Rani',
        songName: 'Billo Rani',
        singerName: 'Falak Shabbir',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: artist1,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: artist2,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: artist3,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: artist4,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: artist5,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: artist1,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: artist2,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: artist3,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: artist4,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: artist5,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
    ],
  },
];

export const veloSoundData = [
  {
    type: "Velo Sound Station",
    data: [
      {
        image: MusicPoster,
        songName: 'Hello GoodBye',
        singerName: 'Arbaaz Khan',
        tune: 'Set As Jazz Tune',
        tracks: [
          {
            icon: Trend,
            title: 'Tera Hawale',
            artist: 'Arjit Singh',
            time: "02:04",
            src: audio,
            videoLink: "https://bajao.pk/video/7927668/Des-Mere-Sevak--The-Confessions--Bajao-Original-"
          },
          { icon: Rectangle1, title: 'Tum Kya mile', artist: 'Atif Aslam', time: "02:04", src: audio1 },
        ],
      },
      {
        image: songPoster1,
        title: 'Shava',
        songName: 'Shava',
        singerName: 'Nindy Kaur',
        tune: 'Set As Jazz Tune',
        tracks: [
          {
            icon: Rectangle2, title: 'Tera Hawale', artist: 'Arjit Singh', src: audio
          },
          { icon: Rectangle1, title: 'Tum Kya mile', artist: 'Atif Aslam', src: audio1 },
          { icon: Rectangle2, title: 'Tera Hawale', artist: 'Arjit Singh', src: audio },
        ],
      },
      {
        image: songPoster2,
        title: 'Hello GoodBye',
        songName: 'Hello GoodBye',
        singerName: 'Arbaaz Khan',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Rectangle2, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Rectangle1, title: 'Jhoom', artist: 'Jhoom', time: "02:04", src: audio },
          { icon: Trend, title: 'HUSN', artist: 'HUSN', time: "02:04", src: audio1 },
          { icon: Rectangle2, title: 'Dil Karey', artist: 'Dil Karey', time: "02:04", src: audio },
          { icon: Rectangle1, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
        ],
      },
      {
        image: songPoster2,
        title: 'Dil Ronda Aye',
        artist: 'Nabeel Shaukat Ali',
        songName: 'Dil Ronda Aye',
        singerName: 'Nabeel Shaukat Ali',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Rectangle1, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Rectangle2, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Rectangle1, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: songPoster1,
        title: 'Billo Rani',
        songName: 'Billo Rani',
        singerName: 'Falak Shabbir',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: songPoster2,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: MusicPoster,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: MusicPoster,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: songPoster1,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: songPoster2,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: MusicPoster,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: MusicPoster,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: songPoster1,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: songPoster2,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
      {
        image: MusicPoster,
        title: 'Mitti Da Khadona',
        songName: 'Mitti Da Khadona',
        singerName: 'Bilal Saeed',
        tune: 'Set As Jazz Tune',
        tracks: [
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio1 },
          { icon: Trend, title: 'Sushi Woofer Paar De', artist: 'Sushi Woofer Paar De', time: "02:04", src: audio },
        ],
      },
    ],
  },
];


export const bannerImages = [
  { image: banner1 },
  { image: banner2 },
  { image: banner3 },
  { image: banner1 }
]

export const classicalSong = [
  {
    image: ImagePlaceholder,
    songName: 'Kahani',
    singerName: 'jagjit signh',
    tune: 'Set As Jazz Tune',
    type: 'Ghazals',
    albums: [
      {
        image: 'https://bajao.pk/image/pakistani/concert_jagjit_singh_chitra_singh_in_pakistan_1979_vol_3-1979/album_thumbs/concert_jagjit_singh_chitra_singh_in_pakistan_1979_vol_3_1650968933833_500x500.webp',
        artist: 'Jagjit Singh',
      },
    ],
    tracks: [
      {
        icon: Trend,
        title: 'Hoshwalon Ko Khabar Kya',
        artist: 'Jagjit Singh',
        src: ghazal,
      },
      {
        icon: Rectangle1,
        title: 'Chithi Na Koi Sandesh',
        artist: 'Jagjit Singh',
        src: ghazal1
      },
    ],
  },
  {
    image: ImagePlaceholder1,
    songName: 'Kahani',
    singerName: 'jagjit signh',
    tune: 'Set As Jazz Tune',
    type: 'Islamic',
    albums: [
      {
        image: 'https://bajao.pk/image/pakistani30222/zamaney_bhar_me-2022/album_thumbs/zamaney_bhar_me_500x500.jpg',
        artist: 'islamic',
      },

    ],
    tracks: [
      {
        icon: Trend,
        title: 'Hoshwalon Ko Khabar Kya',
        artist: 'jagjit',
        src: ghazal,
      },
      {
        icon: Rectangle1,
        title: 'Chithi Na Koi Sandesh',
        artist: 'jagjit',
        src: ghazal1
      },
    ],
  },
  {
    image: ImagePlaceholder2,
    songName: 'Kahani',
    singerName: 'jagjit signh',
    tune: 'Set As Jazz Tune',
    type: 'Pop',
    albums: [
      {
        image: 'https://bajao.pk/image/pakistani/barage_stargay_vol_3737-2015/fulltrack/Barage_stargay_Vol_3737_500x500.webp',
        artist: 'pop',
      },
      {
        image: 'https://bajao.pk/image/pakistani/you_need_love-2021/fulltrack/you_need_love_ft_xpolymer_dar_500x500.webp',
        artist: 'pop',
      },

    ],
    tracks: [
      {
        icon: Trend,
        title: 'Hoshwalon Ko Khabar Kya',
        artist: 'jagjit',
        src: ghazal,
      },
      {
        icon: Rectangle1,
        title: 'Chithi Na Koi Sandesh',
        artist: 'jagjit',
        src: ghazal1
      },
    ],
  },
  {
    image: ImagePlaceholder3,
    songName: 'Kahani',
    singerName: 'jagjit signh',
    tune: 'Set As Jazz Tune',
    type: 'Romantic',
    albums: [
      {
        image: 'https://bajao.pk/image/pakistani/kale_ghory_waly_album_30-2015/fulltrack/Kale_Ghory_Waly_Album_30_500x500.webp',
        artist: 'Romantic',
      },
      {
        image: 'https://bajao.pk/image/pakistani/dil_badshah_album_162-2015/fulltrack/Dil_Badshah_Album_162_500x500.webp',
        artist: 'Romantic',
      },

    ],
    tracks: [
      {
        icon: Trend,
        title: 'Hoshwalon Ko Khabar Kya',
        artist: 'jagjit',
        src: ghazal,
      },
      {
        icon: Rectangle1,
        title: 'Chithi Na Koi Sandesh',
        artist: 'jagjit',
        src: ghazal1
      },
    ],
  },
  {
    image: classicImage,
    songName: 'Kahani',
    singerName: 'jagjit signh',
    tune: 'Set As Jazz Tune',
    type: 'Classic',
    albums: [
      {
        image: 'https://bajao.pk/image/pakistani/rabba_mere_hall_da_meharram_tu_album_27-2007/fulltrack/Rabba_Mere_Hall_Da_Meharram_Tu_Album_27_500x500.webp',
        artist: 'Classic',
      },

    ],
    tracks: [
      {
        icon: Trend,
        title: 'Hoshwalon Ko Khabar Kya',
        artist: 'jagjit',
        src: ghazal,
      },
      {
        icon: Rectangle1,
        title: 'Chithi Na Koi Sandesh',
        artist: 'jagjit',
        src: ghazal1
      },
    ],
  },
  {
    image: Folk,
    songName: 'Kahani',
    singerName: 'jagjit signh',
    tune: 'Set As Jazz Tune',
    type: 'Folk',
    albums: [
      {
        image: 'https://bajao.pk/image/pakistani/bandhan-1980/fulltrack/yeh_mausam_hota_hai_1650870186869_500x500.webp',
        artist: 'Folk',
      },
    ],
    tracks: [
      {
        icon: Trend,
        title: 'Hoshwalon Ko Khabar Kya',
        artist: 'jagjit',
        src: ghazal,
      },
      {
        icon: Rectangle1,
        title: 'Chithi Na Koi Sandesh',
        artist: 'jagjit',
        src: ghazal1
      },
    ],
  },
  {
    image: Patrotric,
    songName: 'Kahani',
    singerName: 'jagjit signh',
    tune: 'Set As Jazz Tune',
    type: 'Patriotic',
    tracks: [
      {
        icon: Trend,
        title: 'Hoshwalon Ko Khabar Kya',
        artist: 'jagjit',
        src: ghazal,
      },
      {
        icon: Rectangle1,
        title: 'Chithi Na Koi Sandesh',
        artist: 'jagjit',
        src: ghazal1
      },
    ],
  },
  {
    image: movieBanner,
    songName: 'Kahani',
    singerName: 'jagjit signh',
    tune: 'Set As Jazz Tune',
    type: 'Movie Songs',
    tracks: [
      {
        icon: Trend,
        title: 'Hoshwalon Ko Khabar Kya',
        artist: 'jagjit',
        src: ghazal,
      },
      {
        icon: Rectangle1,
        title: 'Chithi Na Koi Sandesh',
        artist: 'jagjit',
        src: ghazal1
      },
    ],
  },
  {
    image: Qawali,
    songName: 'Kahani',
    singerName: 'jagjit signh',
    tune: 'Set As Jazz Tune',
    type: 'Qawwali',
    tracks: [
      {
        icon: Trend,
        title: 'Hoshwalon Ko Khabar Kya',
        artist: 'jagjit',
        src: ghazal,
      },
      {
        icon: Rectangle1,
        title: 'Chithi Na Koi Sandesh',
        artist: 'jagjit',
        src: ghazal1
      },
    ],
  },
]

export const data = [
  { img: Top, cate: 'New Releases' },
  { img: Weekly, cate: 'Top Weekly' },
]

export const formatDuration = (duration) => { /** Convert total duration of video in given format */
  let min = Math.floor(duration / 60);
  let sec = duration % 60;
  min = min < 10 ? "0" + min : min;
  sec = sec < 10 ? "0" + sec : sec;
  return min + ':' + sec
}
export const formatProgressDuration = (watchDur, totalDur) => {
  return (watchDur / totalDur) * 100;
}

export const userRoles = [
  { text: 'Parent', value: "63314f7edd5ccc5c401ab036" },
  { text: 'Children', value: "638d9b73ba5b9833dc8cbcd0" },
];

export const userStatus = [
  { text: 'Active', value: '1' },
  { text: 'In-Active', value: '0' },
]

export const yesNo = [
  { text: 'Yes', value: 1 },
  { text: 'No', value: 0 },
]




export const FileOption = [
  { text: 'Embeded', value: '0' },
  { text: 'Download', value: '1' },
]

export function getFormattedDate(date) {
  const date1 = new Date(date);                 // {object Date}
  const monthNames = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  let year = date1.getFullYear();
  let month = monthNames[date1.getMonth()];
  let day = date1.getDate().toString().padStart(2, '0');
  return year + '-' + month + '-' + day;
}

export function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  const formattedTime = `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  return formattedTime;
};
export const getAudioDuration = async (url) => {
  return new Promise((resolve) => {
    const audio = new Audio(url);
    audio.addEventListener('loadedmetadata', () => {
      resolve(audio.duration);
    });
  });
};

export const statusFailureCase = [3, 6, 8, 9, 11]
export const statusFailureCase1 = [5, 6, 7, 8, 9, 10, 11]

export const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

export const monthNamesShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

export const mobileNumberRegex = /^[\+]?[(]?[0-9]{2}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{5,}$/im

