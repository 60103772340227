import HttpService from "./http.service";
class AuthService extends HttpService {
    login = (data) => this.post("auth/login", data);
    signUp = (data) => this.post("auth/register", data);
    sendOtp = (data) => this.post("auth/sendOtp",data);
    verifyOtp = (data) => this.post("auth/verifyPin",data);
    confirmDetail = (data) => this.put("user/update-profile",data);
    preAuth = (data) => this.post("auth/pre-auth");
    refreshToken = (config) => this.post("auth/refresh", undefined, config);
    forgetPassword = (config) => this.post("auth/forgot-password", undefined, config);
    checkUser = (config) => this.geth("auth/check-user", config);
}

export default new AuthService();
