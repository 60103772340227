import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    phoneTxtInput: {
        borderRadius: '20px !important',
        opacity: 0.75,
        background: '#171723',
        color: 'white',
        height: '49px',
    },
    inputFocused: {
        "& .MuiOutlinedInput-notchedOutline":
        {
            border: "1px solid #92263B !important",
        },
    },
 

}));
