import React, { useState, useEffect } from 'react'
import '../../assets/scss/signInBajao.scss';
import { Grid, Button, TextField, InputAdornment, Link, Typography } from '@material-ui/core';
import {SignInBanner, bajaoLogin,
  createAccountImage, verificationImage, cross, ufone, telenor, jazz, zong,
} from '../../assets/images/img/index';
import { useStyles } from './styles';
import OTPInput from "react-otp-input";
import SignComponent from './SignComponent';
import CreateAccount from './createAccount';

const icon = [jazz, telenor, zong, ufone]

function SignIn(props) {
  const classes = useStyles();
  const [OTP, setOTP] = useState("");
  const [remainingTime, setRemainingTime] = useState(60);
  const [Payment, setPayment] = useState(0);
  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState({
    phone: '12345'
  })
  const [errors, setErrors] = useState({
    phone: ''
  })



  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setErrors({
      ...errors,
      [prop]: ''
    });
  }



  const handleLogin = () => {
    setLoading(true)
    const hardcodedPhone = '12345';
    if (values.phone && values.phone === hardcodedPhone) {
      setTimeout(() => {
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem("user", hardcodedPhone)
        props.history.push('/')
        setLoading(false);
      }, 2000);
    } else {
      props.history.push('/verification')

    }
  }


  const selectPaymentMethod = (index) => {
    setPayment(index)
  }
  useEffect(() => {
    let timer;

    const updateTimer = () => {
      if (remainingTime > 0) {
        setRemainingTime((prevTime) => prevTime - 1);
      }
    };

    timer = setInterval(updateTimer, 1000);

    return () => clearInterval(timer);
  }, [remainingTime]);

  const resendOTP = () => {
    setRemainingTime(60);
    console.log("resend");
  };

  const renderImage = () => {
    switch (props.location.pathname) {
      case '/signIn':
        return SignInBanner;
      case '/create-account':
        return createAccountImage;
      case '/verification':
        return verificationImage;
      default:
        return SignInBanner;
    }
  };

  const bannerStyle = {
    backgroundImage: `url(${renderImage()})`,
    width: '100%',
    height: '100%',
    display: 'flex',
    borderRadius: '40px'
  };

  const modalCloseLogin = (data) => {
    console.log(data);
    if (data === 'create-account') {
      props.history.push({ pathname: `/signIn` })
    } else if (data == 'verification') {
      props.history.push({ pathname: `/create-account` })
    }
    else {
      props.history.push({ pathname: `/` });
    }

  }

  return (
    <div className='login-body'>
      <Grid container className='grid-login-ev'>
        <Grid xs={12} sm={12} md={6}>
          <div style={bannerStyle}>
          </div>
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <div className='login-modal-outer'>
            <div className='logo-login'>
              <img src={bajaoLogin} />
            </div>

            {/**signIn screen */}
            {props.location.pathname === '/signIn' && (
              <SignComponent
              modalCloseLogin={modalCloseLogin}
              />
            )}

            {/**create account screen */}
            {props.location.pathname == '/create-account' && (
              <CreateAccount
                modalCloseLogin={modalCloseLogin}
                icon={icon} 
                values={values}
                handleLogin={handleLogin}
                handleChange={handleChange}
                loading={loading}
                selectPaymentMethod={selectPaymentMethod}
                Payment={Payment}
              />
            )}

            {/** verification screen */}
            {props.location.pathname == '/verification' && (
              <div className='login-modal-bajao'>
                <div className='bajao-cross-btn-login'>
                  <img src={cross} onClick={() => modalCloseLogin('verification')} />
                </div>
                <h2>Mobile Number<br />Verification </h2>
                <Typography className='verify-digit-text'>Enter 4-Digit Pin Sent to Your Number<br /></Typography>
                <div className='otp-form'>
                  <OTPInput containerStyle="otp-container-bajao" inputStyle="otp-field-bajao" focusStyle="otp-field-focused-bajao" value={OTP} onChange={setOTP} shouldAutoFocus isInputNum placeholder='' />
                  {remainingTime == 0 ?
                    <Link className='resend-link-button cursor-pointer' onClick={() => resendOTP(true)} >Resend</Link>
                    :
                    <div className='resend-text'>Re-send  in {Math.floor(remainingTime / 60).toString().padStart(2, '0')}:{(remainingTime % 60).toString().padStart(2, '0')}</div>
                  }
                </div>
                <button className='verify-btn' onClick={''}>Verify</button>
              </div>
            )}
          </div>


        </Grid>
      </Grid>
    </div>
  )
}

export default SignIn