import * as actionTypes from "../actionTypes";
import {setUserDataInLocalStorage} from "../../services/utils";

export const loginStart = (data) => {
  return {
    type: actionTypes.LOGIN_START,
    payload: data,
  };
};
 

export const loginSuccess = (data) => {
  // console.log('loginSuccess auth.js ',data.data);
  // localStorage.setItem('token', data?.accessToken);
  setUserDataInLocalStorage(data?.data?.user);
  let d = {user: null, message: 'Successfully loggedIn '}
  return {
    type: actionTypes.LOGIN_SUCCESS,
    payload: d,
  };
};

export const isLoggedIn = () => {
  // console.log('isLoggedIn auth.js ');
  let d = {user: null, message: 'Successfully loggedIn '}
  return {
    type: actionTypes.LOGIN_SUCCESS,
    payload: d,
  };
};

export const loginFailure = (error) => {
  return {
    type: actionTypes.LOGIN_FAILURE,
    payload: error,
  };
};

export const signupStart = (data) => {
  return {
    type: actionTypes.SIGNUP_START,
    payload: data,
  };
};

export const signupSuccess = (data) => {
  return {
    type: actionTypes.SIGNUP_SUCCESS,
    payload: data,
  };
};
export const signupFailure = (error) => {
  return {
    type: actionTypes.SIGNUP_FAILURE,
    payload: error,
  };
};
export const logout = () => {
  // console.log('In logout auth.js ==== ');
  return {
    type: actionTypes.LOGOUT,
    payload: null,
  };
};

export const sendOtp = (data) => {
  return {
      type: actionTypes.SEND_OTP_START,
      payload: data,
  };
};
export const sendOtpSuccess = (data) => {
  //console.log('sendOtpSuccess ',data.data);
  return {
      type: actionTypes.SEND_OTP_SUCCESS,
      payload: data,

  };
};
export const sendOtpFailure = (data) => {
  return {
      type: actionTypes.SEND_OTP_FAILURE,
      payload: data,
  };
}
export const otpSuccessHandler = () => {
  return {
      type: actionTypes.OTP_SUCCESS_HANDLER,
      payload: null,
  };
}

export const verifyOtp = (data) => {
  return {
      type: actionTypes.VERIFY_OTP_START,
      payload: data,
  };
};
export const verifyOtpSuccess = (data) => {
  //console.log('sendOtpSuccess ',data.data);
  return {
      type: actionTypes.VERIFY_OTP_SUCCESS,
      payload: data,

  };
};
export const verifyOtpFailure = (data) => {
  return {
      type: actionTypes.VERIFY_OTP_FAILURE,
      payload: data,
  };
}
export const verifyOtpSuccessHandler = () => {
  return {
      type: actionTypes.VERIFY_OTP_SUCCESS_HANDLER,
      payload: null,
  };
}

export const forgetPassword = (data) => {
  return {
      type: actionTypes.FORGET_PASSWORD_START,
      payload: data,
  };
};
export const forgetPasswordSuccess = (data) => {
  //console.log('sendOtpSuccess ',data.data);
  return {
      type: actionTypes.FORGET_PASSWORD_SUCCESS,
      payload: data,

  };
};
export const forgetPasswordFailure = (data) => {
  return {
      type: actionTypes.FORGET_PASSWORD_FAILURE,
      payload: data,
  };
}

export const getCity = (data) => {
  return {
      type: actionTypes.GET_CITY_START,
      payload: data,
  };
};
export const getCitySuccess = (data) => {
  //console.log('sendOtpSuccess ',data.data);
  return {
      type: actionTypes.GET_CITY_SUCCESS,
      payload: data,

  };
};
export const getCityFailure = (data) => {
  return {
      type: actionTypes.GET_CITY_FAILURE,
      payload: data,
  };
}



export const confirmDetails = (data) => {
  return {
      type: actionTypes.CONFIRM_DETAILS_START,
      payload: data,
  };
};
export const confirmDetailsSuccess = (data) => {
  //console.log('sendOtpSuccess ',data.data);
  return {
      type: actionTypes.CONFIRM_DETAILS_SUCCESS,
      payload: data,

  };
};
export const confirmDetailsFailure = (data) => {
  return {
      type: actionTypes.CONFIRM_DETAILS_FAILURE,
      payload: data,
  };
}

// Pre-Auth & Refresh Token
export const preAuthStart = (data) => {
  return {
    type: actionTypes.PRE_AUTH_START,
    payload: data,
  };
};
export const preAuthSuccess = (data) => {
  return {
    type: actionTypes.PRE_AUTH_SUCCESS,
    payload: data,
  };
};
export const preAuthFailure = (error) => {
  return {
    type: actionTypes.PRE_AUTH_FAILURE,
    payload: error,
  };
};

export const refreshTokenStart = (data) => {
  return {
    type: actionTypes.REFRESH_TOKEN_START,
    payload: data,
  };
};
export const refreshTokenSuccess = (data) => {
  return {
    type: actionTypes.REFRESH_TOKEN_SUCCESS,
    payload: data,
  };
};
export const refreshTokenFailure = (error) => {
  return {
    type: actionTypes.REFRESH_TOKEN_FAILURE,
    payload: error,
  };
};

export const checkUserStart = (data) => {
  return {
      type: actionTypes.CHECK_USER_START,
      payload: data,
  };
};
export const checkUserSuccess = (data) => {
  //console.log('sendOtpSuccess ',data.data);
  return {
      type: actionTypes.CHECK_USER_SUCCESS,
      payload: data,

  };
};
export const checkUserFailure = (data) => {
  return {
      type: actionTypes.CHECK_USER_FAILURE,
      payload: data,
  };
}

export const logoutSuccess = () => {
  // console.log('logoutSuccess auth.js')
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.clear();
  return {
    type: actionTypes.LOGOUT_SUCCESS,
    payload: null,
  };
};

export const loginErrorHandlerSuccess = () => {
  return {
    type: actionTypes.LOGIN_ERROR_HANDLER_SUCCESS,
    payload: null,
  };
};


