import React, { createContext, useContext, useState } from 'react';

export const AudioPlayerContext = createContext();

export const AudioPlayerProvider = ({ children }) => {
  const [currentTrack, setCurrentTrack] = useState();
  const [playlist, setPlaylist] = useState([]);
  const [playlistForAll, setPlaylistForAll] = useState([]);
  const [hitView, setHitView] = useState(0);

  const addSongPlayer = (song) => {
    setCurrentTrack(song);

  }

  const updatePlaylistForAll = (songs) => {
    setPlaylistForAll(songs);
  };


  const playAll = (songs) => {
    setPlaylist(songs);
    setCurrentTrack(songs[0]);
  };

  
  const playNext = () => {
    setHitView(hitView+1)
    const currentIndex = playlist.indexOf(currentTrack);
    if (currentIndex < playlist.length - 1) {
      setCurrentTrack(playlist[currentIndex + 1]);
    }
  };



  const handleClickPrevious = () => {
    const currentIndex = playlistForAll.indexOf(currentTrack);
    if (currentIndex > 0) {
      setCurrentTrack(playlistForAll[currentIndex - 1]);
    }
  };

  const handleClickNext = () => {
    const currentIndex = playlistForAll.indexOf(currentTrack);
    if (currentIndex < playlistForAll.length - 1) {
      setCurrentTrack(playlistForAll[currentIndex + 1]);
    }
  };


  
  return (
    <AudioPlayerContext.Provider
      value={{
        addSongPlayer,
        currentTrack,
        playAll,
        playNext,
        setPlaylist,
        hitView,
        setHitView,
        updatePlaylistForAll,playlistForAll,
        handleClickNext,handleClickPrevious

      }}>
      {children}
    </AudioPlayerContext.Provider>
  );
};

export default AudioPlayerProvider;


export function usePlayer() {
  return useContext(AudioPlayerContext)
}