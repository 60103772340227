import React, { useState, useEffect, useRef, useContext } from 'react';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import 'react-h5-audio-player/lib/styles.css';
import '../../../src/assets/scss/general.scss';
import '../../../src/assets/scss/tracks.scss';
import { shuffleIcon, videoIcon, heartIcon, downloadIcon, repeatIcon, musicIcon } from '../../assets/images/img';
import { customIcons, customIconsMobile } from '../../environment';
import dummyAds from '../../audio/adsDummy.mp3';
import { Menu, MenuItem, Tooltip } from '@material-ui/core';
import { AudioPlayerContext } from '../../routes/AudioPlayerContext';
import { usePlayer } from '../../routes/AudioPlayerContext';
import { APP_DOMAIN_PREFIX } from '../../environment';
import LoginParentModal from '../../components/modals/loginParentModal';
import SubscriptionModal from '../../components/modals/subscriptionModal';
import UserContext from '../../routes/UserContext';
import * as actions from "../../store/actions";
import { connect } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";

const options = [
  { name: 'Set As JazzTune', icon: musicIcon, type: 1 },
  {
    name: 'Set As Apni Dhun', icon: <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 8.35107C5.87969 8.35107 6.1875 8.65887 6.1875 9.03857V12.9711C6.1875 13.3508 5.87969 13.6586 5.5 13.6586C5.12031 13.6586 4.8125 13.3508 4.8125 12.9711V9.03857C4.8125 8.65887 5.12031 8.35107 5.5 8.35107Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25 7.04004C8.62969 7.04004 8.9375 7.34785 8.9375 7.72754V14.2725C8.9375 14.6522 8.62969 14.96 8.25 14.96C7.87031 14.96 7.5625 14.6522 7.5625 14.2725V7.72754C7.5625 7.34785 7.87031 7.04004 8.25 7.04004Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11 13.0537C11.3797 13.0537 11.6875 13.3615 11.6875 13.7412V15.5837C11.6875 15.9634 11.3797 16.2712 11 16.2712C10.6203 16.2712 10.3125 15.9634 10.3125 15.5837V13.7412C10.3125 13.3615 10.6203 13.0537 11 13.0537Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11 5.72949C11.3797 5.72949 11.6875 6.0373 11.6875 6.41699V10.9545C11.6875 11.3342 11.3797 11.642 11 11.642C10.6203 11.642 10.3125 11.3342 10.3125 10.9545V6.41699C10.3125 6.0373 10.6203 5.72949 11 5.72949Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.75 7.04004C14.1297 7.04004 14.4375 7.34785 14.4375 7.72754V14.2725C14.4375 14.6522 14.1297 14.96 13.75 14.96C13.3703 14.96 13.0625 14.6522 13.0625 14.2725V7.72754C13.0625 7.34785 13.3703 7.04004 13.75 7.04004Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5 8.35107C16.8797 8.35107 17.1875 8.65887 17.1875 9.03857V12.9711C17.1875 13.3508 16.8797 13.6586 16.5 13.6586C16.1203 13.6586 15.8125 13.3508 15.8125 12.9711V9.03857C15.8125 8.65887 16.1203 8.35107 16.5 8.35107Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.83676 2.83694C4.06002 1.61368 5.89051 1.146 8.24998 1.146H13.75C16.1095 1.146 17.94 1.61368 19.1632 2.83694C20.3865 4.06021 20.8541 5.89069 20.8541 8.25016V13.7502C20.8541 16.1097 20.3865 17.9402 19.1632 19.1634C17.94 20.3866 16.1095 20.8543 13.75 20.8543H8.24998C5.89051 20.8543 4.06002 20.3866 2.83676 19.1634C1.6135 17.9402 1.14581 16.1097 1.14581 13.7502V11.9077C1.14581 11.528 1.45362 11.2202 1.83331 11.2202C2.21301 11.2202 2.52081 11.528 2.52081 11.9077V13.7502C2.52081 15.974 2.9698 17.3518 3.80903 18.1911C4.64827 19.0303 6.02612 19.4793 8.24998 19.4793H13.75C15.9738 19.4793 17.3517 19.0303 18.191 18.1911C19.0302 17.3518 19.4791 15.974 19.4791 13.7502V8.25016C19.4791 6.0263 19.0302 4.64845 18.191 3.80922C17.3517 2.96998 15.9738 2.521 13.75 2.521H8.24998C6.02612 2.521 4.64827 2.96998 3.80903 3.80922C2.9698 4.64845 2.52081 6.0263 2.52081 8.25016C2.52081 8.62986 2.21301 8.93766 1.83331 8.93766C1.45362 8.93766 1.14581 8.62986 1.14581 8.25016C1.14581 5.89069 1.6135 4.06021 2.83676 2.83694Z" fill="white" />
    </svg>,
    type: 2
  },
  {
    name: 'Or Set Both', icon: <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path d="M11.4583 13.5666H9.16664C8.79998 13.5666 8.43331 13.2916 8.43331 12.8333C8.43331 12.3749 8.70831 12.0999 9.16664 12.0999H11.4583C13.8416 12.0999 15.8583 10.1749 15.8583 7.69993C15.8583 5.22493 13.9333 3.29993 11.4583 3.29993H6.87498C4.49164 3.29993 2.47498 5.22493 2.47498 7.69993C2.47498 8.70827 2.84164 9.7166 3.48331 10.4499C3.75831 10.7249 3.66664 11.1833 3.39164 11.4583C3.11664 11.7333 2.65831 11.6416 2.38331 11.3666C1.55831 10.3583 1.09998 9.07493 1.09998 7.69993C1.09998 4.58327 3.66664 2.0166 6.78331 2.0166H11.3666C14.4833 2.0166 17.05 4.58327 17.05 7.69993C17.05 10.8166 14.575 13.5666 11.4583 13.5666Z" fill="white" />
      <path d="M11 19.9834H10.5417C7.425 19.9834 4.85834 17.4167 4.85834 14.3C4.85834 11.1834 7.425 8.6167 10.5417 8.6167H12.8333C13.2 8.6167 13.5667 8.8917 13.5667 9.35003C13.5667 9.80837 13.2917 10.0834 12.8333 10.0834H10.5417C8.15834 10.0834 6.14167 12.0084 6.14167 14.4834C6.14167 16.9584 8.06667 18.8834 10.5417 18.8834H11C11.3667 18.8834 11.7333 19.1584 11.7333 19.6167C11.7333 20.075 11.3667 19.9834 11 19.9834Z" fill="white" />
      <path d="M15.125 19.9834C14.7583 19.9834 14.3917 19.7084 14.3917 19.2501C14.3917 18.7918 14.6667 18.5168 15.125 18.5168C17.5083 18.5168 19.525 16.5918 19.525 14.1168C19.525 13.1084 19.1583 12.1001 18.5167 11.3668C18.2417 11.0918 18.3333 10.6334 18.6083 10.3584C18.8833 10.0834 19.3417 10.1751 19.6167 10.4501C20.4417 11.4584 20.9 12.7418 20.9 14.1168C20.9 17.3251 18.2417 19.9834 15.125 19.9834Z" fill="white" />
    </svg>,
    type: 3
  },
  {
    name: 'Share', icon: <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path d="M18.9013 11.9805C18.553 11.9805 18.2597 11.7147 18.2138 11.3663C17.9938 9.29467 16.8755 7.4155 15.1522 6.22383C14.8405 6.00383 14.7672 5.58217 14.978 5.2705C15.198 4.95883 15.6288 4.8855 15.9313 5.09633C17.9847 6.51717 19.3047 8.75383 19.5705 11.2197C19.6072 11.5955 19.3413 11.9347 18.9563 11.9805C18.9472 11.9805 18.9197 11.9805 18.9013 11.9805Z" fill="white" />
      <path d="M3.19908 12.0265C3.17158 12.0265 3.15325 12.0265 3.12575 12.0265C2.74991 11.9807 2.47491 11.6415 2.51158 11.2657C2.75908 8.79983 4.06991 6.57233 6.09575 5.13316C6.40741 4.91316 6.83825 4.9865 7.05825 5.29816C7.27825 5.60983 7.20491 6.04066 6.89325 6.26066C5.18825 7.4615 4.08825 9.34065 3.87741 11.4123C3.84991 11.7607 3.54741 12.0265 3.19908 12.0265Z" fill="white" />
      <path d="M11.055 20.7255C9.69831 20.7255 8.40578 20.4138 7.19578 19.8088C6.85661 19.6346 6.71911 19.2221 6.89328 18.883C7.06745 18.5438 7.47995 18.4063 7.81911 18.5805C9.79914 19.5796 12.1825 19.598 14.1808 18.6355C14.52 18.4705 14.9325 18.6171 15.0975 18.9563C15.2625 19.2955 15.1158 19.708 14.7766 19.873C13.6033 20.4413 12.3566 20.7255 11.055 20.7255Z" fill="white" />
      <path d="M11.0544 7.73632C9.26688 7.73632 7.81854 6.28798 7.81854 4.50048C7.81854 2.71298 9.26688 1.26465 11.0544 1.26465C12.8419 1.26465 14.2902 2.71298 14.2902 4.50048C14.2902 6.28798 12.8327 7.73632 11.0544 7.73632ZM11.0544 2.64882C10.0277 2.64882 9.19354 3.48298 9.19354 4.50965C9.19354 5.53631 10.0277 6.37048 11.0544 6.37048C12.081 6.37048 12.9152 5.53631 12.9152 4.50965C12.9152 3.48298 12.0719 2.64882 11.0544 2.64882Z" fill="white" />
      <path d="M4.42822 18.9473C2.64072 18.9473 1.19238 17.4989 1.19238 15.7114C1.19238 13.9331 2.64072 12.4756 4.42822 12.4756C6.21572 12.4756 7.66405 13.9239 7.66405 15.7114C7.66405 17.4898 6.21572 18.9473 4.42822 18.9473ZM4.42822 13.8506C3.40155 13.8506 2.56738 14.6848 2.56738 15.7114C2.56738 16.7381 3.40155 17.5723 4.42822 17.5723C5.45488 17.5723 6.28905 16.7381 6.28905 15.7114C6.28905 14.6848 5.45488 13.8506 4.42822 13.8506Z" fill="white" />
      <path d="M17.5731 18.9473C15.7856 18.9473 14.3372 17.4989 14.3372 15.7114C14.3372 13.9331 15.7856 12.4756 17.5731 12.4756C19.3606 12.4756 20.8089 13.9239 20.8089 15.7114C20.7997 17.4898 19.3514 18.9473 17.5731 18.9473ZM17.5731 13.8506C16.5464 13.8506 15.7122 14.6848 15.7122 15.7114C15.7122 16.7381 16.5464 17.5723 17.5731 17.5723C18.5997 17.5723 19.4339 16.7381 19.4339 15.7114C19.4247 14.6848 18.5997 13.8506 17.5731 13.8506Z" fill="white" />
    </svg>
  },
];

const CustomAudioPlayer = (props) => {

  const { currentSong, classes } = props;
  const { user, type, setType, isModel, setModel, isShow, setShow } = useContext(UserContext);
  const { addSongPlayer, playNext,hitView,setHitView,handleClickNext, handleClickPrevious } = usePlayer();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1199);

  const [adsPlayed, setAdsPlayed] = useState(false);
  const audioPlayer = useRef(null);
  const mainAudioPositionRef = useRef();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [openLogin, setOpenLogin] = useState(false)
  const [openSubscription, setOpenSubscription] = useState(false)
  const [tuneType, setTuneType] = useState(null)
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [userLogin, setUserLogin] = useState(false);
  const [userData, setUserData] = useState(false);








  const handlePlayerClose = () => {
    addSongPlayer(null)
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if(user){
      setUserData(user.id)
    }
  }, [user]);


  useEffect(() => {
    setHitView(0)
    const storedLoginStatus = localStorage.getItem('isLoggedIn');
    const userData = JSON.parse(localStorage.getItem('user'));
    if(userData){
      setUserData(userData.id)
    }
    if (storedLoginStatus === 'true')
      setLoggedIn(true);
    else
      setLoggedIn(false);
  }, []);

  useEffect(() => {
    if (type === 1) {
      setOpenSubscription(true);
    }
  }, [type]);

  useEffect(() => {
    if (hitView) {
      props.songView({user:userData,song:currentSong.content_id})
    }
  }, [hitView]);

  const handleModal = (type) => {
    setType(1)
    setModel(true)
    if (type === 1)
      setTuneType(type);
    else if (type === 2)
      setTuneType(type);
    else if (type === 3)
      setTuneType(type);
    if (isLoggedIn || user) {
      setOpenSubscription(true);
    }
    else
      setOpenLogin(true);
    handleClose();
  }

  const handleLoginSuccess = () => {
    setLoggedIn(true);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMainAudioEnd = () => {
    console.log('Main Audio Finished. mainAudioPosition:');
    // Handle any logic you need when the main audio completes
  };

  const handleAudioEnded = () => {
    props.songView({user:userData,song:currentSong.content_id})
  };
  const playAds = () => {
    const audioInstance = audioPlayer.current && audioPlayer.current.audio.current;
    if (audioInstance) {
      console.log('Playing Ads');
      mainAudioPositionRef.current = audioInstance.currentTime;

      // Simulate playing an audio ad
      audioInstance.src = dummyAds;

      // Set up event listener for when the ad completes
      audioInstance.addEventListener('ended', handleAdsEnd);

      // Play the audio ad
      audioInstance.play().catch(error => {
        console.error('Error playing ad:', error);
        handleAdsEnd(); // If there's an error playing the ad, proceed to handleAdsEnd
      });
    }
  };

  const handleAdsEnd = () => {
    console.log('Ads Finished');
    const audioInstance = audioPlayer.current && audioPlayer.current.audio.current;
    if (audioInstance) {
      console.log("audio time", audioInstance.currentTime);

      // Remove event listener
      audioInstance.removeEventListener('ended', handleAdsEnd);

      // Load the main audio source
      audioInstance.src = currentSong ? currentSong.src : null;

      // Set up event listener for when the main audio completes
      audioInstance.addEventListener('ended', handleMainAudioEnd);

      // Set the currentTime and play the main audio
      audioInstance.currentTime = mainAudioPositionRef.current || 0;
      audioInstance.play().catch(error => {
        console.error('Error playing main audio:', error);
      });
    }
  };

  const handleAudioPlay = () => {
    console.log('Audio Play. Ads Played:', adsPlayed, 'mainAudioPosition:', mainAudioPositionRef.current);
    if (!adsPlayed) {
      const audioInstance = audioPlayer.current && audioPlayer.current.audio.current;
      if (audioInstance && mainAudioPositionRef.current === undefined) {
        mainAudioPositionRef.current = audioInstance.currentTime;
        // console.log('mainAudioPosition set:', audioInstance.currentTime);
      }
    }
  };

  useEffect(() => {
    if (!adsPlayed) {
      console.log('Song Playing');
      // Check if the audio is playing and ads haven't been played yet
      setTimeout(() => {
        // playAds();
      }, 30000);
    }
  }, [adsPlayed, currentSong]);

  // useEffect(() => {
  //   const audioInstance = audioPlayer.current && audioPlayer.current.audio.current;
  //   if (audioInstance) {
  //     // Set up event listener for when the main audio starts playing
  //     audioInstance.addEventListener('play', handleAudioPlay);
  //     return () => {
  //       // Clean up event listener on component unmount
  //       audioInstance.removeEventListener('play', handleAudioPlay);
  //     };
  //   }
  // }, []);


  return (
    <>
      <div>
        {!isMobile &&
          <div style={{ position: 'relative' }} className='player-for-full-screen'>
            {
              (user || isLoggedIn) && type === 1 &&
              <SubscriptionModal openModal={openSubscription} setOpen={setOpenSubscription} rowData={currentSong} tuneType={tuneType} />
            }
            {
              (!user || !isLoggedIn) &&
              type === 1 && <LoginParentModal openModal={openLogin} setOpen={setOpenLogin} isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} handleLoginSuccess={handleLoginSuccess} type={1} />
            }
            <div className='audio-player-bottom'>
              <AudioPlayer
                ref={audioPlayer}
                // onEnded={handleAudioEnded}
                showFilledVolume={true}
                customIcons={customIcons}
                showSkipControls={true}
                customAdditionalControls={[
                  <div className="thumbnailouter-track-audio" key={0}>
                    <div className="thumbnail-track-audio">
                      {<img src={currentSong ? currentSong.thumbnail || currentSong.image : 'path-to-default-thumbnail.jpg'} alt='Thumbnail' className='thumbnail' />}
                    </div>
                    <div className='detail-track-audio'>
                      <span>{currentSong?.title}</span>
                      <span>{currentSong?.artist}</span>
                    </div>
                    <div className='player-tune'>
                      <button className='track-player-btn' onClick={() => handleModal(1)}>
                        <span className='icon-span'>
                          <img src={musicIcon} />
                        </span>
                        <span className='icon-span-text'>Set As JazzTune</span>
                      </button>
                    </div>
                    <div className='svg-gap'>
                      <Tooltip title="More" onClick={handleClick}>
                        <MoreVertIcon style={{ color: '#FACD66', cursor: 'pointer', position: 'absolute', left: '24%', bottom: '46%' }} />
                      </Tooltip>
                      <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          className: classes.menuPaper,
                        }}
                      >
                        {options.map((option, index) => (
                          <MenuItem key={index} selected='' onClick={() => handleModal(option.type)}>
                            <span className={`${classes.menuItemIcon} -icon-player`}>
                              {option.name === 'Set As JazzTune' ? <img src={option.icon} /> : option.icon}
                            </span>
                            <span>{option.name}</span>
                          </MenuItem>
                        ))}
                      </Menu>
                    </div>
                  </div>,
                  <div>
                    <span className='shuffle-icon' key={1}><img src={shuffleIcon} alt='Shuffle' /></span>
                  </div>,

                  // RHAP_UI.LOOP,
                ]}
                customVolumeControls={[
                  <div key={0} className='audio-extra-button'>
                    {/* <span><img src={downloadIcon} alt='Download' /></span> */}
                    {/* <span><img src={videoIcon} alt='Video' /></span> */}
                    {/* <span><img src={heartIcon} alt='Heart' /></span> */}
                  </div>,
                  RHAP_UI.VOLUME,
                ]}

                autoPlay
                src={currentSong ? currentSong.filename : null}
                onEnded={playNext} // Play next song when the current one ends
                // onPlay={e => console.log("onPlay")}
                // autoPlay
                // src={adsPlayed ? dummyAds : (currentSong ? currentSong.src : null)}
                onPlay={handleAudioPlay}
                onClickPrevious={handleClickPrevious}
                onClickNext={handleClickNext}

              />
            </div>
            <div style={{ position: 'absolute', right: '3%', bottom: '35%', cursor: 'pointer' }} onClick={() => handlePlayerClose()}>

              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z" fill="black" fill-opacity="0.3" stroke="#DDDDDD" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.63527 7.41038C7.54371 7.4104 7.4613 7.4659 7.42685 7.55072C7.39239 7.63555 7.41277 7.7328 7.47839 7.79666L9.68181 10.0001L7.47839 12.2035C7.41974 12.26 7.39616 12.3437 7.41673 12.4225C7.43731 12.5012 7.49882 12.5628 7.57759 12.5833C7.65636 12.6039 7.7401 12.5803 7.79656 12.5217L9.99998 10.3183L12.2034 12.5217C12.2599 12.5803 12.3436 12.6039 12.4224 12.5833C12.5011 12.5628 12.5627 12.5012 12.5832 12.4225C12.6038 12.3437 12.5802 12.26 12.5216 12.2035L10.3182 10.0001L12.5216 7.79666C12.5881 7.73197 12.6081 7.63307 12.572 7.5476C12.5358 7.46214 12.4509 7.40764 12.3581 7.41038C12.2997 7.41212 12.2442 7.43655 12.2034 7.47849L9.99999 9.68192L7.79657 7.47849C7.75421 7.43495 7.69604 7.41038 7.63529 7.41038H7.63527Z" fill="white" />
              </svg>

            </div>
          </div>
        }
      </div>

      {/**for mobile player */}
      <div>
        {isMobile &&
          <div className='player-for-mobile'>
            <div className='audio-player-bottom '>
              <AudioPlayer
                className='audio-player-mobile'
                ref={audioPlayer}
                // onEnded={handleAudioEnded}
                showFilledVolume={true}
                customIcons={customIconsMobile}
                customAdditionalControls={[
                  <div className="thumbnailouter-track-audio" key={0}>
                    <div className="thumbnail-track-audio">
                      {<img src={currentSong ? currentSong.thumbnail : 'path-to-default-thumbnail.jpg'} alt='Thumbnail' className='thumbnail' />}
                    </div>
                    <div className='detail-track-audio'>
                      <span>{currentSong?.title}</span>
                      <span>{currentSong?.artist}</span>
                    </div>
                    {/* <div className='player-tune'>
                <button className='track-player-btn' onClick={() => handleModal(1)}>
                  <span className='icon-span'>
                    <img src={musicIcon} />
                  </span>
                  <span className='icon-span-text'>Set As JazzTune</span>
                </button>
              </div> */}
                    <div className='svg-gap'>
                      <Tooltip title="More" onClick={handleClick}>
                        <MoreVertIcon style={{ color: '#FACD66', cursor: 'pointer', position: 'absolute', left: '24%', bottom: '46%' }} />
                      </Tooltip>
                      <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          className: classes.menuPaper,
                        }}
                      >
                        {options.map((option, index) => (
                          <MenuItem key={index} selected='' onClick={() => handleModal(option.type)}>
                            <span className={classes.menuItemIcon}>
                              {option.name === 'Set As JazzTune' ? <img src={option.icon} /> : option.icon}
                            </span>
                            <span>{option.name}</span>
                          </MenuItem>
                        ))}
                      </Menu>
                    </div>
                  </div>,
                  <div>
                    {/* <span className='shuffle-icon' key={1}><img src={shuffleIcon} alt='Shuffle' /></span> */}
                  </div>,

                  // RHAP_UI.LOOP,
                ]}

                autoPlay
                src={currentSong ? currentSong.filename : null}
                onEnded={playNext} // Play next song when the current one ends
                // onPlay={e => console.log("onPlay")}
                // autoPlay
                // src={adsPlayed ? dummyAds : (currentSong ? currentSong.src : null)}
                onPlay={handleAudioPlay}
                onClickPrevious={handleClickPrevious}
                onClickNext={handleClickNext}
              />
            </div>
            <div style={{ position: 'absolute', right: '3%', bottom: '35%', cursor: 'pointer' }} onClick={() => handlePlayerClose()}>

              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z" fill="black" fill-opacity="0.3" stroke="#DDDDDD" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.63527 7.41038C7.54371 7.4104 7.4613 7.4659 7.42685 7.55072C7.39239 7.63555 7.41277 7.7328 7.47839 7.79666L9.68181 10.0001L7.47839 12.2035C7.41974 12.26 7.39616 12.3437 7.41673 12.4225C7.43731 12.5012 7.49882 12.5628 7.57759 12.5833C7.65636 12.6039 7.7401 12.5803 7.79656 12.5217L9.99998 10.3183L12.2034 12.5217C12.2599 12.5803 12.3436 12.6039 12.4224 12.5833C12.5011 12.5628 12.5627 12.5012 12.5832 12.4225C12.6038 12.3437 12.5802 12.26 12.5216 12.2035L10.3182 10.0001L12.5216 7.79666C12.5881 7.73197 12.6081 7.63307 12.572 7.5476C12.5358 7.46214 12.4509 7.40764 12.3581 7.41038C12.2997 7.41212 12.2442 7.43655 12.2034 7.47849L9.99999 9.68192L7.79657 7.47849C7.75421 7.43495 7.69604 7.41038 7.63529 7.41038H7.63527Z" fill="white" />
              </svg>

            </div>
          </div>
        }
      </div>
    </>
  );
};

//what is needed at start
const mapStateToProps = ({homeReducer }) => {
  const { loading, error, message} = homeReducer;
  return { loading, error, message};
};

//which actions our function can dispatch
const mapDispatchToProps = (dispatch) => {
  return {
    songView: (data) => dispatch(actions.getWatchVideoStart(data)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomAudioPlayer));
