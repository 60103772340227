import axios from "axios";
import {API_URL,APP_DOMAIN_PREFIX} from "../environment";

if (localStorage.getItem("token")) {
  axios.defaults.headers.common["Authorization"] = "Bearer "+localStorage.getItem("token");
} else if (!localStorage.getItem("token") && localStorage.getItem("pre_auth")) {
  axios.defaults.headers.common["Authorization"] = "Bearer "+localStorage.getItem("pre_auth");
} else {
  axios.defaults.headers.common["Authorization"] = "";
}

if (localStorage.getItem("user") && localStorage.getItem("userid")) {
  let user = localStorage.getItem("user"); 
  axios.defaults.headers.common["userid"] = localStorage.getItem("userid");
} else {
  axios.defaults.headers.common["userid"] = Date.now();
}

axios.defaults.headers.common["app"] = "WEB";
axios.defaults.headers.common["lang"] = "EN";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default class HttpService {
  static setToken = (token) => {
    axios.defaults.headers.common["Authorization"] = "Bearer "+token;
  };
  static setUser = (user) => { 
    axios.defaults.headers.common["user"] = user;
  };
  static setUserId = (id) => { 
    axios.defaults.headers.common["userid"] = id;
  };
  static setLang = (lang) => { 
    axios.defaults.headers.common["lang"] = lang;
  };

  static removeUser = () => {
    axios.defaults.headers.common["user"] = '';
    axios.defaults.headers.common["Authorization"]=null;
  };
  static guestUser = (userId, token) => {
    axios.defaults.headers.common["userid"] = userId;
    axios.defaults.headers.common["Authorization"]="Bearer "+token;;
  };


  get = (url, params) => axios.get(`${API_URL}/${url}`, {params});

  geth = (url, config) => axios.get(`${API_URL}/${url}`, config);

  gethSearch = (url, config) => axios.get(`${APP_DOMAIN_PREFIX}/teleschool/lms/ws/${url}`, config);

  post = (url, data, config) => axios.post(`${API_URL}/${url}`, data, config);

  postPass = (url, data, config) => axios.post(`${API_URL}/${url}`, data,
  {
    headers: {
      'email': data.email,
      'password':'1234'
    },
    ...config // You can also pass other configurations
  });

  put = (url, data, params) => axios.put(`${API_URL}/${url}?user_id=${data.user_id}`, data.data);

  puts = (url, params, config) => axios.put(`${API_URL}/${url}`, {params}, config);

  patch = (url,  params) => axios.patch(`${API_URL}/${url}`,  params);

  delete = (url, params) => axios.delete(`${API_URL}/${url}`, {params});

  deleteh = (url, config) => axios.delete(`${API_URL}/${url}`, config);

  gets = (url, params) => axios.get(`${API_URL}/${url}`, {params});

  // patch = (url, params) => axios.patch(`${API_URL}/${url}`, {params});

  //addressget = (params) => axios.get(`https://api.ideal-postcodes.co.uk/v1/autocomplete/addresses?api_key=ak_jc635mjv12swIsWCiEJWOAiDG0W84&query=${params}`);
}
