import { all } from "redux-saga/effects";
import { authSaga } from "./authSagas";
import { dashboardSaga } from "./dashboardSagas";
import { homeSaga } from "./homeSaga";
import { accountSaga } from "./accountSaga";
import { categorySaga } from "./categorySaga";

export function* rootSaga(getState) {
  yield all([
    authSaga(),
    dashboardSaga(),
    homeSaga(),
    accountSaga(),
    categorySaga()
  ]);
}
