import * as actionTypes from "../actionTypes";

const initialState = {
  loading: false,
  user: null,
  error: null,
  success: null,
  preAuth: null,
  errorSignup: null,
  successSignup: null,
  errorLogin: null,
  successLogin: null,
  preAuthSuccess: null,
  preAuthError: null,
  otpSuccess: null,
  verifyOtpSuccess: null,
  sendOtpData: null,
  sendOtpError: null,
  loggedInSuccess: false,
  queryList: null,
  queryListError: null,
  confirmDetailError: null,
  confirmDetailData: null,
  verifyOtpError: null,
  verifyOtpData: null,
  confirmDetailSuccess: null,
  forgetPasswordSuccess: null,
  forgetPasswordMessage: null,
  checkUserError: null,
  checkUserSuccess: null,
  checkUserLoading: false,
  checkUserData: null

};

export const authReducer = (state = initialState, action) => {
  // console.log('action.type home2 === ',action.payload);
  switch (action.type) {
    case actionTypes.LOGIN_START:
      return { ...state, loading: true, errorLogin: null, successLogin: null };
    case actionTypes.LOGIN_SUCCESS:
      return { ...state, loading: false, errorLogin: null, successLogin: action.payload.message, loggedInSuccess: true };
    case actionTypes.LOGIN_FAILURE:
      return { ...state, loading: false, errorLogin: action.payload, successLogin: null };

    case actionTypes.SIGNUP_START:
      return { ...state, loading: true, errorSignup: null, successSignup: null, user: null };
    case actionTypes.SIGNUP_SUCCESS:
      return { ...state, loading: false, errorSignup: null, loggedInSuccess: true, successSignup: action.payload?.data?.isSuccess, user: action.payload.data.data, message: action.payload?.message };
    case actionTypes.SIGNUP_FAILURE:
      return { ...state, loading: false, errorSignup: action.payload, message: null, user: null };

    /** SEND OTP START */
    case actionTypes.SEND_OTP_START:
      return { ...state, loading: true, sendOtpError: null, sendOtpData: null };

    case actionTypes.SEND_OTP_SUCCESS:
      return { ...state, loading: false, sendOtpError: null, otpSuccess: action.payload.data.isSuccess, sendOtpData: action.payload, };

    case actionTypes.SEND_OTP_FAILURE:
      return { ...state, loading: false, sendOtpError: action.payload, sendOtpData: null, };
    
    case actionTypes.OTP_SUCCESS_HANDLER:
      return { ...state, loading: false, sendOtpError: action.payload, otpSuccess: action.payload, sendOtpData: action.payload };  
    /** END */


    /** SEND OTP START */
    case actionTypes.VERIFY_OTP_START:
      return { ...state, loading: true, verifyOtpError: null, verifyOtpData: null };

    case actionTypes.VERIFY_OTP_SUCCESS:
      return { ...state, loading: false, verifyOtpError: null, verifyOtpSuccess: action.payload.data.isSuccess, verifyOtpData: action.payload, };

    case actionTypes.VERIFY_OTP_FAILURE:
      return { ...state, loading: false, verifyOtpError: action.payload, verifyOtpData: null, };

    case actionTypes.VERIFY_OTP_SUCCESS_HANDLER:
      return { ...state, loading: false, verifyOtpError: action.payload, verifyOtpSuccess: action.payload, verifyOtpData: action.payload };  
    /** END */

    case actionTypes.FORGET_PASSWORD_START:
      return { ...state, loading: true, forgetPasswordData: null };

    case actionTypes.FORGET_PASSWORD_SUCCESS:
      return { ...state, loading: false, forgetPasswordSuccess: action.payload?.data?.isSuccess, forgetPasswordData: action.payload.message, };

    case actionTypes.FORGET_PASSWORD_FAILURE:
      return { ...state, loading: false, forgetPasswordSuccess: action.payload?.isSuccess, forgetPasswordMessage: action.payload?.message, forgetPasswordData: null, };


    /** CONFIRM DETAILS */
    case actionTypes.CONFIRM_DETAILS_START:
      return { ...state, loading: true, confirmDetailError: null, confirmDetailData: null };

    case actionTypes.CONFIRM_DETAILS_SUCCESS:
      return { ...state, loading: false, confirmDetailError: null, confirmDetailSuccess: action.payload.data.isSuccess, confirmDetailData: action.payload, };

    case actionTypes.CONFIRM_DETAILS_FAILURE:
      return { ...state, loading: false, confirmDetailError: action.payload, confirmDetailData: null, };
    /** END */

    /** PRE-AUTH & REFRESH TOKEN */
    case actionTypes.PRE_AUTH_START:
      return { ...state, loading: true, preAuthError: null, preAuthSuccess: null, preAuth: null };
    case actionTypes.PRE_AUTH_SUCCESS:
      return { ...state, loading: false, preAuthError: null, preAuthSuccess: action?.payload?.isSuccess, preAuth: action.payload };
    case actionTypes.PRE_AUTH_FAILURE:
      return { ...state, loading: false, preAuthError: action?.payload?.message, preAuth: null };

    case actionTypes.REFRESH_TOKEN_START:
      return { ...state, loading: true, preAuthError: null, preAuthSuccess: null, preAuth: null };
    case actionTypes.REFRESH_TOKEN_SUCCESS:
      return { ...state, loading: false, preAuthError: null, preAuthSuccess: action?.payload?.isSuccess, preAuth: action.payload };
    case actionTypes.REFRESH_TOKEN_FAILURE:
      return { ...state, loading: false, preAuthError: action.payload?.message, preAuth: null };

    // Check User
    case actionTypes.CHECK_USER_START:
      return { ...state, checkUserLoading: true, error: null, checkUserSuccess: null, checkUserData: null };
    case actionTypes.CHECK_USER_SUCCESS:
      return { ...state, checkUserLoading: false, checkUserError: null, checkUserSuccess: action?.payload?.message, checkUserData: action.payload };
    case actionTypes.CHECK_USER_FAILURE:
      return { ...state, checkUserLoading: false, checkUserError: action.payload?.message, checkUserSuccess: null, checkUserData: null };


    case actionTypes.LOGOUT:
      return { ...state, loading: false, error: null, success: null, user: {}, loggedInSuccess: false };
    case actionTypes.LOGOUT_SUCCESS:
      return { ...state, loading: false, error: null, success: null, user: null };

    case actionTypes.LOGIN_ERROR_HANDLER_SUCCESS:
      return { ...state, loading: false, checkUserData: null, checkUserLoading: false, checkUserError: null, checkUserSuccess: null, successSignup: null, errorLogin: null, errorSignup: null, forgetPasswordSuccess: null, forgetPasswordMessage: null };


    default:
      return {
        ...state,
        checkUserLoading: false, 
        checkUserError: null, 
        checkUserSuccess: null,
        checkUserData: null
      };
  }
};
