import React, { Suspense, useContext, useState } from "react";
import { useStyles } from "../container/sidebar/styles";
import { useStyles as playerStyles } from "../container/tracks/styles";
import MySideBar from '../container/sidebar/MySidebar';
import { Loader } from "../components";
import { useLocation } from "react-router-dom";
import MainFooter from "../container/footer";
import CustomAudioPlayer from "../components/audioPlayer/audioPlayer";

import { usePlayer } from "./AudioPlayerContext";
const MainLayout = (props) => {
  const location = useLocation();
  const classes = useStyles();
  const playerClasses = playerStyles();
  const { currentTrack } = usePlayer();


  return (
    <div>
      <div className={[classes.root, " sidebar-container "]}>
        <MySideBar sidebar={props.sidebar} />
        <div className={classes.content}>
          <Suspense fallback={<Loader />}>
            {props.children}
          </Suspense>
          {/* <div className="footer-main">
            <div className="footer-copyright">
            </div>
          </div> */}
        </div>
      </div>
      {currentTrack && (
        <div className='audio-player-main '>
          <CustomAudioPlayer
            currentSong={currentTrack}
            classes={playerClasses}
          />
        </div>
      )}
      {
        location.pathname !== '/tracks' &&
        <MainFooter />
      }
    </div>
  );
};
export default MainLayout;
