import React from 'react';
import Typography from "@material-ui/core/Typography";
import SearchBar from "../../search";
import {DownloadAddButton} from '../../index'

function Index(props) {
  const {
    tableLabel, searchValue, setSearchValue, newIconHandler, showSearch = true, showAdd = true,
    exportData, searchPlaceholder = 'search...', downloadHandler,
    className,styles,loading,fileName
  } = props;
  return (
    <div className={` table-title-container ${className}`}style={styles}>
      <Typography variant='h4' className='table-title'>
        {tableLabel}
      </Typography>

      {(searchValue != null || showAdd) && <div className='search-bar-container'>
        {showSearch && <SearchBar value={searchValue} onChange={setSearchValue} placeholder={searchPlaceholder}/>}
        {showAdd &&
        <DownloadAddButton addHandler={newIconHandler} downloadHandler={downloadHandler} data={exportData} loading={loading} fileName={fileName}/>}
        {/*{exportData && <ExportToCSV data={exportData}/>}*/}
        {/*{showAdd && <AddIcon className='add-icon mouse-pointer' titleAccess='Add New' onClick={newIconHandler}/>}*/}
      </div>}
    </div>
  );
}

export default Index;
