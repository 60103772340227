import * as actionTypes from "../actionTypes";


export const getCategoryStart = (data) => {
    return {
        type: actionTypes.GET_CATEGORY_START,
        payload: data,
    };
};
export const getCategorySuccess = (data) => {
    return {
        type: actionTypes.GET_CATEGORY_SUCCESS,
        payload: data,
    };
};
export const getCategoryFailure = (data) => {
    return {
        type: actionTypes.GET_CATEGORY_FAILURE,
        payload: data,
    };
};


export const getCategoryDataStart = (data) => {
    return {
        type: actionTypes.GET_CATEGORY_DATA_START,
        payload: data,
    };
};
export const getCategoryDataSuccess = (data) => {
    return {
        type: actionTypes.GET_CATEGORY_DATA_SUCCESS,
        payload: data,
    };
};
export const getCategoryDataFailure = (data) => {
    return {
        type: actionTypes.GET_CATEGORY_DATA_FAILURE,
        payload: data,
    };
};

export const getSongsByCategoryStart = (data) => {
    return {
        type: actionTypes.GET_SONG_BY_CATEGORY_START,
        payload: data,
    };
};
export const getSongsByCategorySuccess = (data) => {
    return {
        type: actionTypes.GET_SONG_BY_CATEGORY_SUCCESS,
        payload: data,
    };
};
export const getSongsByCategoryFailure = (data) => {
    return {
        type: actionTypes.GET_SONG_BY_CATEGORY_FAILURE,
        payload: data,
    };
};
