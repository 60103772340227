import * as actionTypes from "../actionTypes";

const initialState = {
    loading: false,
    watchVideoLoading: false,
    bannersLoading: false,
    sectionContentLoading: false,
    songsByArtistLoading: false,
    gradesLoading: false,
    liveChannelsLoading: false,
    contentLoading: false,
    contentByGradeIdLoading: false,
    cityLoading: false,
    data: null,
    setAsFavouriteData: null,
    searchLoading: false,
    searchError: null,
    searchSuccess: null,
    setAsJazzTuneLoading: false,
    setAsJazzTuneError: null,
    setAsJazzTuneSuccess: null,
    setAsJazzTuneData: null,
    bannersData: null,
    gradesData: null,
    contentData: null,
    sectionContentData: null,
    songsByArtistData: null,
    allContentSubscriptionData: null,
    contentSubscriptionDataLoading: false,
    contentSubscriptionData: null,
    profileFavouritesData: null,
    contentByGradeIdData: [],
    contentSearchError: null,
    search: '',
    contentSearch: [],
    channelData: null,
    channelByIdData: null,
    contWatchLoading: null,
    contWatchData: null,
    subjectData: null,
    subjectDataError: false,
    contentByChapterIdData: null,
    contentByChapterIdLoading: false,
    watchHistoryData: null,
    introStreamLink: null,
    introStreamLoading: false,
    provinceLoading: false,
    provinceData: null,
    schoolLoading: false,
    schoolData: null,
    watchHistoryLoading: false,
    error: null,
    success: null,
    errorCity: null,
    successCity: null
};
export const homeReducer = (state = initialState, action) => {
    switch (action.type) {
        
        case actionTypes.ALL_CONTENT_SUBSCRIPTION_DATA_START:
            return { ...state, loading: true, error: null, success: null, allContentSubscriptionData: null };
        case actionTypes.ALL_CONTENT_SUBSCRIPTION_DATA_SUCCESS:
            return { ...state, loading: false, error: null, success: null, allContentSubscriptionData: action.payload, };
        case actionTypes.ALL_CONTENT_SUBSCRIPTION_DATA_FAILURE:
            return { ...state, loading: false, error: action.payload, success: null, };

        case actionTypes.CONTENT_SUBSCRIPTION_DATA_START:
            return { ...state, contentSubscriptionDataLoading: true, error: null, success: null, contentSubscriptionData: null };
        case actionTypes.CONTENT_SUBSCRIPTION_DATA_SUCCESS:
            return { ...state, contentSubscriptionDataLoading: false, error: null, success: null, contentSubscriptionData: action.payload, };
        case actionTypes.CONTENT_SUBSCRIPTION_DATA_FAILURE:
            return { ...state, contentSubscriptionDataLoading: false, error: action.payload, success: null, };

        case actionTypes.PROFILE_FAVOURITES_DATA_START:
            return { ...state, profileFavouritesDataLoading: true, error: null, success: null, profileFavouritesData: null };
        case actionTypes.PROFILE_FAVOURITES_DATA_SUCCESS:
            return { ...state, profileFavouritesDataLoading: false, error: null, success: null, profileFavouritesData: action.payload, };
        case actionTypes.PROFILE_FAVOURITES_DATA_FAILURE:
            return { ...state, profileFavouritesDataLoading: false, error: action.payload, success: null, };

        case actionTypes.SET_AS_FAVOURITE_START:
            return { ...state, loading: true, error: null, success: null, setAsFavouriteData: null };
        case actionTypes.SET_AS_FAVOURITE_SUCCESS:
            return { ...state, loading: false, error: null, success: null, setAsFavouriteData: action.payload, };
        case actionTypes.SET_AS_FAVOURITE_FAILURE:
            return { ...state, loading: false, error: action.payload, success: null, };
        case actionTypes.UPDATE_FAVOURITE_START:
            return { ...state, loading: true, error: null, success: null, setAsFavouriteData: null };
        case actionTypes.UPDATE_FAVOURITE_SUCCESS:
            return { ...state, loading: false, error: null, success: null, setAsFavouriteData: action.payload, };
        case actionTypes.UPDATE_FAVOURITE_FAILURE:
            return { ...state, loading: false, error: action.payload, success: null, };
        case actionTypes.SET_AS_FAVOURITE_SUCCESS_HANDLER:
            return { ...state, loading: false, error: action.payload, success: action.payload, setAsFavouriteData: action.payload };  

        case actionTypes.SEARCH_CONTENT_DATA_START:
            return { ...state, searchLoading: true, searchError: null, searchSuccess: null };
        case actionTypes.SEARCH_CONTENT_DATA_SUCCESS:
            return { ...state, searchLoading: false, searchError: null, searchSuccess: action.payload };
        case actionTypes.SEARCH_CONTENT_DATA_FAILURE:
            return { ...state, searchLoading: false, searchError: action.payload, searchSuccess: null };

        case actionTypes.SET_AS_JAZZ_TUNE_START:
            return { ...state, setAsJazzTuneLoading: true, setAsJazzTuneError: null, setAsJazzTuneSuccess: null, setAsJazzTuneData: null };
        case actionTypes.SET_AS_JAZZ_TUNE_SUCCESS:
            return { ...state, setAsJazzTuneLoading: false, setAsJazzTuneError: null, setAsJazzTuneSuccess: action.payload, setAsJazzTuneData: action.payload };
        case actionTypes.SET_AS_JAZZ_TUNE_FAILURE:
            return { ...state, setAsJazzTuneLoading: false, setAsJazzTuneError: action.payload, setAsJazzTuneSuccess: null };
        case actionTypes.SET_AS_JAZZ_TUNE_SUCCESS_HANDLER:
            return { ...state, loading: false, setAsJazzTuneError: action.payload, setAsJazzTuneSuccess: action.payload, setAsJazzTuneData: action.payload };              

        case actionTypes.GET_WATCH_VIDEO_START:
            return { ...state, watchVideoLoading: true, error: null, success: null, data: null };
        case actionTypes.GET_WATCH_VIDEO_SUCCESS:
            return { ...state, watchVideoLoading: false, error: null, success: null, data: action.payload, };
        case actionTypes.GET_WATCH_VIDEO_FAILURE:
            return { ...state, watchVideoLoading: false, error: action.payload, success: null, };

        case actionTypes.GET_BANNERS_START:
            return { ...state, bannersLoading: true, error: null, success: null, bannersData: null };
        case actionTypes.GET_BANNERS_SUCCESS:
            return { ...state, bannersLoading: false, error: null, success: null, bannersData: action.payload, };
        case actionTypes.GET_BANNERS_FAILURE:
            return { ...state, bannersLoading: false, error: action.payload, success: null, };

        case actionTypes.GET_SECTION_CONTENT_START:
            return { ...state, sectionContentLoading: true, error: null, success: null, sectionContentData: null };
        case actionTypes.GET_SECTION_CONTENT_SUCCESS:
            return { ...state, sectionContentLoading: false, error: null, success: true, sectionContentData: action.payload, };
        case actionTypes.GET_SECTION_CONTENT_FAILURE:
            return { ...state, sectionContentLoading: false, error: action.payload, success: null, };

        case actionTypes.GET_SONG_BY_ARTIST_START:
            return { ...state, songsByArtistLoading: true, error: null, success: null, songsByArtistData: null };
        case actionTypes.GET_SONG_BY_ARTIST_SUCCESS:
            return { ...state, songsByArtistLoading: false, error: null, success: true, songsByArtistData: action.payload, };
        case actionTypes.GET_SONG_BY_ARTIST_FAILURE:
            return { ...state, songsByArtistLoading: false, error: action.payload, success: null, };

        case actionTypes.GET_GRADES_START:
            return { ...state, gradesLoading: true, error: null, success: null, gradesData: null };
        case actionTypes.GET_GRADES_SUCCESS:
            return { ...state, gradesLoading: false, error: null, success: null, gradesData: action.payload, };
        case actionTypes.GET_GRADES_FAILURE:
            return { ...state, gradesLoading: false, error: action.payload, success: null, };

        case actionTypes.GET_CONTENT_START:
            return { ...state, contentLoading: true, error: null, success: null, contentData: null };
        case actionTypes.GET_CONTENT_SUCCESS:
            return { ...state, contentLoading: false, error: null, success: null, contentData: action.payload, };
        case actionTypes.GET_CONTENT_FAILURE:
            return { ...state, contentLoading: false, error: action.payload, success: null, };
        case actionTypes.GET_CONTENT_HANDLER:
            return { ...state, contentLoading: false, error: action.payload, success: action.payload, contentData: action.payload, };

        case actionTypes.GET_CONTENT_BY_GRADE_ID_START:
            return { ...state, contentByGradeIdLoading: true, error: null, success: null, contentByGradeIdData: [] };
        case actionTypes.GET_CONTENT_BY_GRADE_ID_SUCCESS:
            return { ...state, contentByGradeIdLoading: false, error: null, success: null, contentByGradeIdData: action.payload, };
        case actionTypes.GET_CONTENT_BY_GRADE_ID_FAILURE:
            return { ...state, contentByGradeIdLoading: false, error: action.payload, success: null, };

        case actionTypes.GET_SEARCH_SUCCESS:
            return { ...state, search: action.payload };
        case actionTypes.GET_CONTENT_SEARCH_START:
            return { ...state, loading: true, contentSearchError: null, error: null, success: null, contentSearch: [] };
        case actionTypes.GET_CONTENT_SEARCH_SUCCESS:
            return { ...state, loading: false, contentSearchError: false, error: false, success: true, contentSearch: action.payload, };
        case actionTypes.GET_CONTENT_SEARCH_FAILURE:
            return { ...state, loading: false, contentSearchError: action.payload, error: true, success: false, };

        case actionTypes.GET_LIVE_CHANNELS_START:
            return { ...state, liveChannelsLoading: true, error: null, success: null, channelData: null };
        case actionTypes.GET_LIVE_CHANNELS_SUCCESS:
            return { ...state, liveChannelsLoading: false, error: null, success: null, channelData: action.payload, };
        case actionTypes.GET_LIVE_CHANNELS_FAILURE:
            return { ...state, liveChannelsLoading: false, error: action.payload, success: null, };

        case actionTypes.GET_LIVE_CHANNEL_BY_ID_START:
            return { ...state, liveChannelsLoading: true, error: null, success: null, channelByIdData: null };
        case actionTypes.GET_LIVE_CHANNEL_BY_ID_SUCCESS:
            return { ...state, liveChannelsLoading: false, error: null, success: null, channelByIdData: action.payload, };
        case actionTypes.GET_LIVE_CHANNEL_BY_ID_FAILURE:
            return { ...state, liveChannelsLoading: false, error: action.payload, success: null, };

        case actionTypes.GET_CITY_START:
            return { ...state, cityLoading: true, errorCity: null, successCity: null, data: null };
        case actionTypes.GET_CITY_SUCCESS:
            return { ...state, cityLoading: false, errorCity: null, successCity: action.payload.isSuccess, data: action.payload.data, };
        case actionTypes.GET_CITY_FAILURE:
            return { ...state, cityLoading: false, error: action.payload, success: null, };

        case actionTypes.CONTINUE_WATCHING_START:
            return { ...state, contWatchLoading: true, error: null, success: null, contWatchData: null };
        case actionTypes.CONTINUE_WATCHING_SUCCESS:
            return { ...state, contWatchLoading: false, error: null, success: null, contWatchData: action.payload };
        case actionTypes.CONTINUE_WATCHING_FAILURE:
            return { ...state, contWatchLoading: false, error: action.payload, success: null, };

        case actionTypes.UPDATE_DURATION_WATCHED_START:
            return { ...state, loading: true, error: null, success: null, data: null };
        case actionTypes.UPDATE_DURATION_WATCHED_SUCCESS:
            return { ...state, loading: false, error: null, success: null, data: action.payload };
        case actionTypes.UPDATE_DURATION_WATCHED_FAILURE:
            return { ...state, loading: false, error: action.payload, success: null, };

        case actionTypes.GET_CONTENT_BY_CHAPTER_ID_START:
            return { ...state, contentByChapterIdLoading: true, error: null, success: null, contentByChapterIdData: null };
        case actionTypes.GET_CONTENT_BY_CHAPTER_ID_SUCCESS:
            return { ...state, contentByChapterIdLoading: false, error: null, success: null, contentByChapterIdData: action.payload, };
        case actionTypes.GET_CONTENT_BY_CHAPTER_ID_FAILURE:
            return { ...state, contentByChapterIdLoading: false, error: action.payload, success: null, };

        case actionTypes.WATCH_HISTORY_START:
            return { ...state, watchHistoryLoading: true, error: null, success: null, watchHistoryData: null };
        case actionTypes.WATCH_HISTORY_SUCCESS:
            return { ...state, watchHistoryLoading: false, error: null, success: null, watchHistoryData: action.payload };
        case actionTypes.WATCH_HISTORY_FAILURE:
            return { ...state, watchHistoryLoading: false, error: action.payload, success: null, };

        case actionTypes.GET_INTRO_STREAM_LINK_START:
            return { ...state, introStreamLoading: true, error: null, success: null, introStreamLink: null };
        case actionTypes.GET_INTRO_STREAM_LINK_SUCCESS:
            return { ...state, introStreamLoading: false, error: null, success: null, introStreamLink: action.payload };
        case actionTypes.GET_INTRO_STREAM_LINK_FAILURE:
            return { ...state, introStreamLoading: false, error: action.payload, success: null, };

        // Provinces
        case actionTypes.GET_PROVINCE_START:
            return { ...state, provinceLoading: true, error: null, success: null, provinceData: null };
        case actionTypes.GET_PROVINCE_SUCCESS:
            return { ...state, provinceLoading: false, error: null, success: null, provinceData: action.payload };
        case actionTypes.GET_PROVINCE_FAILURE:
            return { ...state, provinceLoading: false, error: action.payload, success: null, };

        // School By City
        case actionTypes.GET_SCHOOLS_BY_CITY_START:
            return { ...state, schoolLoading: true, error: null, success: null, schoolData: null };
        case actionTypes.GET_SCHOOLS_BY_CITY_SUCCESS:
            return { ...state, schoolLoading: false, error: null, success: null, schoolData: action.payload };
        case actionTypes.GET_SCHOOLS_BY_CITY_FAILURE:
            return { ...state, schoolLoading: false, error: action.payload, success: null, };

        case actionTypes.HOME_MESSAGE_HANDLER:
            return {
                ...state, loading: false, error: action.payload, success: action.payload,
            };

        case actionTypes.GET_SUBJECT_START:
            return {
                ...state, loading: true, error: false, subjectData: null,
            };
        case actionTypes.GET_SUBJECT_SUCCESS:
            return {
                ...state, loading: false, error: false, subjectData: action.payload,
            };
        case actionTypes.GET_SUBJECT_FAILURE:
            return {
                ...state, loading: false, error: true, subjectData: null, subjectDataError: action.payload || null,
            };

        default:
            return {
                loading: false,
                bannersLoading: false,
                sectionContentLoading: false,
                songsByArtistLoading: false,
                gradesLoading: false,
                liveChannelsLoading: false,
                contentLoading: false,
                contentByGradeIdLoading: false,
                cityLoading: false,
                searchLoading: false,
                searchError: null,
                searchSuccess: null,
                data: null,
                bannersData: null,
                sectionContentData: null,
                songsByArtistData: null,
                gradesData: null,
                contentData: null,
                contentByGradeIdData: null,
                channelData: null,
                contWatchLoading: null,
                contWatchData: null,
                channelByIdData: null,
                contentByChapterIdData: null,
                contentByChapterIdLoading: false,
                watchHistoryData: null,
                introStreamLink: null,
                introStreamLoading: false,
                provinceLoading: false,
                provinceData: null,
                schoolLoading: false,
                schoolData: null,
                watchHistoryLoading: false,
                error: null,
                success: null
            };
    }
};
