import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    rootMediaCard: {
        display: 'flex',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        display: 'flex'
    },
    cover: {
        width: 213,
        height: 213,
        borderRadius: '35px'

    },
    table: {
        [theme.breakpoints.up('lg')]: {
            minWidth: 650

        },
    },
    tableLine: {
        '& .MuiTableCell-root': {
            borderBottom: 'solid 1px rgba(255, 255, 255, 0.20);'
        }
    },
    table: {
        [theme.breakpoints.up('lg')]: {
            minWidth: 650,
        },
        //   backgroundColor: '#2e2e2e',
        borderCollapse: 'separate',
        borderSpacing: '0 10px', // Add spacing between rows for better appearance with border-radius
    },
    tableLine: {
        '& .MuiTableRow-root': {
            // backgroundColor: '#3e3e3e',
            backgroundColor: 'var(--table-td-color)',
            borderRadius: '10px',
            '&:hover': {
                backgroundColor: '',
            },
            '& td': {
                borderBottom: 'none',
                padding: '10px 16px',
                '&:first-of-type': {
                    borderTopLeftRadius: '15px',
                    borderBottomLeftRadius: '15px',
                },
                '&:last-of-type': {
                    borderTopRightRadius: '15px',
                    borderBottomRightRadius: '15px',
                },
            },
        },
    },
  
    trackThumb: {
        width: '40px',
        height: '40px',
        borderRadius: '9px',
        marginRight: '10px',
    },
    trackDetail: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '10px',
    },
    tableHeader: {
        color: '#fff',
        fontSize: 20,
        fontWeight: 'bold',
        margin: '10px 10px',
    },
    tableCell: {
        color: 'var(--white-text)',
        cursor: 'pointer',
    },
    timerAction: {
        display: 'flex',
        alignItems: 'center',
    },
    menuPaper: {
        width: '300px',
        background: 'var(--profile-modal)',
        color: 'var(--white-text)',
        border: 'var(--jazz-tune-modal-border)',
        borderRadius: 20,
    },
    menuItemIcon: {
        paddingRight: 20,
        paddingLeft: 10,
        marginTop: 7,
    },
}));