import React, { useEffect, useState, useRef, useContext } from "react";
import { AppBar, List, Toolbar, useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Box from '@material-ui/core/Box';
import { withRouter, useLocation } from "react-router-dom";
import { useStyles } from "./styles";
import { generalStyles } from "./general";
import { connect } from "react-redux";
import { setting, notification, profileTop, Jazz, } from "../../assets/images/img/index";
import '../../assets/scss/modal.scss'
import '../../assets/scss/watchVideo.scss'
import { useTranslation } from 'react-i18next'
import * as actions from "../../store/actions";
import { Alert } from '../../components'
import { useTheme } from "../../components/themeProvider";
import ProfileMenuComponent from "./profileMenu";
import LoginParentModal from "../../components/modals/loginParentModal";
import UserContext from "../../routes/UserContext";
import { Tooltip } from '@material-ui/core';


//For language change do i18next.changeLanguage('en') in drop down select

const MySidebar = (props) => {

  const classes = useStyles();
  const genClasses = generalStyles();
  const { sidebar, verifyOtpData, loginUser } = props;
  const [open, setOpen] = useState(true);
  const [userData, setUserData] = useState({});
  const [activeTab, setActiveTab] = useState("");
  const [activeSubTab, setActiveSubTab] = useState("");
  const [profileOption, setProfileOption] = useState(false);
  const [mobileProfileOption, setMobileProfileOption] = useState(false);
  const [search, setSearch] = React.useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [profileMenu, setProfileMenu] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isCheck, setCheck] = useState(false);
  const location = useLocation();
  const { user } = useContext(UserContext);



  const matches = useMediaQuery('(max-width: 767px)');
  const { isDarkTheme, toggleTheme } = useTheme();

  const ref = useRef();

  useEffect(() => {
    if (location.pathname !== '/search-result') {
      setSearch('');
    }
  }, [location.pathname]);




  useEffect(() => {
    if (loginUser) {
      setLoggedIn(true)
    }
  }, []);


  useEffect(() => {
    const storedLoginStatus = localStorage.getItem('isLoggedIn');
    if (storedLoginStatus) {
      setLoggedIn(true);
    }
  }, []);

  const handleLoginSuccess = () => {
    setLoggedIn(true);
  };

  const handleSignIn = () => {
    setCheck(true)
    console.log('test', isCheck);
    // props.history.push({ pathname: '/signIn' });
    // handleLoginSuccess();
  };

  const handleProfile = () => {
    setProfileMenu(true)
  }

  const _useEffect = () => {
    if (Boolean(window.navigator.vibrate)) {
      // It Supports

    }
    // let userData = getUserDataFromLocalStorage();
    let userData = JSON.parse(localStorage.getItem('user'));
    if (userData) {
      setUserData(userData);
      highlightActiveTab();
    }
  };


  useEffect(() => {
    // let userData = getUserDataFromLocalStorage();
    let userData = JSON.parse(localStorage.getItem('user'));
    if (userData) {
      setUserData(userData);
      highlightActiveTab();
    }
  }, [props?.loggedInSuccess, isLoggedIn, localStorage.getItem('user')]);

  useEffect(() => {
    if (verifyOtpData && verifyOtpData.data)
      setUserData(verifyOtpData.data.user)
  }, [verifyOtpData])

  const highlightActiveTab = () => {
    let pathname = props.history.location.pathname.split("/");
    let fullPath = props.history.location.pathname;
    let activeTab = pathname[pathname.length - 1];
    // console.log('this is active ', activeTab)
    if (activeTab) {

    }
  };
  useEffect(_useEffect, []);
  //userData ? sidebar.filter(x=> x.role.includes(userData?.role?._id)) :null;
  const sideBarOptions = sidebar;
  const _useEffectActiveTab = () => {
    let pathname = props.history.location.pathname.split("/");
    let fullPath = props.history.location.pathname;
    let activeTab = (!pathname[1] || pathname[1] == '') ? 'home' : pathname[1];
    let tab = null;
    sideBarOptions?.forEach(element => {
      if (element.key == activeTab)
        tab = element;
    });
    setActiveTab(activeTab);
  };
  useEffect(_useEffectActiveTab, [activeTab, props.history.location.pathname]);



  // Function to toggle the switch state
  const toggleThemes = () => {
    setIsChecked(!isChecked);
  };

  // Use useEffect to set the initial state based on localStorage
  useEffect(() => {
    const storedState = localStorage.getItem('themeToggle');
    if (storedState !== null) {
      setIsChecked(storedState === 'true');
    }
  }, []);

  // Use useEffect to update localStorage whenever the state changes
  useEffect(() => {
    localStorage.setItem('themeToggle', isChecked);
    // Toggle the theme classes on the body element
    const bodyElement = document.body;
    if (isChecked) {
      bodyElement.classList.add('light-theme');
      bodyElement.classList.remove('dark-theme');
    } else {
      bodyElement.classList.add('dark-theme');
      bodyElement.classList.remove('light-theme');
    }
    const html = document.querySelector('html');
    html.dataset.theme = isChecked ? 'light-theme' : 'dark-theme';
  }, [isChecked]);


  const goToHome = () => {
    let pathname = props.history.location.pathname.split('/')[1];
    if (pathname !== 'home') {
      props.history.push({ pathname: `/home`, state: { mainActiveParentCategory: null, currentUser: userData } })
      setActiveTab('home');
    } else {
      if (props?.loggedInSuccess) {
        return;
      } else {
        window.location.reload();
      }
    }
  }


  useEffect(() => {
    setMobileProfileOption(false);
  }, [props?.location?.pathname]);

  const onTabHandler = (e, tab, isProfile) => {
    e.preventDefault();
    let pathname = props.history.location.pathname.split('/')[1];
    let tabKey = tab.key.toLowerCase();

    if (mobileProfileOption) {
      setMobileProfileOption(!mobileProfileOption);
    }

    if (pathname === 'home' && tabKey === 'home' && !props?.loggedInSuccess)
      window.location.reload();
    else {
      props.history.push({ pathname: `/${tabKey}`, state: { mainActiveParentCategory: tab.subItems?.length > 0 ? tab.subItems[0] : null, currentUser: userData } });
      setActiveTab(tab.key);
    }
    if (tab.subItems?.length > 0) setActiveSubTab(tab.subItems[0].text)
    //console.log("yess parent caleddddd",activeTab)
  };


  useOnClickOutside(ref, () => setProfileOption(false));
  const searchFn = (e) => {
    if (search) {
      props.history.push({ pathname: '/search' })
      props.searchStart(search)
    }
  }
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      searchFn(e);
    }
  };

  const getTooltipTitle = (key) => {
    switch (key) {
      case 'home':
        return 'Home';
      case 'categories':
        return 'Categories';
      // Add more cases as needed
      default:
        return '';
    }
  }

  const { t } = useTranslation();
  return (
    <div>
      {(props?.updatePasswordSuccess === false) &&
        <div className='margin-alert'>
          <Alert type={'error'} message={props.updatePasswordMessage} />
        </div>
      }
      {(props?.updatePasswordSuccess === true) &&
        <div className='margin-alert'>
          <Alert type={'success'} message={props.updatePasswordMessage} />
        </div>
      }
      <Box display={{ xs: 'block', lg: 'none' }}>
        {/* <div className="mobile-logo-topbar"> */}
        <div className={`${isDarkTheme ? 'light-theme' : 'dark-theme'} mobile-logo-topbar`}>
          <div className="tpbar-logomobile cursor-pointer" onClick={() => goToHome()}>
            <img src={Jazz} className={classes.logoImage} alt={""} />
          </div>
          <div className="profile-bar-top">
            {/* <div className="icon-pro">
              <span>
                <img src={setting} />
              </span>
            </div> */}
            {/* <div className="icon-pro">
              <span><img src={notification} />
              </span>
            </div> */}
            <div>
              {/* <span className="profileimg-state"><img src={profileTop} /></span> */}
              {/* <div className="profile-outer-btn">
                <button className="login-btn-profile" onClick={handleSignIn}>Login</button>
              </div> */}
              <div>
                {user || isLoggedIn ? (
                  <div style={{ display: 'flex', zIndex: 10 }}>
                    <div style={{ zIndex: 10, marginTop: 15, marginRight: 15 }} className="phn-noo">{userData?.mobile}</div>
                    <div className="profileimg-state"  onClick={handleProfile}><img src={profileTop} /></div>
                  </div>
                ) : (
                  // <div className="profile-outer-btn">
                  <div className="">
                    <button className="login-btn-profile" onClick={handleSignIn}>Login</button>
                  </div>
                )}


              </div>
            </div>
          </div>
          {/* <div className="tpbar-searchmobile">
            <ListItemIcon className='cursor-pointer'>
              <Paper component="form" className='search-formbar'>
                <InputBase
                  // className={[classes.inputSearchOuter].join(' ')}
                  className={`${classes.inputSearchOuter} search-color`}
                  placeholder="Search"
                  inputProps={{ 'aria-label': 'Search' }}
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none" className="search-svg-adjust">
                </svg>
              </Paper>
            </ListItemIcon>
          </div> */}
        </div>

        <div className={`${isDarkTheme ? 'light-theme' : 'dark-theme'} mobile-menubar-outer`}>
          <div className="mobile-menu-bar">
            <List className="mobile-menu-inner">
              {(sideBarOptions || []).map((obj, index) => {
                let key = obj.key.toLowerCase();
                let [textClass] = (activeTab == key && !obj.subItems) ? ["sidebar-tab-item", "active-tab", "white_icon"] : ["sidebar-tab-text", "black_icon"];

                return (
                  <ListItem
                    button
                    onClick={(e) => onTabHandler(e, obj)}
                    className={[textClass, "sidebar-tab-spacing"].join(' ')}
                  >
                    {obj.icon &&
                      <ListItemIcon className='sidebar-tab-icon'>
                        {obj.icon}
                      </ListItemIcon>}
                    <ListItemText
                      primary={obj.text}
                      className={`text-capitalize ${open ? 'display-flex' : 'display-none'}`}
                    />
                  </ListItem>
                );
              })}
             
            </List>
          </div>
        </div>
      </Box>

      <Box display={{ xs: 'none', lg: 'block' }}>
        <AppBar elevation={2}
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.headerWhite]: open
          })}
        >

          <Toolbar className="tool-bar">
            <div className={["sidebar-logospacing", "cursor-pointer"].join(' ')} onClick={() => goToHome()}>
              <img src={isDarkTheme ? Jazz : Jazz} className={classes.logoImage} alt={""} />
            </div>
            {/* <Toolbar className={classes.toolBarStyle} > */}
            <div className="top-header-bar">
              <div className="sider-first-spac">
                {/* <ListItemIcon onClick={handleDrawer} className='pl-small cursor-pointer'>
                  <img src={menuIcon} />
                </ListItemIcon> */}
              </div>
              <div className="srch-right">
                <div className="sider-top-mainsearch">
                  <ListItemIcon className='cursor-pointer'>
                    <Paper component="form" className='search-formbar'>
                      <InputBase
                        // className={[classes.inputSearchOuter].join(' ')}
                        className={`${classes.inputSearchOuter} search-color`}
                        placeholder="Search"
                        inputProps={{ 'aria-label': 'Search ' }}
                        value={search}
                        onChange={e => {
                          const value = e.target.value;
                          setSearch(value);
                          props.history.push('/search-result', { searchValue: value });
                        }}
                        onKeyPress={handleKeyPress}
                      />

                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="search-svg-adjust">
                        <g clip-path="url(#clip0_59_841)">
                          <path d="M7.66671 14.4999C3.90004 14.4999 0.833374 11.4333 0.833374 7.66659C0.833374 3.89992 3.90004 0.833252 7.66671 0.833252C11.4334 0.833252 14.5 3.89992 14.5 7.66659C14.5 11.4333 11.4334 14.4999 7.66671 14.4999ZM7.66671 1.83325C4.44671 1.83325 1.83337 4.45325 1.83337 7.66659C1.83337 10.8799 4.44671 13.4999 7.66671 13.4999C10.8867 13.4999 13.5 10.8799 13.5 7.66659C13.5 4.45325 10.8867 1.83325 7.66671 1.83325Z" fill="white" />
                          <path d="M14.667 15.1666C14.5403 15.1666 14.4137 15.12 14.3137 15.02L12.9803 13.6866C12.787 13.4933 12.787 13.1733 12.9803 12.98C13.1737 12.7866 13.4937 12.7866 13.687 12.98L15.0203 14.3133C15.2137 14.5066 15.2137 14.8266 15.0203 15.02C14.9203 15.12 14.7937 15.1666 14.667 15.1666Z" fill="white" />
                        </g>
                        <defs>
                          <clipPath id="clip0_59_841">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </Paper>
                  </ListItemIcon>
                </div>

                <div className="profile-bar-top">
                  <div className="icon-pro">
                    {/* <span>
                    <img src={setting} />
                  </span> */}
                  </div>
                  {/* <div className="icon-pro">
                  <span><img src={notification} />
                  </span>
                </div> */}
                  <div>
                    {user || isLoggedIn ? (
                      <div style={{ display: 'flex', zIndex: 10 }} >
                        <div style={{ zIndex: 10, marginTop: 15, marginRight: 15 }}className="phn-noo">{userData?.mobile}</div>
                        <div className="profileimg-state"  onClick={handleProfile}><img src={profileTop} /></div>
                      </div>
                    ) : (
                      // <div className="profile-outer-btn">
                      <div className="">
                        <button className="login-btn-profile" onClick={handleSignIn}>Login</button>
                      </div>
                    )}


                  </div>
                </div>
              </div>
            </div>
          </Toolbar>
        </AppBar>
      </Box>
      {/* {props.location.pathname !== '/tracks' && */}
      <div className='sideBar'>
        <List>
          {(sideBarOptions || []).map((obj, index) => {
            let key = obj.key.toLowerCase();
            let [textClass] = (activeTab == key && !obj.subItems) ? ["sidebar-tab-item", "active-tab", "white_icon"] : ["sidebar-tab-text", "black_icon"];

            return (
              <React.Fragment key={`tabList ${index}`}>
                <ListItem
                  button
                  onClick={(e) => onTabHandler(e, obj)}
                  className={[textClass, "sidebar-tab-spacing"].join(' ')}
                >
                  {obj.icon &&
                    <ListItemIcon className='sidebar-tab-icon'>
                      <Tooltip title={getTooltipTitle(obj.key)}>
                        {obj.icon}
                      </Tooltip>
                    </ListItemIcon>}
                  <ListItemText
                    primary={obj.text}
                    className={`text-capitalize ${open ? 'display-flex' : 'display-none'}`}
                  />

                </ListItem>
              </React.Fragment>
            );
          })}

        </List>
      </div>
      {/* } */}

      {/* <Drawer
      variant="permanent"
      className={`drawerOpen-style  ${clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open
      })}`}

      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >

      <List style={{
        paddingTop: 10,
        position: "relative",
        height: '100%',
        overflow: 'hidden',
      }}>
      
        {(sideBarOptions || []).map((obj, index) => {
          let key = obj.key.toLowerCase();
          let [textClass] = (activeTab == key && !obj.subItems) ? ["sidebar-tab-item", "active-tab", "white_icon"] : ["sidebar-tab-text", "black_icon"];
         
          return (
            <React.Fragment key={`tabList ${index}`}>
              <ListItem
                button
                onClick={(e) => onTabHandler(e, obj)}
                className={[textClass, "sidebar-tab-spacing"].join(' ')}
              >
                {obj.icon &&
                  <ListItemIcon className='sidebar-tab-icon'>
                    {obj.icon}
                  </ListItemIcon>}
                <ListItemText
                  primary={obj.text}
                  className={`text-capitalize ${open ? 'display-flex' : 'display-none'}`}
                />

              </ListItem>
            </React.Fragment>
          );
        })}

      </List>
      </Drawer> */}

      {profileMenu &&
        <ProfileMenuComponent
          setLoggedIn={setLoggedIn}
          setProfileMenu={setProfileMenu}
          onClick={toggleTheme}
          checked={isChecked}
          onChange={toggleThemes}
        // profileMenu={profileMenu}
        // handleCloseProfile={handleCloseProfile}
        />
      }
      {isCheck &&
        <LoginParentModal openModal={isCheck} setOpen={setCheck} handleLoginSuccess={handleLoginSuccess}
        />}


    </div>
  );
}

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}

//what is needed at start
const mapStateToProps = ({ authReducer, accountReducer, homeReducer }) => {
  const { loading, error, message, signUpSuccess, signUpError, loggedInSuccess, loggedInError, verifyOtpData } = authReducer;
  const { updatePasswordSuccess, updatePasswordMessage } = accountReducer;
  const { introStreamLink, introStreamLoading } = homeReducer;
  return { loading, updatePasswordSuccess, error, message, signUpSuccess, signUpError, loggedInSuccess, loggedInError, verifyOtpData, updatePasswordMessage, introStreamLink, introStreamLoading };
};
//which actions our function can dispatch
const mapDispatchToProps = (dispatch) => {
  return {
    searchStart: (data) => dispatch(actions.getSearchStart(data)),
    getUserAccountStart: (data) => dispatch(actions.getUserAccountStart(data)),
    updatePasswordStart: (data) => dispatch(actions.updatePasswordStart(data)),
    getIntroStreamLinkStart: (data) => dispatch(actions.getIntroStreamLinkStart(data)),
    accountMessageHandler: (data) => dispatch(actions.accountMessageHandler(data)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MySidebar));
