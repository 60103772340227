import * as actions from "../actions";
import * as actionsTypes from "../actionTypes";
import { put, takeEvery, all, fork } from "redux-saga/effects";
// import axios from 'axios';
import CategoryService from "../../services/category.service";
import { getUserDataFromLocalStorage } from "../../services/utils";

function* getCatData(action) {
  try {
    const response = yield CategoryService.getCat();

    if (response?.data) {        
      yield put(actions.getCategorySuccess(response?.data));
    } else {
      yield put(actions.getCategoryFailure("network error"));
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) error = 'network error'

    yield put(actions.getCategoryFailure(error));
  }
}
function* getCatDataSaga() {
  yield takeEvery(actionsTypes.GET_CATEGORY_START, getCatData);
}

function* getSubCatData(action) {
    try {
     let response;
      if(action.payload){
        const param = {
            user_id: action.payload.user_id,
            sub_category_id :action.payload.sub_cat_id
        }
       response = yield CategoryService.getSubCat(param);
      }
      if (response?.data) {        
        yield put(actions.getCategoryDataSuccess(response?.data));
      } else {
        yield put(actions.getCategoryDataFailure("network error"));
      }
    } catch (err) {
      let error = err?.response?.data?.message
      if (!error) error = 'network error'
  
      yield put(actions.getCategoryDataFailure(error));
    }
  }
  function* getSubCatSaga() {
    yield takeEvery(actionsTypes.GET_CATEGORY_DATA_START, getSubCatData);
  }

  function* getSongsByCategoryStart(action) {
    try {
      const response = yield CategoryService.getSongsByCategory(action.payload);
      if (response?.data) {
        yield put(actions.getSongsByCategorySuccess(response.data));
      } else {
        yield put(actions.getSongsByCategoryFailure("network error"));
      }
  
    } catch (err) {
      let error = err?.response?.data?.message
      if (!error) error = 'network error'
  
      yield put(actions.getSongsByCategoryFailure(error));
    }
  }
  function* getSongsByCategorySaga() {
    yield takeEvery(actionsTypes.GET_SONG_BY_CATEGORY_START, getSongsByCategoryStart);
  }
  

export function* categorySaga() {
  yield all([
    fork(getCatDataSaga),
    fork(getSubCatSaga),
    fork(getSongsByCategorySaga),
  ]);
}
