import HttpService from "./http.service";

class AccountService extends HttpService {
  getUserAccounts = (params) => this.get("account/all", params);

  addAccount = (params) => this.post("account/add", params)

  updateAccount = (params, config) => this.post(`account/update`, params, config)

  deleteAccount = (config) => this.post(`account/disable`, undefined, config)

  updatePassword = (config) => this.post(`account/update-password`, undefined, config)
}

export default new AccountService();